import { defineStore } from "pinia";
import { App } from "vue";
import { Sentry } from "./sentry";

interface State {
  sentry?: Sentry;
}

export const useSentry = defineStore("sentry", {
  state: (): State => ({
    sentry: undefined,
  }),
  getters: {
    enabled(): boolean {
      return Sentry.isEnabled();
    },
  },
  actions: {
    initialize(app: App): Sentry {
      const sentry = new Sentry(app);
      sentry.initialize();
      this.sentry = sentry;
      return sentry;
    },
  },
});
