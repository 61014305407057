<template>
  <div class="flex max-w-full items-center rounded-[20px] bg-gray-700 p-[3px] pr-[6px] text-[5px]">
    <div :style="{ color: color }">⬤</div>
    <div class="ml-5 truncate pr-5 text-xs">{{ label }}</div>
    <div v-if="sublabel" class="text-xs text-gray-500">{{ sublabel }}</div>
  </div>
</template>

<script lang="ts" setup>
defineProps<{ color: string; label: string; sublabel?: string }>();
</script>
