import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", {
    class: _normalizeClass(["pointer-events-none flex items-start gap-5 pl-10 text-xs font-bold text-gray-500", [
      $setup.requiredText,
      {
        'text-orange': $props.focus,
      },
    ]])
  }, _toDisplayString($props.label), 3 /* TEXT, CLASS */))
}