import useGraph from "@/common/composables/useGraph";
import useKnowledge from "@/common/composables/useKnowledge";
import { useNavigation } from "@/common/composables/useNavigation";
import { environment } from "@/common/environments/environmentLoader";
import { httpClient as axios, BackendError, isAxiosError } from "@/common/http/http";
import {
  ApplicableEnrichment,
  ApplicableEnrichmentsResponse,
  Environ,
  Intermediates,
  JobResponse,
  Module,
  ModuleResponse,
  RunnerOutput,
} from "@/common/lib/api";
import {
  Async,
  asyncFailed,
  asyncInProgress,
  asyncNotStarted,
  AsyncStatus,
  asyncSucceeded,
} from "@/common/lib/async";
import { conceptColorAtIndex, RECORD_CONCEPT_COLOR } from "@/common/lib/conceptColors";
import { DragItem } from "@/common/lib/dragAndDrop";
import { FailureOptions, FailureType } from "@/common/lib/failure";
import { emptyGraph, Graph, GraphTrace } from "@/common/lib/graph";
import { KnowledgeItem, RECORD_CONCEPT_TYPE } from "@/common/lib/knowledge";
import {
  applyAutomerge,
  ConceptPropertyPair,
  CTMap,
  garbageCollectMap,
  getMapClausesWhere,
  getMapSection,
  MapClauseReference,
  MapSectionKey,
  selectivelyApplyMap,
  unplannedMapClauses,
} from "@/common/lib/map";
import { MapAction } from "@/common/lib/mapActions";
import { useFailureStore } from "@/common/stores/failureStore";
import { useKnowledgeStore } from "@/common/stores/knowledgeStore";
import { useAppStore as useReaderAppStore } from "@/reader/stores/app";
import { useExploreStore } from "@/reader/stores/explore";
import { AxiosResponse } from "axios";
import { cloneDeep, isEmpty, without } from "lodash";
import { defineStore } from "pinia";
import { useRouter } from "vue-router";
import { useSourceBrowserStore } from "../../common/stores/sourceBrowser";
import { createMetagraphThumbnail } from "../lib/metagraphThumbnail";
import { useConceptSpaceStore } from "./conceptSpace";
import { usePerSourceConceptViewStore } from "./perSourceConceptView";
import { useSourceSpaceStore } from "./sourceSpace";
import { useUnifiedConceptViewStore } from "./unifiedConceptView";

const MAP_HISTORY_LENGTH = environment.requireNumber("MAX_UNDO_HISTORY"); // Purge older states to conserve memory

export const ROOT_ROUTE = "editor";

export enum Space {
  Source = "source",
  Concept = "concept",
  Explore = "explore",
  ExploreBookmark = "explore/:bookmark?",
}

interface MapHistoryEntry {
  description: string;
  state: Pick<State, "map" | "lastPlannerOutput">;
}

interface ModuleDetails {
  name: string;
  publishedReaderView: boolean;
  description?: string;
  llmPrompt?: string;
}

interface State {
  environ: Environ | null;
  globalLoadingState: Async<null>;
  localKnowledge: KnowledgeItem[];
  lastPlannerOutput: CTMap | null;
  trace: GraphTrace | null;
  intermediates: Async<Intermediates>;
  conceptColors: Record<string, string>;
  map: CTMap | null;
  graph: Graph | null;
  metagraph: Graph | null;
  applicableEnrichments: ApplicableEnrichment[] | null;
  currentModuleId: string | null;
  currentModule: ModuleDetails | null;
  mapHistory: MapHistoryEntry[];
  historyIndex: number; // index 0 means at the end of undos, 1 means 1 from end, etc.
  runIsInvalidated: boolean;
  job: Async<JobResponse>;
  jobCancelRequested: boolean;
  collapsedConceptTypes: string[];
  resizeWrapperStates: Record<string, { width: number; height: number }>;
  knowledgeShown: boolean;
  autosave: boolean;
  globalSavingState: Async<null>;
  autoSaver: AutoSaver;
  showSidebars: boolean;
  drag: DragItem | null;
  dropTarget: MapClauseReference | null;
  bannedAutomerges: ConceptPropertyPair[];
}

export const useAppStore = defineStore("editor-app", {
  state: (): State => ({
    environ: null,
    globalLoadingState: asyncSucceeded(null),
    localKnowledge: [],
    lastPlannerOutput: null,
    map: null,
    conceptColors: {},
    trace: null,
    intermediates: asyncNotStarted(),
    graph: null,
    metagraph: null,
    applicableEnrichments: null,
    currentModuleId: null,
    currentModule: null,
    mapHistory: [],
    historyIndex: 0,
    runIsInvalidated: false,
    job: asyncNotStarted(),
    jobCancelRequested: false,
    collapsedConceptTypes: [],
    resizeWrapperStates: {},
    knowledgeShown: false,
    autosave: true,
    globalSavingState: asyncSucceeded(null),
    autoSaver: new AutoSaver(),
    showSidebars: true,
    drag: null,
    dropTarget: null,
    bannedAutomerges: [],
  }),
  getters: {
    mapOrEmptyMap: (state) => state.map ?? {},
    canUndo: (state) => state.historyIndex < state.mapHistory.length - 1,
    undoDescription: (state) => state.mapHistory[state.historyIndex]?.description,
    canRedo: (state) => state.historyIndex > 0,
    redoDescription: (state) => state.mapHistory[state.historyIndex - 1]?.description,
    isEmpty: (state) => isEmpty((state.map ?? {}).in_connections),
  },
  actions: {
    async boot() {
      const conceptSpaceStore = useConceptSpaceStore();
      const sourceSpaceStore = useSourceSpaceStore();
      const sourceBrowserStore = useSourceBrowserStore();
      const perSourceConceptViewStore = usePerSourceConceptViewStore();
      const unifiedConceptViewStore = useUnifiedConceptViewStore();
      this.$reset();
      conceptSpaceStore.$reset();
      sourceSpaceStore.reset();
      sourceBrowserStore.$reset();
      unifiedConceptViewStore.$reset();
      perSourceConceptViewStore.$reset();

      const environResponse = await axios.get("/api/environ", undefined, {
        type: FailureType.Api,
        description: "Application fail to bootup due to backend API errors",
      });

      this.environ = environResponse?.data;
    },
    async runMap(save = true) {
      const params = {
        map: this.mapOrEmptyMap,
      };
      this.intermediates = asyncInProgress("Loading results...");
      this.runIsInvalidated = false;

      const response = await axios.post(
        `/api/projects/${this.currentModuleId}/run`,
        params,
        undefined,
        {
          type: FailureType.Runner,
          description: "The runner encountered an unexpected error during a normal map run.",
        }
      );

      if (this.runIsInvalidated) {
        this.intermediates = asyncNotStarted();
        this.runMap();
      } else {
        this.updateWithRunnerOutput(response.data.output);
      }
      // Do post-save
      await this.autoSaveModule(save);
    },

    async loadUserModule(moduleId: string, newProject: boolean) {
      const message = newProject ? "Initializing new project…" : "Loading project…";
      this.globalLoadingState = asyncInProgress(message);
      let moduleResponse: AxiosResponse<ModuleResponse>;
      try {
        [moduleResponse] = await Promise.all([
          axios.get(`/api/projects/${moduleId}`),
          // Loading a module can cause new knowledge to be present.
          useKnowledgeStore().load(moduleId),
        ]);
      } catch (error: unknown) {
        if (
          error instanceof BackendError &&
          isAxiosError(error.cause) &&
          error.cause.response?.status == 404
        ) {
          // Invalid module ID
          return useNavigation().goHome();
        }
        this.globalLoadingState = asyncSucceeded(null);
        throw error;
      }

      const moduleData = moduleResponse.data.module;
      const map = moduleData.map as CTMap;

      this.$patch({
        currentModuleId: moduleData.manifest.id,
        currentModule: {
          name: moduleData.manifest.name,
          description: moduleData.manifest.description,
          publishedReaderView: moduleData.manifest.published_reader_view,
          llmPrompt: moduleData.app_state?.llmPrompt,
        },
        conceptColors: moduleData.app_state?.conceptColors ?? {},
        bannedAutomerges: moduleData.app_state?.bannedAutomerges ?? [],
      });

      const exploreStore = useExploreStore();
      exploreStore.boot(moduleData.manifest.id);
      exploreStore.showSidebars = () => this.showSidebars;

      this.updateMap(moduleData.map);

      try {
        if (isEmpty(getMapSection(map, MapSectionKey.InConnections))) {
          // No files; skip planner and runner.
        } else if (isEmpty(getMapSection(map, MapSectionKey.InIterators))) {
          // This map has never been through the planner
          // runPlanner will handle the global loading state for us
          await this.runPlanner();
        } else {
          // We use the global loading state for the first run because it
          // Loads icons and colors.
          this.globalLoadingState = asyncInProgress("Loading results...");
          // Do not save on load
          await this.runMap(false);
        }
      } finally {
        this.globalLoadingState = asyncSucceeded(null);
      }

      // Save initial state to history (for undo / redo purposes)
      this.pushStateToHistory("");
    },
    async changeMap(action: MapAction, description?: string, message?: string) {
      const newMap = garbageCollectMap(action.map);
      this.metagraph = null; // This is about to become invalid, or at least wrong
      this.updateMap(newMap);
      const unplanned = unplannedMapClauses(newMap);
      if (unplanned.length) {
        await this.runPlanner(unplanned, message);
      } else {
        await this.runMap();
      }
      this.pushStateToHistory(description ?? "");
    },
    async runPlanner(clauses?: MapClauseReference[], message = "Planning...") {
      const action = new MapAction(this.mapOrEmptyMap);
      if (isEmpty(getMapSection(action.map, MapSectionKey.InConnections))) {
        return;
      }
      const { isRecordConceptType } = useKnowledge();
      // Remove record concepts so unassigned properties can be reassigned
      const recs = getMapClausesWhere(this.mapOrEmptyMap, MapSectionKey.InConcepts, (c) =>
        isRecordConceptType(c.concept_type)
      );
      for (const recKey of Object.keys(recs)) action.removeConcept(recKey);
      const params = {
        map: garbageCollectMap(action.map),
        limit_parent_clauses: clauses,
        prior_plan: this.lastPlannerOutput,
      };
      this.globalLoadingState = asyncInProgress(message);
      let response;
      try {
        response = await axios.post(
          `/api/projects/${this.currentModuleId}/plan`,
          params,
          undefined,
          {
            type: FailureType.Planner,
            description: "The planner encountered an unexpected error.",
          }
        );
      } catch (error: unknown) {
        this.globalLoadingState = asyncSucceeded(null);
        throw error;
      }

      let newMap = response.data.output;
      this.lastPlannerOutput = newMap;
      if (clauses != null) newMap = selectivelyApplyMap(this.mapOrEmptyMap, newMap, clauses);
      this.updateMap(newMap);
      this.pushStateToHistory("run planner");
      this.globalLoadingState = asyncSucceeded(null);
      if (this.intermediates.status === AsyncStatus.InProgress) {
        // An async run is in progress. To prevent simultaneous runner calls,
        // just make a note of this so that runMap can notice, throw away the
        // results and run again.
        this.runIsInvalidated = true;
      } else {
        this.runMap();
      }
    },
    clearMap() {
      const oldMap = this.mapOrEmptyMap;
      const newMap: CTMap = {};
      newMap[MapSectionKey.Import] = oldMap[MapSectionKey.Import];
      newMap[MapSectionKey.InConnections] = oldMap[MapSectionKey.InConnections];
      newMap[MapSectionKey.InEncodings] = oldMap[MapSectionKey.InEncodings];
      this.updateMap(newMap);
      this.metagraph = null;
    },
    toggleAutosave() {
      this.autosave = !this.autosave;
    },
    async autoSaveModule(saveEnabled: boolean) {
      if (!this.autosave) {
        this.globalSavingState = asyncNotStarted();
      }
      if (saveEnabled && this.currentModuleId && this.autosave) {
        await this.saveModule(this.currentModuleId);
      }
    },
    async saveModule(moduleId: string) {
      await this.autoSaver.save(() => this.saveModuleSync(moduleId));
    },
    async saveModuleSync(moduleId: string) {
      try {
        this.globalSavingState = asyncInProgress("Saving...");

        const params: Partial<Module> = {
          map: this.mapOrEmptyMap,
          app_state: {
            conceptColors: this.conceptColors,
            bannedAutomerges: this.bannedAutomerges,
          },
        };
        const metagraph = useGraph(() => this.metagraph ?? emptyGraph()).metagraphWithoutRecords();
        if (metagraph.concepts.length > 0) {
          params.thumbnail = await createMetagraphThumbnail(metagraph, this.conceptColors);
        }
        await axios.patch(`/api/projects/${moduleId}`, params);
        this.globalSavingState = asyncSucceeded(null);
      } catch (error: unknown) {
        this.globalSavingState = asyncFailed(`${error}`);
      }
    },
    updateMap(map: CTMap) {
      this.drag = null; // Prevent drops from the past
      this.dropTarget = null;
      useKnowledgeStore().extractMapKnowledge(map);
      map = applyAutomerge(map, this.bannedAutomerges);
      const sourceSpaceStore = useSourceSpaceStore();
      const perSourceConceptViewStore = usePerSourceConceptViewStore();
      const conKeys = Object.keys(getMapSection(map, MapSectionKey.InConcepts));
      sourceSpaceStore.reconcileWithAvailableSources(map);
      sourceSpaceStore.ensureSelectedClausesInSet(map);
      sourceSpaceStore.ensureCollapsedConceptMappingsInSet(conKeys);
      perSourceConceptViewStore.reconcileWithAvailableSources(map);
      this.map = map;
    },
    async updateWithRunnerOutput(output: RunnerOutput) {
      const unifiedConceptViewStore = useUnifiedConceptViewStore();
      const graphIntermediate = output.intermediates.resolve ??
        output.intermediates.enrich ??
        output.intermediates.map_in ?? {
          graph: emptyGraph(),
          metagraph: emptyGraph(),
        };
      const metagraph = graphIntermediate.metagraph as Graph;
      const metaconceptTypes = metagraph.concepts.map((c) => c.type);
      unifiedConceptViewStore.ensureSelectedConceptTypeInSet(metaconceptTypes);
      this.graph = graphIntermediate.graph;
      this.intermediates = asyncSucceeded(output.intermediates);
      this.metagraph = metagraph;
      metaconceptTypes.forEach((ctype) => this.assignConceptColor(ctype));
      unifiedConceptViewStore.initializeLayout();
      useReaderAppStore().bootEmbedded(
        this.currentModuleId!,
        this.mapOrEmptyMap,
        useGraph(() => metagraph ?? emptyGraph()).metagraphWithoutRecords(),
        this.conceptColors
      );
      if (environment.requireBoolean("ENRICHMENTS_ENABLED")) {
        const params = {
          map: output.map,
          module_id: this.currentModuleId,
        };
        try {
          const response = await axios.post("/api/applicable_enrichments", params);
          this.applicableEnrichments = (response.data as ApplicableEnrichmentsResponse).applicables;
        } catch (error) {
          // FIXME:issue#2453 This endpoint is throwing errors so we making it optional for now.
          this.applicableEnrichments = [];
        }
      }
    },
    assignConceptColor(conceptType: string) {
      if (this.conceptColors[conceptType] == null) {
        const { isAncestorOf } = useKnowledge();
        if (isAncestorOf(conceptType, RECORD_CONCEPT_TYPE)) {
          this.conceptColors[conceptType] = RECORD_CONCEPT_COLOR;
        } else {
          const nextIndex = without(Object.values(this.conceptColors), RECORD_CONCEPT_COLOR).length;
          this.conceptColors[conceptType] = conceptColorAtIndex(nextIndex);
        }
      }
    },
    collapseConceptType(typeId: string) {
      if (!this.collapsedConceptTypes.includes(typeId)) this.collapsedConceptTypes.push(typeId);
    },
    toggleConceptTypeCollapsed(typeId: string) {
      if (this.collapsedConceptTypes.includes(typeId)) {
        this.collapsedConceptTypes = without(this.collapsedConceptTypes, typeId);
      } else {
        this.collapsedConceptTypes.push(typeId);
      }
    },
    backendFail(failure: FailureOptions) {
      useFailureStore().backendFail(failure);
    },
    pushStateToHistory(description = "") {
      // Invalidate undo history after this
      this.mapHistory = this.mapHistory.slice(this.historyIndex);
      this.historyIndex = 0;

      const stateSubset = {
        map: this.map,
        lastPlannerOutput: this.lastPlannerOutput,
      };
      this.mapHistory.unshift({ state: cloneDeep(stateSubset), description });
      if (this.mapHistory.length > MAP_HISTORY_LENGTH)
        this.mapHistory = this.mapHistory.slice(0, MAP_HISTORY_LENGTH);
    },
    async undo() {
      if (!this.canUndo) {
        return;
      }

      this.historyIndex += 1;
      await this._loadHistoryAtIndex();
    },
    async redo() {
      if (!this.canRedo) {
        return;
      }

      this.historyIndex -= 1;
      await this._loadHistoryAtIndex();
    },
    async _loadHistoryAtIndex() {
      const historyEntry = this.mapHistory[this.historyIndex];
      this.$patch(historyEntry.state);
      this.updateMap(this.mapOrEmptyMap); // Extract knowledge and ensure UI state is sane for this map
      if (this.isEmpty) {
        useRouter().push({ name: ROOT_ROUTE, replace: true });
      }
      await this.runMap();
    },
    showKnowledge() {
      this.knowledgeShown = true;
    },
    toggleSidebars() {
      this.showSidebars = !this.showSidebars;
    },
    downloadMap() {
      const map = this.map;
      if (!map) {
        return;
      }
      const blob = new Blob([JSON.stringify(map, undefined, 2)], { type: "application/json" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "map.json";
      link.target = "_blank";
      link.click();
      URL.revokeObjectURL(link.href);
    },
    async loadMap(mapFile: File) {
      const mapJson = await mapFile.text();
      const ctMap: CTMap = JSON.parse(mapJson);
      const action = new MapAction(ctMap);
      this.changeMap(action, "upload map file");
    },
  },
});

export class AutoSaver {
  private saving = false;
  private next?: () => Promise<void>;

  async save(save: () => Promise<void>) {
    if (this.saving) {
      this.next = save;
    } else {
      this.saving = true;
      await save();
      while (this.next) {
        const next = this.next;
        this.next = undefined;
        await next();
      }
      this.saving = false;
    }
  }
}
