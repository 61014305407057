import axios from "axios";
import { defineStore } from "pinia";
import { httpClient } from "../http/http";
import { FailureType } from "../lib/failure";
import { User } from "../lib/user";

export interface LoginResponse {
  auth: {
    access_token: string;
    refresh_token?: string;
  };
  user?: User;
}

export interface RefreshTokenResponse {
  access_token: string;
}

export interface ResetPasswordResponse {
  email: string;
}

export const useBackendAuth = defineStore("backend-auth", {
  actions: {
    async login(email: string, password: string): Promise<LoginResponse> {
      const body = { email, password };
      const loginResponse = await httpClient.post("/api/auth/login", body, undefined, {
        type: FailureType.Api,
        description: "Failed to login due to backend auth error",
      });

      return loginResponse?.data;
    },
    async refreshToken(refreshToken: string): Promise<RefreshTokenResponse> {
      const body = { refresh_token: refreshToken };
      // Use axios directly so we don't retry this.
      const response = await axios.post("/api/auth/refresh-token", body);
      return response?.data;
    },
    async resetPassword(email: string): Promise<ResetPasswordResponse> {
      const body = { email };
      // Use axios directly so we don't retry this.
      const response = await axios.post("/api/auth/reset-password", body);
      return response?.data;
    },
  },
});
