<template>
  {{ value.value }}
</template>

<script lang="ts">
import { FloatValue, IntegerValue } from "@/common/lib/value";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    value: {
      type: Object as () => IntegerValue | FloatValue,
      required: true,
    },
  },
});
</script>
