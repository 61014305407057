import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex flex-row items-center gap-5" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 1,
  class: "flex flex-col items-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.workspaces.status === $setup.AsyncStatus.Succeeded)
      ? (_openBlock(), _createBlock($setup["Select"], {
          key: 0,
          label: "Workspace",
          options: $setup.workspaces.result,
          "option-value": (w) => w.id,
          "model-value": $setup.currentWorkspaceId,
          "onUpdate:modelValue": $setup.selectWorkspace,
          "option-label": "nickname",
          clearable: false,
          class: "min-h-[62px]"
        }, {
          "option-data": _withCtx((workspace) => [
            _createElementVNode("span", _hoisted_1, [
              ($setup.providerIcon(workspace.provider))
                ? (_openBlock(), _createBlock($setup["Icon"], {
                    key: 0,
                    name: $setup.providerIcon(workspace.provider)!
                  }, null, 8 /* PROPS */, ["name"]))
                : _createCommentVNode("v-if", true),
              _createElementVNode("span", null, _toDisplayString(workspace.nickname), 1 /* TEXT */),
              (!workspace.connected)
                ? (_openBlock(), _createElementBlock("span", _hoisted_2, "(disconnected)"))
                : _createCommentVNode("v-if", true)
            ])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["options", "option-value", "model-value"]))
      : _createCommentVNode("v-if", true),
    ($setup.workspaces.status === $setup.AsyncStatus.InProgress)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode($setup["Spinner"])
        ]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}