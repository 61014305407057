import useGraph from "@/common/composables/useGraph";
import { MetagraphLayout } from "@/common/lib/webcola";
import { findKey } from "lodash";
import { defineStore } from "pinia";
import { toRaw } from "vue";
import { useExploreStore } from "./explore";

let metagraphLayout = new MetagraphLayout();

interface State {
  visible: boolean;
  layoutByConcept: Record<
    string,
    { x: number; y: number; index: number; width: number; height: number }
  >;
  panOrigin: [number, number];
}

export const useExploreMetagraphStore = defineStore("reader-exploreMetagraph", {
  state: (): State => ({
    visible: false,
    layoutByConcept: {},
    panOrigin: [0, 0],
  }),
  actions: {
    initializeLayout() {
      const metagraph = useExploreStore().metagraph;
      if (metagraph == null) return;
      const newLayoutNodes: typeof this.layoutByConcept = {};
      metagraph.concepts.forEach((concept, index) => {
        const prior = this.layoutByConcept[concept.type];
        newLayoutNodes[concept.type] = {
          x: prior?.x || 0,
          y: prior?.y || 0,
          width: 120,
          height: 120,
          index,
        };
      });
      this.layoutByConcept = newLayoutNodes;
      metagraphLayout = new MetagraphLayout();
      metagraphLayout.avoidOverlaps(true);
      metagraphLayout.onUpdateView = this.layoutEngineTick;
      const { getConcept } = useGraph(() => metagraph);
      metagraphLayout.nodes(Object.values(this.layoutByConcept));
      metagraphLayout.links(
        metagraph.links.map((link) => {
          const from = getConcept(link.from);
          const to = getConcept(link.to);
          return {
            source: this.layoutByConcept[from.type].index,
            target: this.layoutByConcept[to.type].index,
          };
        })
      );
      this.startLayout();
    },
    layoutEngineTick() {
      // This callback fires when the layout engine has a new layout for us
      for (const node of metagraphLayout.nodes()) {
        const key = findKey(this.layoutByConcept, (l) => l.index === node.index);
        if (key != null) {
          this.layoutByConcept[key].x = node.x;
          this.layoutByConcept[key].y = node.y;
        }
      }
    },
    startLayout() {
      metagraphLayout.nodes().forEach((node, index) => {
        const layout = Object.values(toRaw(this.layoutByConcept)).find((l) => l.index === index);
        if (layout != null) {
          node.x = layout.x;
          node.y = layout.y;
        }
      });
      metagraphLayout.jaccardLinkLengths(60);
      metagraphLayout.start();
    },
  },
});
