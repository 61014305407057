import { defineStore } from "pinia";

export enum GraphDisplay {
  PerSource = "per-source",
  Unified = "unified",
}

interface State {
  graphDisplay: GraphDisplay;
}

export const useConceptSpaceStore = defineStore("editor-conceptSpace", {
  state: (): State => ({
    graphDisplay: GraphDisplay.Unified,
  }),
});
