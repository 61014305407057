<template>
  <div class="my-10">
    <label class="mb-5 block">Terms:</label>
    <MultiTerm :parent-op="OP" v-model="terms" />
  </div>
</template>

<script lang="ts" setup>
import { DerivedPropertyTerm, SubtractPropertyType, PropertyOpType } from "@/common/lib/derived";
import { Ref, ref, watchEffect } from "vue";
import MultiTerm from "./MultiTerm.vue";
import { isNull } from "lodash";

const OP = PropertyOpType.Subtract;

const terms: Ref<(DerivedPropertyTerm | null)[]> = ref([null, null]);

const model = defineModel<SubtractPropertyType | null>({ required: true });

watchEffect(function () {
  if (terms.value.some((t) => isNull(t))) {
    model.value = null;
  } else {
    model.value = {
      op: OP,
      terms: terms.value as DerivedPropertyTerm[],
    };
  }
});
</script>
