import { environment } from "@/common/environments/environmentLoader";
import { MenuItem } from "@/common/lib/menus";
import { useExploreStore } from "../stores/explore";

const LLM_OPTIONS: { key: string; label: string } = JSON.parse(
  environment.require("LLM_PROVIDER_MAP")
);

export function chooseLLMProviderMenu(): MenuItem {
  const explore = useExploreStore();
  const submenu: MenuItem[] = [];
  const currentProvider = explore.llmProvider;
  for (const [key, label] of Object.entries(LLM_OPTIONS)) {
    submenu.push({
      icon: currentProvider === key ? "md:check" : null,
      key,
      label,
      action: () => {
        explore.llmProvider = key;
        return;
      },
    });
  }
  return {
    key: "select-llm",
    label: "Choose LLM provider...",
    submenu,
  };
}
