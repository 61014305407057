import useGraph from "@/common/composables/useGraph";
import { Graph } from "@/common/lib/graph";
import {
  CTMap,
  getMapSection,
  MapPropertyParserClause,
  MapSectionKey,
  MapTransformClause,
} from "@/common/lib/map";
import { intersectSets, toggle } from "@/common/lib/set";
import { MetagraphLayout } from "@/common/lib/webcola";
import { findKey } from "lodash";
import { defineStore } from "pinia";

export const NEW_SOURCE_CLAUSE = "";

export enum MapperMode {
  Columns = "columns",
  Rows = "rows",
}

export enum Menu {
  Source = "source",
  Knowledge = "knowledge",
}

// For editing* items, null means no editing is underway,
// an empty string means a new clause is being created,
// and any other string is the clause key being edited

export interface SourceState {
  selectionSection: MapSectionKey | null;
  selectionClauseKeys: Set<string>;
  collapsedConceptMappings: Set<string>;
  editingConceptMapping: string | null;
  search: string;
  graphConceptMappings: Record<string, { x: number; y: number; index: number }>;
  graphLayout: MetagraphLayout;
  graphPanOrigin: [number, number];
}

interface State {
  selectedDataset: string | null;
  sourceStates: Map<string, SourceState>;
  editingTransform: string | null;
  transformEditOrigin: Partial<MapTransformClause>;
  editingParser: string | null;
  parserEditOrigin: Partial<MapPropertyParserClause>;
  mapperMode: MapperMode;
  activeMenu: Menu;
}

function emptySourceState(): SourceState {
  return {
    selectionSection: null,
    selectionClauseKeys: new Set(),
    collapsedConceptMappings: new Set(),
    editingConceptMapping: null,
    search: "",
    graphConceptMappings: {},
    graphLayout: new MetagraphLayout(),
    graphPanOrigin: [0, 0],
  };
}

export const useSourceSpaceStore = defineStore("editor-sourceSpace", {
  state: (): State => ({
    selectedDataset: null,
    sourceStates: new Map(),
    editingTransform: null,
    transformEditOrigin: {},
    editingParser: null,
    parserEditOrigin: {},
    mapperMode: MapperMode.Columns,
    activeMenu: Menu.Source,
  }),
  getters: {
    sourceState(state) {
      return (dataset: string) => state.sourceStates.get(dataset) ?? emptySourceState();
    },
    selectedSourceState(state) {
      if (state.selectedDataset == null) return emptySourceState();
      // We should be able to use the sourceState getter here but TS hates it. Needs looking into.
      return state.sourceStates.get(state.selectedDataset) as SourceState;
    },
  },
  actions: {
    selectDataset(dataset: string | null) {
      this.selectedDataset = dataset;
      if (dataset != null) {
        if (!this.sourceStates.has(dataset)) {
          this.sourceStates.set(dataset, emptySourceState());
        }
      }
    },
    toggleClauseSelected(section: MapSectionKey, clauseKey: string) {
      const dataset = this.selectedDataset;
      if (dataset == null) return;
      const sourceState = this.sourceState(dataset);
      if (sourceState.selectionSection != null && sourceState.selectionSection != section) {
        this.setClausesSelected(section, [clauseKey]);
      } else {
        sourceState.selectionSection = section;
        toggle(sourceState.selectionClauseKeys, clauseKey);
      }
    },
    setClausesSelected(section: MapSectionKey, clauseKeys: Set<string> | string[]) {
      this.patchSelectedSourceState({
        selectionSection: section,
        selectionClauseKeys: new Set(clauseKeys),
      });
    },
    clearClausesSelected() {
      this.patchSelectedSourceState({
        selectionSection: null,
        selectionClauseKeys: new Set(),
      });
    },
    toggleConceptMappingCollapsed(clauseKey: string) {
      const dataset = this.selectedDataset;
      if (dataset == null) return;
      toggle(this.sourceState(dataset).collapsedConceptMappings, clauseKey);
    },
    setCollapsedConceptMappings(clauseKeys: Set<string>) {
      this.patchSelectedSourceState({ collapsedConceptMappings: clauseKeys });
    },
    setEditingConceptMapping(clauseKey: string | null) {
      this.patchSelectedSourceState({ editingConceptMapping: clauseKey });
    },
    setSearch(search: string) {
      this.patchSelectedSourceState({ search });
      this.clearClausesSelected();
    },
    patchSelectedSourceState(patch: Partial<SourceState>) {
      if (this.selectedDataset == null) return;
      this.sourceStates.set(this.selectedDataset, {
        ...this.selectedSourceState,
        ...patch,
      });
    },
    initializeLayout(dataset: string, metagraph: Graph) {
      const state = this.sourceStates.get(dataset);
      if (state == null) return;
      const newLayoutNodes: typeof state.graphConceptMappings = {};
      metagraph.concepts.forEach((concept, index) => {
        const prior = state?.graphConceptMappings[concept.id];
        newLayoutNodes[concept.type] = {
          x: prior?.x ?? 0,
          y: prior?.y ?? 0,
          index,
        };
      });
      state.graphConceptMappings = newLayoutNodes;
      state.graphLayout = new MetagraphLayout();
      state.graphLayout.avoidOverlaps(true);
      state.graphLayout.onUpdateView = () => this.layoutEngineTick(dataset);
      const { getConcept } = useGraph(() => metagraph);
      state.graphLayout.nodes(
        metagraph.concepts.map(() => ({
          width: 150,
          height: 50,
        }))
      );
      state.graphLayout.links(
        metagraph.links.map((link) => {
          const from = getConcept(link.from);
          const to = getConcept(link.to);
          return {
            source: state.graphConceptMappings[from.type].index,
            target: state.graphConceptMappings[to.type].index,
          };
        })
      );
      this.startLayout(dataset);
    },
    layoutEngineTick(dataset: string) {
      // This callback fires when the layout engine has a new layout for us
      const state = this.sourceStates.get(dataset);
      if (state == null) return;
      for (const node of state.graphLayout.nodes()) {
        const key = findKey(state.graphConceptMappings, (l) => l.index === node.index);
        if (key != null) {
          state.graphConceptMappings[key].x = node.x;
          state.graphConceptMappings[key].y = node.y;
        }
      }
    },
    startLayout(dataset: string) {
      const state = this.sourceStates.get(dataset);
      if (state == null) return;
      state.graphLayout.nodes().forEach((node, index) => {
        const layout = Object.values(state.graphConceptMappings).find((l) => l.index === index);
        if (layout != null) {
          node.x = layout.x;
          node.y = layout.y;
        }
      });
      state.graphLayout.jaccardLinkLengths(100);
      state.graphLayout.start();
    },
    ensureSelectedClausesInSet(map: CTMap) {
      for (const [stateKey, state] of this.sourceStates) {
        if (state.selectionSection != null) {
          this.sourceStates.set(stateKey, {
            ...state,
            selectionClauseKeys: intersectSets(
              new Set(Object.keys(getMapSection(map, state.selectionSection))),
              state.selectionClauseKeys
            ),
          });
        }
      }
    },
    ensureCollapsedConceptMappingsInSet(clauseKeys: string[]) {
      this.sourceStates.forEach((state, stateKey) =>
        this.sourceStates.set(stateKey, {
          ...state,
          collapsedConceptMappings: intersectSets(
            new Set(clauseKeys),
            state.collapsedConceptMappings
          ),
        })
      );
    },
    reconcileWithAvailableSources(map: CTMap | null) {
      const datasets = Object.keys(getMapSection(map ?? {}, MapSectionKey.InEncodings));
      // Remove states of sources which no longer exist
      const keysToDelete: string[] = [];
      this.sourceStates.forEach((state, dataset) => {
        if (!datasets.includes(dataset)) keysToDelete.push(dataset);
      });
      for (const key of keysToDelete) this.sourceStates.delete(key);
      // Try to select a reasonable source from those available
      if (this.selectedDataset == null || !this.sourceStates.has(this.selectedDataset)) {
        if (datasets.length) {
          this.selectDataset(datasets[0]);
        } else {
          this.selectDataset(null);
        }
      }
    },
    startEditingTransform(clauseKey: string, origin: Partial<MapTransformClause>) {
      this.editingTransform = clauseKey;
      this.transformEditOrigin = origin;
    },
    stopEditingTransform() {
      this.editingTransform = null;
      this.transformEditOrigin = {};
    },
    startEditingParser(clauseKey: string, origin: Partial<MapPropertyParserClause>) {
      this.editingParser = clauseKey;
      this.parserEditOrigin = origin;
    },
    stopEditingParser() {
      this.editingParser = null;
      this.parserEditOrigin = {};
    },
    reset() {
      this.$reset();
    },
  },
});
