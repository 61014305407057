import { environment } from "../environments/environmentLoader";

export function loadHubspotTracking() {
  if (!environment.requireBoolean("HUBSPOT_ENABLE_TRACKING")) {
    return;
  }
  const hubspotScript = document.createElement("script");
  hubspotScript.setAttribute("id", "hs-script-loader");
  hubspotScript.setAttribute("async", "");
  hubspotScript.setAttribute("defer", "");
  hubspotScript.setAttribute("src", environment.require("HUBSPOT_TRACKING_SRC"));
  document.head.appendChild(hubspotScript);
}
