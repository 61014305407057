<template>
  <div class="flex items-center self-stretch">
    <div
      class="flex shrink-0 flex-grow basis-0 items-center gap-10 px-5 py-0 text-xxl font-bold text-gray-800 dark:text-gray-200"
    >
      <Icon name="electrical_services" size="xl" class="icon" :color="iconColor" />
      Connect to workspace
    </div>
  </div>
  <div
    class="flex max-w-[450px] flex-col items-start gap-10 self-stretch px-10 py-0"
    v-if="
      [AsyncStatus.Succeeded, AsyncStatus.Failed].includes(currentWorkspace.status) &&
      asyncResultOr(workspaces, []).length &&
      mode === 'view'
    "
  >
    <div
      class="flex items-start justify-between gap-10 self-stretch text-lg text-gray-900 dark:text-gray-100"
    >
      <span> Select a workspace </span>
      <TextButton label="Add workspace" @click="mode = 'create'" />
    </div>
    <form class="flex flex-col items-start gap-10 self-stretch">
      <WorkspaceChooser />
      <WarehouseChooser />
    </form>
    <div class="flex items-center justify-end gap-10 self-stretch">
      <TextButton v-if="currentWorkspaceId" label="Delete" @click="deletingWorkspace = true" />
      <TextButton label="Edit" @click="mode = 'edit'" />
    </div>
  </div>

  <EditConnectionSettings
    v-if="mode !== 'view'"
    :mode="mode"
    :workspaceId="currentWorkspaceId"
    @save-workspace="updateWorkspace"
  />

  <Dialog
    v-if="deletingWorkspace && currentWorkspace.status === AsyncStatus.Succeeded"
    title="Delete workspace?"
    success-label="Delete"
    @succeeded="deleteWorkspace"
    @cancelled="deletingWorkspace = false"
  >
    Are you sure you want to delete workspace {{ currentWorkspace.result?.nickname }}? This cannot
    be undone.
  </Dialog>
</template>

<script lang="ts" setup>
import Icon from "@/common/components/Icon.vue";
import TextButton from "@/common/components/TextButton.vue";
import EditConnectionSettings from "@/common/components/settings/EditConnectionSettings.vue";
import { useWorkspaceStore } from "@/common/stores/workspaceStore";
import { onMounted, ref, inject, Ref, computed, watch } from "vue";
import Dialog from "@/common/components/Dialog.vue";
import { DarkMode } from "@/common/lib/keys";
const workspaceStore = useWorkspaceStore();
import { storeToRefs } from "pinia";
import { asyncResultOr, AsyncStatus, asyncValue } from "@/common/lib/async";
import WorkspaceChooser from "../WorkspaceChooser.vue";
import WarehouseChooser from "../WarehouseChooser.vue";
import { isNil } from "lodash";

const { currentWorkspace, currentWorkspaceId, workspaces } = storeToRefs(workspaceStore);

const mode = ref<"view" | "edit" | "create">("view");
const deletingWorkspace = ref(false);

function updateWorkspace() {
  mode.value = "view";
}

async function deleteWorkspace() {
  await workspaceStore.deleteCurrentWorkspace();
  deletingWorkspace.value = false;
}

watch(
  () => workspaces.value,
  (workspacesAsync) => {
    const workspaces = asyncValue(workspacesAsync);
    if (isNil(workspaces)) {
      return;
    }
    if (workspaces.length === 0) {
      mode.value = "create";
    }
  },
  { immediate: true }
);

onMounted(async () => {
  await workspaceStore.initializeWorkspaceState();
});

const darkMode = inject(DarkMode) as Ref<boolean>;
const iconColor = computed(() => (darkMode.value ? "white" : "black"));
</script>
