import { AuthProvider } from "@/common/auth/authProvider";
import { useAuthProvider } from "@/common/auth/authStore";
import { BackendUserError, FailureOptions, FailureType } from "@/common/lib/failure";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { backendFailure, FailureMessage } from "../stores/failureStore";

/* eslint-disable @typescript-eslint/no-explicit-any */
/* We are wrapping an API that specifies `any` */

type AxiosMethod<T = any, R = AxiosResponse<T>, D = any> = (
  config?: AxiosRequestConfig<D>
) => Promise<R>;

/**
 * Simple drop-in wrapper around axios to handle auth.
 */
export class Http {
  private _auth?: AuthProvider;

  public get auth(): AuthProvider {
    if (!this._auth) {
      this._auth = useAuthProvider();
    }
    if (!this._auth) {
      throw new Error("Error, useAuthProvider was empty");
    }
    return this._auth;
  }

  public set auth(auth: AuthProvider) {
    this._auth = auth;
  }

  public get<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    config?: AxiosRequestConfig<D>,
    failureOptions?: FailureOptions
  ): Promise<R> {
    return this.wrapMethod((c) => axios.get<T, R, D>(url, c))(config, failureOptions);
  }

  public post<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>,
    failureOptions?: FailureOptions
  ): Promise<R> {
    return this.wrapMethod((c) => axios.post<T, R, D>(url, data, c))(config, failureOptions);
  }

  public put<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>,
    failureOptions?: FailureOptions
  ): Promise<R> {
    return this.wrapMethod((c) => axios.put<T, R, D>(url, data, c))(config, failureOptions);
  }

  public patch<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>,
    failureOptions?: FailureOptions
  ): Promise<R> {
    return this.wrapMethod((c) => axios.patch<T, R, D>(url, data, c))(config, failureOptions);
  }

  public delete<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    config?: AxiosRequestConfig<D>,
    failureOptions?: FailureOptions
  ): Promise<R> {
    return this.wrapMethod((c) => axios.delete<T, R, D>(url, c))(config, failureOptions);
  }

  private wrapMethod<T = any, R = AxiosResponse<T>, D = any>(
    method: AxiosMethod<T, R, D>
  ): (config?: AxiosRequestConfig<D>, failureOptions?: FailureOptions) => Promise<R> {
    return (config?: AxiosRequestConfig<D>, failureOptions?: FailureOptions) => {
      return (async () => {
        try {
          // Add auth information to config.
          config = await this.updateConfig(config);
          try {
            // Try initial method
            return await method(config);
          } catch (error: unknown) {
            // If this is an auth error, try renewing token
            if (!isAuthError(error)) {
              throw error;
            }
            if (!(await this.auth.tryRefreshToken())) {
              throw error;
            }
          }
          // Try second response, with refreshed token.
          config = await this.updateConfig(config);
          return await method(config);
        } catch (error: unknown) {
          return this.handleError(error as AxiosError<any, any>, failureOptions);
        }
      })();
    };
  }

  private async updateConfig<D>(config?: AxiosRequestConfig<D>): Promise<AxiosRequestConfig<D>> {
    config ??= {};
    if (!this.auth.isAuthenticated) {
      return config;
    }
    await this.auth.waitAuthenticated();
    // TODO: handle failure if not authenticated
    const accessToken = await this.auth.getAccessToken();
    return {
      ...config,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
  }

  private handleError(error: AxiosError<any, unknown>, failureOptions?: FailureOptions): never {
    if (error.response?.data?.error === "AuthenticationError") {
      this.auth.handleAuthenticationError(error);
    }
    if (error.response?.data?.error === "KnowledgeValidationException") {
      throw new BackendUserError(error.response?.data?.cause, error);
    }
    throw new BackendError(
      failureOptions?.type ?? FailureType.Api,
      failureOptions?.description ?? "API Error",
      error
    );
  }
}

export const httpClient = new Http();

export function isAxiosError<T = any>(error: unknown): error is AxiosError<T, any> {
  return (error as AxiosError).isAxiosError;
}

export function isAuthError<T = any>(error: unknown): error is AxiosError<T, any> {
  return isAxiosError(error) && error.response?.data.error === "AuthenticationError";
}

export class BackendError extends Error {
  public failure?: FailureMessage;

  constructor(
    public type: FailureType,
    description: string,
    error: Error | undefined
  ) {
    super(description, { cause: error });
    this.failure = backendFailure({ type, description, error });
  }
}
