import { useNavigation } from "../composables/useNavigation";
import { AuthProvider } from "./authProvider";

/**
 * No-op implemented of AuthProvider.
 * Turns off frontend auth completely.
 */
export class NoopAuthProvider implements AuthProvider {
  // Allow access to all routes.
  public authGuard(): boolean {
    return true;
  }

  public getAccessToken(): Promise<string> {
    return Promise.reject(new Error("NoopAuthProvider getAccessToken not implemented"));
  }

  public loginWithRedirect(): Promise<void> {
    useNavigation().goToUrl("/home");
    return Promise.resolve();
  }

  public logout(): Promise<void> {
    return Promise.reject(new Error("NoopAuthProvider logout not implemented"));
  }

  public async waitAuthenticated(): Promise<boolean> {
    return await this.isAuthenticated;
  }

  public handleAuthenticationError(error: Error): Promise<void> {
    // We can't handle, so just rethrow.
    throw error;
  }

  public tryRefreshToken(): Promise<boolean> {
    return Promise.resolve(false);
  }

  public isLoading = false;

  public isEnabled = false;

  public isAuthenticated = false;
}
