import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { CurrentDatePropertyType, PropertyOpType } from "@/common/lib/derived";
import { onMounted } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'CurrentDatePropertyDef',
  props: {
    "modelValue": { type: Object },
    "modelModifiers": {},
  },
  emits: ["update:modelValue"],
  setup(__props, { expose: __expose }) {
  __expose();

const model = _useModel<CurrentDatePropertyType>(__props, "modelValue");
onMounted(() => {
  model.value = { op: PropertyOpType.CurrentDate };
});

const __returned__ = { model }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})