import { fromPairs, isArray, isString, omit, pick } from "lodash";
import { environment } from "../environments/environmentLoader";
import {
  CountPropertyType,
  DerivedPropertyTerm,
  PropertyOpType,
  PropertyReferenceType,
} from "./derived";
import {
  AliasLocations,
  FetchNFilter,
  FetchNNeighborhood,
  FetchNOrderBy,
  FetchNPathNode,
  FetchNRequest,
  FilterType,
  GROUP_BY_ALL,
  PropertyFilter,
} from "./fetchApi";
import {
  allQueryBranches,
  BaseQuery,
  columnName,
  filterIsComplete,
  findDeepColumnByAlias,
  Query,
  QueryBranch,
  QueryColumn,
  QueryFilter,
  QueryNeighborAddress,
  QueryOrderBy,
  QueryPathNode,
} from "./query";
import { QueryPropertyTerm } from "./queryProperties";

// This code converts a Query (as found in ./query.ts) to a FetchNRequest (see ./fetchApi.ts)
// Right now a lot of this is overcomplicated and the abstraction layer is way thicker than
// intended. Erik and I have plans to address this. Meanwhile let's chat if you want to work
// on this code. -jstreufert

const ROOT_ALIAS = "__root__";

export function expandQuery(query: Query): [FetchNRequest, AliasLocations] {
  // Hack: as a zeroth pass, find the base position for each branch, necessary
  // to calculate tag names for properties that contain branch references
  const nonRootBranches = allQueryBranches(query).slice(1) as QueryBranch[];
  const branchBases = fromPairs(nonRootBranches.map((nrb) => [nrb.alias, nrb.path.length * 2 - 1]));

  const branch = expandQueryBranch({ path: [], alias: ROOT_ALIAS, ...query }, branchBases);
  const request: FetchNRequest = {
    concept_type: query.root_concept_type,
    size: query.size ?? environment.requireNumber("EXPLORER_DEFAULT_QUERY_LIMIT"),
    order_by: query.order_by.map((ob) => expandOrderBy(query, ob, branch.aliasLocations)),
    group_by: branch.group_by,
    properties: branch.properties,
    filters: branch.filters,
    neighbors: branch.neighbors,
    columns: branch.columns,
  };
  return [request, branch.aliasLocations];
}

interface QueryBranchExpansion
  extends Pick<FetchNRequest, "group_by" | "columns" | "properties" | "filters" | "tag"> {
  neighbors: Record<string, FetchNNeighborhood>;
  aliasLocations: AliasLocations;
  alias: string;
  path: QueryPathNode[];
}

function expandQueryBranch(
  query: QueryBranch,
  branchBases: Record<string, number>,
  deepRefs: QueryNeighborAddress[] = []
): QueryBranchExpansion {
  const [properties, propertyRefs] = expandProperties(query.columns, branchBases, query.alias);
  deepRefs = [...deepRefs, ...propertyRefs];
  const subexpansions = (query.branches ?? []).map((b) =>
    expandQueryBranch(b, branchBases, deepRefs)
  );
  const [localNeighbors, localAliasLocations] = buildNeighborhoods(query, deepRefs);
  const aliasLocations = Object.assign(
    {},
    localAliasLocations,
    ...subexpansions.map((se) => se.aliasLocations)
  );
  let rootGroupBy = query.group_by;
  if (isArray(rootGroupBy)) {
    rootGroupBy = rootGroupBy.filter(
      (gb) => query.columns.find((col) => col.alias === gb)!.path == null
    );
  }

  const neighbors = Object.assign(
    {},
    localNeighbors,
    ...subexpansions.map(function (se) {
      let path = expandPath(
        se.path,
        pick(se, "filters", "group_by", "properties", "neighbors", "tag")
      );
      if (Object.keys(se.neighbors).length > 1) {
        throw "Neighborhood branching not yet supported";
      } else if (Object.keys(se.neighbors).length == 1) {
        // Since QE doesn't currently support branching neighborhoods, make an
        // attempt to append a single neighborhood to this one instead
        path = [...path, ...Object.values(se.neighbors)[0]];
      }
      return {
        [se.alias]: path,
      };
    })
  );
  return {
    neighbors,
    aliasLocations,
    alias: query.alias,
    path: query.path,
    columns: query.columns.map((column) => ({
      alias: column.alias,
      name: columnName(column),
    })),
    group_by: rootGroupBy,
    properties,
    filters: query.filters.filter(filterIsComplete).map((ef) => expandFilter(ef, aliasLocations)),
    tag: generateTagName(query.alias, query.path.length * 2 - 1),
  };
}

function buildNeighborhoods(
  query: QueryBranch,
  additionalRefs: QueryNeighborAddress[]
): [Record<string, FetchNNeighborhood>, AliasLocations] {
  const neighborhoods: Record<string, FetchNNeighborhood> = {};
  const aliasLocations: AliasLocations = {};

  function addNeighborhood(
    path: QueryPathNode[],
    endpointAttrs: Omit<FetchNPathNode, "concept_type" | "tag"> = {}
  ) {
    const neighborhoodKey = generateNeighborhoodKey(query.alias, path);
    if (!Object.hasOwn(neighborhoods, neighborhoodKey)) {
      neighborhoods[neighborhoodKey] = expandPath(path, {
        tag: generateTagName(neighborhoodKey, path.length * 2 - 1),
        ...endpointAttrs,
      });
    } else {
      // Merge existing and new endpoint attributes
      const node = neighborhoods[neighborhoodKey][path.length * 2 - 1] as FetchNPathNode;
      if (endpointAttrs.group_by != null) {
        if (endpointAttrs.group_by === GROUP_BY_ALL || node.group_by === GROUP_BY_ALL) {
          node.group_by = GROUP_BY_ALL;
        } else {
          node.group_by = [...(node.group_by ?? []), ...endpointAttrs.group_by];
        }
      }
      if (endpointAttrs.properties) {
        node.properties = { ...endpointAttrs.properties, ...(node.properties ?? {}) };
      }
    }
    return neighborhoodKey;
  }
  for (const column of query.columns) {
    if (column.path) {
      const [ptype] = expandProperty(column.property_type, {}, ROOT_ALIAS);
      const nkey = addNeighborhood(column.path, {
        properties: { [column.alias]: ptype },
      });
      aliasLocations[column.alias] = { neighborhood: nkey, position: column.path.length * 2 - 1 };
    } else if (query.path.length > 0) {
      aliasLocations[column.alias] = {
        neighborhood: query.alias,
        position: query.path.length * 2 - 1,
      };
    }
  }
  for (const filter of query.filters) {
    if (filter.path) {
      const nkey = addNeighborhood(filter.path);
      aliasLocations[filter.alias] = { neighborhood: nkey, position: filter.path.length * 2 - 1 };
    }
  }
  if (isArray(query.group_by)) {
    for (const group of query.group_by) {
      const path = query.columns.find((c) => c.alias === group)?.path;
      if (path != null) addNeighborhood(path, { group_by: [group] });
    }
  }
  for (const ref of additionalRefs.filter((r) => r.path.length > 0)) {
    if ((ref.branch ?? ROOT_ALIAS) === query.alias) addNeighborhood(ref.path);
  }
  return [neighborhoods, aliasLocations];
}

function expandFilter(filter: QueryFilter, aliasLocations: AliasLocations): FetchNFilter {
  const tag = filter.path != null ? aliasLocations[filter.alias] : undefined;
  const on_tag = tag ? generateTagName(tag.neighborhood, tag.position) : undefined;
  let filters: PropertyFilter[];
  if (filter.type === FilterType.Exists) {
    filters = [
      {
        type: filter.type,
        property_type: filter.property_type,
        on_alias: filter.on,
        on_tag,
      },
    ];
  } else {
    filters = filter.values.map((value) => ({
      type: filter.type,
      property_type: filter.property_type,
      on_alias: filter.on,
      on_tag,
      ...value,
    })) as PropertyFilter[];
  }
  let outerFilter: FetchNFilter;
  if (filters.length === 1) {
    outerFilter = filters[0];
  } else {
    outerFilter = { type: FilterType.Or, filters }; // Later, make op configurable
  }
  if (filter.negated) outerFilter = { type: FilterType.Not, filter: outerFilter };
  return outerFilter;
}

function expandOrderBy(
  query: BaseQuery,
  orderBy: QueryOrderBy,
  aliasLocations: AliasLocations
): FetchNOrderBy {
  const column = findDeepColumnByAlias(query, orderBy.on);
  if (column == null) throw `Order by column missing (${orderBy.on})`;
  const needsTag = column.path != null || query.columns.find((c) => c.alias === orderBy.on) == null;
  const tag = needsTag ? aliasLocations[column.alias] : undefined;
  return {
    ...orderBy,
    on_tag: tag ? generateTagName(tag.neighborhood, tag.position) : undefined,
  };
}

// Replaces QueryNeighborAddresses in property types with tag references,
// extracting the Addresses and returning them along with the property dictionary.
// Also ensures there are no naked property string references in the output
function expandProperty(
  term: QueryPropertyTerm,
  branchBases: Record<string, number>,
  currentBranch: string
): [DerivedPropertyTerm, QueryNeighborAddress[]] {
  const refs: QueryNeighborAddress[] = [];

  function resolveNeighborAddress(neighbor: QueryNeighborAddress) {
    const onBranch = neighbor.branch ?? currentBranch;
    const neighborhoodKey = generateNeighborhoodKey(
      neighbor.branch ?? currentBranch,
      neighbor.path
    );
    const on_tag = generateTagName(
      neighborhoodKey,
      // This || is intentional - we want to use the branchBase if the path is empty
      neighbor.path.length || (onBranch === ROOT_ALIAS ? 0 : branchBases[onBranch])
    );
    const branch = onBranch === ROOT_ALIAS ? undefined : onBranch;
    return { on_tag, branch };
  }

  function expandTerm(qterm: QueryPropertyTerm, isTopLevel: boolean = false): DerivedPropertyTerm {
    if (isString(qterm)) {
      // This is a hack (https://claritype.slack.com/archives/C08FMAB6SRY/p1741894208125829)
      if (isTopLevel) return qterm;
      return { op: PropertyOpType.Property, property_type: qterm };
    }
    switch (qterm.op) {
      case PropertyOpType.Count: {
        const fterm: CountPropertyType = omit(qterm, "on_neighbor");
        if (fterm.term != null) fterm.term = expandTerm(fterm.term);
        if (qterm.on_neighbor != null) {
          const { on_tag, branch } = resolveNeighborAddress(qterm.on_neighbor);
          fterm.on_tag = on_tag;
          refs.push({ ...qterm.on_neighbor, branch });
        }
        return fterm;
      }

      case PropertyOpType.Property: {
        const fterm: PropertyReferenceType = omit(qterm, "on_neighbor");
        if (qterm.on_neighbor != null) {
          const { on_tag, branch } = resolveNeighborAddress(qterm.on_neighbor);
          fterm.on_tag = on_tag;
          refs.push({ ...qterm.on_neighbor, branch });
        }
        return fterm;
      }

      case PropertyOpType.Sum:
      case PropertyOpType.Avg:
      case PropertyOpType.Median:
      case PropertyOpType.Percentile:
      case PropertyOpType.Max:
      case PropertyOpType.Min:
      case PropertyOpType.Ntile:
      case PropertyOpType.DateTrunc:
      case PropertyOpType.Map:
        return { ...qterm, term: expandTerm(qterm.term) };

      case PropertyOpType.Add:
      case PropertyOpType.Subtract:
        return { ...qterm, terms: qterm.terms.map((t) => expandTerm(t)) };

      case PropertyOpType.Multiply:
        return { ...qterm, factors: qterm.factors.map((t) => expandTerm(t)) };

      case PropertyOpType.Divide:
        return {
          ...qterm,
          divisor: expandTerm(qterm.divisor),
          dividend: expandTerm(qterm.dividend),
        };
      case PropertyOpType.ConstantValue:
        return qterm;

      case PropertyOpType.DateDiff:
        return {
          ...qterm,
          start: expandTerm(qterm.start),
          end: expandTerm(qterm.end),
        };

      case PropertyOpType.CurrentDate:
        return qterm;
    }
  }

  return [expandTerm(term, true), refs];
}

function expandProperties(
  columns: QueryColumn[],
  branchBases: Record<string, number>,
  currentBranch: string
): [Record<string, DerivedPropertyTerm>, QueryNeighborAddress[]] {
  const properties: Record<string, DerivedPropertyTerm> = {};
  const refs: QueryNeighborAddress[] = [];
  for (const column of columns.filter((c) => c.path == null)) {
    const [propType, refs] = expandProperty(column.property_type, branchBases, currentBranch);
    properties[column.alias] = propType;
    refs.push(...refs);
  }
  return [properties, refs];
}

function expandPath(
  path: QueryPathNode[],
  endpointAttrs: Partial<FetchNPathNode> = {}
): FetchNNeighborhood {
  const neighborhood: FetchNNeighborhood = [];
  for (const pathNode of path) {
    neighborhood.push(pathNode.link_descriptor!);
    neighborhood.push({ concept_type: pathNode.concept_type });
  }
  neighborhood[neighborhood.length - 1] = {
    ...(neighborhood[neighborhood.length - 1] as FetchNPathNode),
    ...endpointAttrs,
  };
  return neighborhood;
}

function generateNeighborhoodKey(alias: string, path: QueryPathNode[]) {
  return [
    alias,
    ...path.flatMap((element) => [element.link_descriptor, element.concept_type]),
  ].join("_");
}

function generateTagName(neighborhoodKey: string, position: number) {
  return `${neighborhoodKey}_${position}`;
}
