import { defineComponent as _defineComponent } from 'vue'
import { IndicatorVisualization } from "@/reader/lib/visualizationTypes";
import { computed, toRefs } from "vue";
import GraphValueComponent from "@/common/components/GraphValue.vue";
import { UseQueryResult } from "@/reader/composables/useQuery";
import { FloatValue, GraphValue, isValue, stringifyValue, toNative } from "@/common/lib/value";
import useVisualization from "@/reader/composables/useVisualization";
import { ValueGenerator } from "@/reader/lib/visualization";
import Icon from "@/common/components/Icon.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'Indicator',
  props: {
    visualization: { type: Object, required: true },
    results: { type: Array, required: true },
    comparisonResults: { type: [Array, null], required: true },
    height: { type: Number, required: true },
    width: { type: Number, required: true }
  },
  emits: ["select"],
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;
const { visualization, results, comparisonResults, height, width } = toRefs(props);



const { valueSets } = useVisualization(
  visualization,
  results,
  comparisonResults,
  [],
  ["value"],
  ["value"]
);

const primaryDatum = computed(function () {
  if (valueSets.value.length !== 1) return null;
  return valueSets.value[0].primary["value"];
});

const comparisonDatum = computed(function () {
  if (valueSets.value.length !== 1) return null;
  return valueSets.value[0].comparison?.["value"];
});

const diffDatum = computed(function () {
  if (valueSets.value.length !== 1) return null;
  return valueSets.value[0].diff?.["value"];
});

const primaryTextHeight = computed(() => {
  let size = height.value / 5 + 25;
  const stringLength = stringifyValue(primaryDatum.value?.formattedValue).length;

  if (stringLength * (size / 1.5) > width.value) {
    size = (1.5 * width.value) / stringLength;
  }
  return size;
});

const comparisonTextHeight = computed(() =>
  Math.min(Math.max(primaryTextHeight.value / 3, 14), 30)
);

const comparisonIsGood = computed(function () {
  const prim = primaryDatum.value?.originalValue;
  const comp = comparisonDatum.value?.originalValue;
  if (!isValue(comp) || !isValue(prim)) return false;
  const isPositive = toNative(prim as FloatValue) > toNative(comp as FloatValue);
  if (visualization.value.config.lower_is_better) return !isPositive;
  return isPositive;
});

const __returned__ = { props, visualization, results, comparisonResults, height, width, valueSets, primaryDatum, comparisonDatum, diffDatum, primaryTextHeight, comparisonTextHeight, comparisonIsGood, GraphValueComponent, Icon }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})