<template>
  <div class="absolute inset-0 flex items-center justify-center">
    <div v-if="datum != null" :style="{ fontSize: `${textHeight}px` }">
      <GraphValueComponent :value="datum.formattedValue" />
    </div>
    <div v-else>&mdash;</div>
  </div>
</template>

<script lang="ts" setup>
import { generateValue, IndicatorVisualization } from "@/reader/lib/visualization";
import { computed, toRefs } from "vue";
import GraphValueComponent from "@/common/components/GraphValue.vue";
import { UseQueryResult } from "@/reader/composables/useQuery";

const props = defineProps<{
  visualization: IndicatorVisualization;
  results: UseQueryResult[];
  height: number;
}>();
const { visualization, results, height } = toRefs(props);

const datum = computed(function () {
  const viz = visualization.value;
  if (results.value.length !== 1) return null;
  return generateValue(viz.config.value, results.value[0], viz.query);
});
const textHeight = computed(() => {
  return height.value / 5 + 25;
});
</script>
