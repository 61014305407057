<template>
  <div class="absolute inset-0 flex flex-col">
    <div
      v-if="exploreStore.context === ExploreContext.Embedded"
      class="border-b border-b-gray-900 bg-gray-800 px-20 py-10"
    >
      <div class="flex flex-row justify-center gap-15">
        <AskBar class="flex basis-6/12" />
        <BookmarkMenu
          @updateBookmark="openUpdateBookmark"
          @displayError="displayError"
          @deleteBookmark="deleteBookmark"
          :projectId="projectId"
        />
      </div>
    </div>
    <Teleport v-if="exploreStore.context === ExploreContext.Standalone" to="#title-bar-controls">
      <div class="flex flex-row justify-center gap-15">
        <AskBar class="flex basis-6/12" />
        <BookmarkMenu
          @updateBookmark="openUpdateBookmark"
          @displayError="displayError"
          @deleteBookmark="deleteBookmark"
          :projectId="projectId"
        />
      </div>
    </Teleport>
    <div class="flex min-h-0 basis-full flex-row">
      <div
        v-if="exploreStore.showSidebars()"
        class="relative flex-none basis-[325px] overflow-y-auto border-r border-gray-200 bg-gray-100 p-15 dark:border-r-gray-900 dark:bg-gray-800"
      >
        <QueryBasics />
      </div>
      <div class="relative isolate flex basis-full flex-col">
        <div
          class="absolute inset-0 z-10 bg-gray-200 dark:bg-gray-900"
          v-if="exploreMetagraphStore.visible"
        >
          <MetagraphPane />
        </div>
        <div class="absolute inset-0 z-0 flex flex-col">
          <div
            class="flex flex-none flex-row justify-between border-b border-b-gray-200 bg-gray-100 dark:border-b-gray-900 dark:bg-gray-800"
          >
            <div class="flex flex-row">
              <TabSelect
                :tabs="tabs"
                :modelValue="exploreStore.mode"
                @update:model-value="exploreStore.setMode"
                :disabled="exploreStore.query == null"
              />
            </div>
            <div class="flex items-center justify-between gap-10 pr-5">
              <IconButton2
                name="md:star"
                @click="bookmarkStatus = 'create'"
                v-if="bookmarksEnabled && exploreStore.query"
                size="l"
              />
              <div v-if="exportEnabled" class="min-w-[120px]">
                <TextButton
                  v-if="!exporting"
                  label="Export to Excel"
                  icon="table_export"
                  @click="downloadExcel()"
                />
                <SpinnerButton v-else label="Exporting…" :icon="true" />
              </div>
              <TextButton
                label="Create test"
                icon="md:labs"
                v-if="createTestEnabled"
                @click="showCreateTestDialog = true"
              />
              <IconButton
                class="rotate-90"
                :name="exploreStore.toolsVisible ? 'reveal-panel' : 'hide-panel'"
                @click="exploreStore.toolsVisible = !exploreStore.toolsVisible"
              />
            </div>
          </div>
          <div class="relative basis-full bg-white dark:bg-gray-900">
            <AsyncWrapper :async="exploreStore.table" v-if="exploreStore.mode === Mode.Table">
              <Table />
            </AsyncWrapper>
            <AsyncWrapper :async="exploreStore.sqlData" v-if="exploreStore.mode === Mode.SQL">
              <SqlView />
            </AsyncWrapper>
          </div>
          <QueryProblems />
        </div>
      </div>
      <div
        class="relative flex-none basis-[400px] border-l border-gray-300 dark:border-gray-900"
        v-if="exploreStore.toolsVisible"
      >
        <ExploreTools />
      </div>
    </div>
  </div>

  <Modal v-if="exploreStore.creatingCalculation !== undefined">
    <CalculationForm />
  </Modal>

  <Dialog
    v-if="showCreateTestDialog"
    title="Create Query Engine Test"
    success-label="Create Test"
    @succeeded="createExplorerTest()"
    @cancelled="showCreateTestDialog = false"
  >
    <Textbox label="Name of integration test" v-model="testName" />
  </Dialog>

  <Dialog
    v-if="bookmarkStatus === 'create' || bookmarkStatus === 'update'"
    :title="bookmarkStatus === 'create' ? 'Add Bookmark' : 'Rename Bookmark'"
    icon="md:star"
    success-label="Done"
    @succeeded="handleBookmark"
    @cancelled="clearBookmark"
  >
    <Textbox label="Bookmark Name" v-model="bookmarkName" :autoselect="true" :required="true" />
  </Dialog>

  <Dialog
    v-if="bookmarkStatus === 'showInvalidBookmark' || bookmarkStatus === 'failure'"
    title="Error Details"
    icon="md:error"
    :cancel-label="bookmarkStatus === 'showInvalidBookmark' ? 'Delete bookmark' : null"
    @succeeded="bookmarkStatus = undefined"
    @cancelled="deleteBookmark(currentBookmark)"
  >
    <ul>
      <div v-for="(error, index) in bookmarkErrors" :key="index">{{ error }}</div>
    </ul>
  </Dialog>
</template>

<script lang="ts" setup>
import AsyncWrapper from "@/common/components/AsyncWrapper.vue";
import Modal from "@/common/components/Modal.vue";
import CalculationForm from "../components/explore/calculation/CalculationForm.vue";
import MetagraphPane from "../components/explore/metagraph/MetagraphPane.vue";
import QueryBasics from "../components/explore/QueryBasics.vue";
import QueryProblems from "../components/explore/QueryProblems.vue";
import SqlView from "../components/explore/SqlView.vue";
import Table from "../components/explore/Table.vue";
import BookmarkMenu from "../components/explore/BookmarkMenu.vue";
import { Mode, useExploreStore, ExploreContext } from "../stores/explore";
import { useExploreMetagraphStore } from "../stores/exploreMetagraph";
import { computed, onMounted, onUnmounted, ref, Ref, watch } from "vue";
import TabSelect from "@/common/components/TabSelect.vue";
import ExploreTools from "../components/explore/ExploreTools.vue";
import IconButton from "@/common/components/IconButton.vue";
import IconButton2 from "@/common/components/IconButtonV2.vue";
import AskBar from "../components/explore/AskBar.vue";
import TextButton from "@/common/components/TextButton.vue";
import { environment } from "@/common/environments/environmentLoader";
import Textbox from "@/common/components/Textbox.vue";
import Dialog from "@/common/components/Dialog.vue";
import { cloneDeep, isEmpty } from "lodash";
import { useUserModuleStore, Bookmark } from "@/common/stores/userModuleStore";
import SpinnerButton from "@/common/components/SpinnerButton.vue";
import { Query, validateQuery } from "@/common/lib/query";
import { consumeStoredExploreQuery } from "../lib/storage";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import { storeExploreQuery } from "../lib/storage";

const exploreStore = useExploreStore();
const exploreMetagraphStore = useExploreMetagraphStore();
const userModuleStore = useUserModuleStore();
const router = useRouter();
const route = useRoute();

const projectId = computed(() => exploreStore.module);
const bookmarksEnabled = computed(() => projectId.value);
const createTestEnabled = environment.requireBoolean("EXPLORER_CREATE_TEST_ENABLED");
const exportEnabled = environment.requireBoolean("EXCEL_EXPORT_ENABLED");
const showCreateTestDialog = ref(false);
const testName = ref("");
const bookmarkStatus: Ref<"create" | "update" | "showInvalidBookmark" | "failure" | undefined> =
  ref(undefined);
const bookmarkName = ref<string>();
const currentBookmark: Ref<Bookmark | undefined> = ref(undefined);

const props = defineProps<{ bookmark?: string; module?: string; moduleId?: string }>();
const exporting = ref(false);

const tabs = computed(() => {
  return [
    {
      label: "Table",
      key: Mode.Table,
      icon: "md:table",
      disabled: exploreStore.query == null,
    },
    {
      label: "SQL",
      key: Mode.SQL,
      icon: "SQL",
      disabled: exploreStore.query == null,
    },
  ];
});

function createExplorerTest() {
  exploreStore.load(true, { testName: testName.value });
  testName.value = "";
  showCreateTestDialog.value = false;
}
async function loadBookmark(bookmarkId: string) {
  if (projectId.value) {
    try {
      const response = await userModuleStore.getBookmark(projectId.value, bookmarkId);
      const errors = validateQuery(response.state, exploreStore.metagraph);
      if (errors.length > 0) {
        bookmarkStatus.value = "showInvalidBookmark";
        bookmarkErrors.value = errors;
        currentBookmark.value = response;
      } else {
        router.push({
          name: route.name,
          params: { module: projectId.value },
        });

        exploreStore.loadQuery(response.state);
      }
    } catch (error: string) {
      bookmarkStatus.value = "failure";
      bookmarkErrors.value = [error];
    }
  }
}

async function handleBookmark() {
  if (bookmarkStatus.value == "create") {
    await addBookmark();
  } else {
    await updateBookmark();
  }
}

async function addBookmark() {
  if (!projectId.value || !bookmarkName.value) {
    return;
  }
  try {
    const bookmark = cloneDeep(exploreStore.query);
    if (!bookmark) return;
    const bookmarkId = await userModuleStore.newBookmark(projectId.value, {
      name: bookmarkName.value,
      description: "",
      state: bookmark,
    });

    if (bookmarkId) {
      userModuleStore.loadProjectBookmarks(projectId.value);
    }

    clearBookmark();
  } catch (error) {
    bookmarkStatus.value = "failure";
    bookmarkErrors.value = [String(error)];
  }
}
async function updateBookmark() {
  const bookmarkToUpdate = currentBookmark.value;
  if (!projectId.value || !bookmarkName.value || !bookmarkToUpdate) {
    return;
  }
  try {
    const bookmarkId = await userModuleStore.updateBookmark(projectId.value, bookmarkToUpdate.id, {
      id: bookmarkToUpdate.id,
      name: bookmarkName.value,
      description: "",
    });

    if (bookmarkId && projectId.value) {
      userModuleStore.loadProjectBookmarks(projectId.value);
    }

    clearBookmark();
  } catch (error: string) {
    bookmarkStatus.value = "failure";
    bookmarkErrors.value = [error];
  }
}

async function deleteBookmark(bookmark: Bookmark | undefined) {
  if (projectId.value && bookmark) {
    try {
      await userModuleStore.deleteBookmark(projectId.value, bookmark.id);
      userModuleStore.loadProjectBookmarks(projectId.value);
    } catch (error: string) {
      bookmarkStatus.value = "failure";
      bookmarkErrors.value = [error];
    }

    clearBookmark();
  }
}

function openUpdateBookmark(bookmark: Bookmark) {
  bookmarkStatus.value = "update";
  currentBookmark.value = bookmark;
  bookmarkName.value = bookmark.metadata.name;
}

const bookmarkErrors: Ref<string[]> = ref([]);
function displayError(invalidBookmark: Bookmark, errors: string[]) {
  bookmarkStatus.value = "showInvalidBookmark";
  bookmarkErrors.value = errors;
  currentBookmark.value = invalidBookmark;
}

function clearBookmark() {
  bookmarkName.value = undefined;
  bookmarkStatus.value = undefined;
}

async function downloadExcel() {
  exporting.value = true;
  try {
    await exploreStore.downloadExcel();
  } finally {
    exporting.value = false;
  }
}

const maybeQuery: Ref<Query | null> = ref(null);
watch(
  () => exploreStore.metagraph,
  () => {
    if (exploreStore.module) {
      userModuleStore.loadProjectBookmarks(exploreStore.module);
    }
    if (props.bookmark) {
      loadBookmark(props.bookmark);
    } else if (
      maybeQuery.value &&
      isEmpty(validateQuery(maybeQuery.value, exploreStore.metagraph))
    ) {
      exploreStore.loadQuery(maybeQuery.value);
    }
  },
  { immediate: true }
);

watch(
  () => props.bookmark,
  (newVal) => {
    if (newVal) {
      loadBookmark(newVal);
    }
  }
);

const storeCurrentQuery = environment.requireBoolean("EXPLORER_STORE_CURRENT_QUERY");

onMounted(function () {
  if (storeCurrentQuery) {
    window.addEventListener("beforeunload", handleBeforeUnload);
  }
  if (props.bookmark) {
    loadBookmark(props.bookmark);
  } else {
    maybeQuery.value = consumeStoredExploreQuery();
    if (maybeQuery.value) {
      exploreStore.loadQuery(maybeQuery.value);
    }
  }
});

const handleBeforeUnload = () => {
  const query = exploreStore.query;
  query && storeExploreQuery(query);
};

if (storeCurrentQuery) {
  onUnmounted(function () {
    window.removeEventListener("beforeunload", handleBeforeUnload);
  });
}
</script>
