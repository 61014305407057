<template>
  <div
    class="bar-track relative h-[10px] w-[100px] overflow-hidden rounded-sm border border-gray-200 bg-white dark:border-0 dark:bg-gray-700"
  >
    <div class="bar absolute bottom-0 left-0 top-0 bg-orange" :style="{ width: barWidth }"></div>
  </div>
</template>

<script setup lang="ts">
import { asyncValue } from "@/common/lib/async";
import { QueryColumn } from "@/common/lib/query";
import { FloatValue, toNative } from "@/common/lib/value";
import { useExploreStore } from "@/reader/stores/explore";
import { computed, toRefs } from "vue";

const props = defineProps<{ value: FloatValue; column: QueryColumn }>();
const { value, column } = toRefs(props);

const exploreStore = useExploreStore();

const barWidth = computed(function () {
  const thisValue = toNative(value.value);
  const stats = asyncValue(exploreStore.columnStats)![column.value.alias];
  const max = stats.max.value as number;
  const percentage = (thisValue / max) * 100;
  return `${percentage}%`;
});
</script>
