<template>
  <IconButton
    :name="expanded ? 'arrow_drop_down' : 'arrow_right'"
    color="white"
    :size="size"
    :target-size="20"
    @click="emit('toggle')"
  />
</template>

<style lang="scss" scoped></style>

<script lang="ts" setup>
import IconButton from "@/common/components/IconButtonV2.vue";
import { toRefs } from "vue";
import { IconSize } from "../lib/icons";

const props = withDefaults(defineProps<{ expanded: boolean; size?: IconSize }>(), {
  size: "l",
});
const { expanded, size } = toRefs(props);

const emit = defineEmits<{ (e: "toggle"): void }>();
</script>
