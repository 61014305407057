<template>
  <div class="flex items-center">
    <IconButtonV2
      :name="orderBy.asc ? 'sort-asc' : 'sort-desc'"
      :label="orderBy.asc ? 'Switch to Descending' : 'Switch to Ascending'"
      @click="toggleAsc"
      size="m"
    />
    <v-select
      class="select basis-full"
      :class="{ 'light-select': !darkMode }"
      :model-value="selectedColumn"
      :options="exploreStore.query!.columns"
      :getOptionLabel="columnName"
      @option:selected="updateColumn"
      :clearable="false"
      placeholder="Choose a Property"
    />
  </div>
</template>

<script lang="ts" setup>
import { useExploreStore } from "@/reader/stores/explore";
import { Ref, computed, inject, ref, watchEffect } from "vue";
import vSelect from "vue-select";
import { find } from "lodash";
import IconButtonV2 from "@/common/components/IconButtonV2.vue";
import { DarkMode } from "@/common/lib/keys";
import { QueryColumn, QueryOrderBy } from "@/common/lib/query";
import { FetchNOrderBy } from "@/common/lib/fetchApi";
import { columnName } from "@/common/lib/query";

const darkMode = inject(DarkMode) as Ref<boolean>;

const orderBy: Ref<Partial<QueryOrderBy>> = ref({});
const model = defineModel<QueryOrderBy | null>({ required: true });

const exploreStore = useExploreStore();

const selectedColumn = computed(function () {
  if (orderBy.value.on == null) return undefined;
  return find(exploreStore.query!.columns, { alias: orderBy.value.on });
});

function toggleAsc() {
  updateOrderBy({ asc: !orderBy.value.asc });
}

function updateColumn(col: QueryColumn) {
  updateOrderBy({ on: col.alias });
}

function updateOrderBy(changes: Partial<FetchNOrderBy>) {
  const newValue = { ...orderBy.value, ...changes };
  orderBy.value = newValue;
  if (newValue.asc != null && newValue.on != null) model.value = newValue as FetchNOrderBy;
}

watchEffect(
  () =>
    (orderBy.value = {
      on: model.value?.on,
      asc: model.value?.asc ?? true,
    })
);
</script>
