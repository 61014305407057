<template>
  <div class="absolute inset-0 flex min-w-0">
    <div class="basis-full overflow-hidden">
      <Chart :spec="spec" @select="handleSelect" />
    </div>
    <div class="relative" v-if="isStacked">
      <ResizeObserver :emit-on-mount="true" @notify="handleLegendResize" />
      <Legend :items="legend" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  colorizer,
  emptyFormattedValue,
  generatorAlias,
  generatorName,
  visualizationTheme,
} from "@/reader/lib/visualization";
import { TimeDistributionVisualization } from "@/reader/lib/visualizationTypes";
import { ComputedRef, Ref, computed, inject, ref, toRefs } from "vue";
import * as vega from "vega";
import Chart from "@/common/components/Chart.vue";
import { DarkMode } from "@/common/lib/keys";
import {
  DatetimeValue,
  FloatValue,
  GraphValue,
  GraphValueType,
  stringifyValue,
  toNative,
  toValue,
} from "@/common/lib/value";
import { DateTime, DateTimeUnit } from "luxon";
import { isEqual, isString, uniqWith } from "lodash";
import { filterValueType, PropertyOpType } from "@/common/lib/derived";
import { UseQueryResult } from "@/reader/composables/useQuery";
import { findDeepColumnByAlias } from "@/common/lib/query";
import { TRANSFORMERS } from "@/common/lib/format";
import { combinedQuerySignature } from "@/common/lib/combiningQuery";
import { LegendItem } from "../page/Legend.vue";
import Legend from "../page/Legend.vue";
import { ResizeObserver } from "vue-resize";
import useVisualization from "@/reader/composables/useVisualization";

const props = defineProps<{
  visualization: TimeDistributionVisualization;
  results: UseQueryResult[];
  width?: number;
  height?: number;
}>();
const { visualization, results, width, height } = toRefs(props);

const emit = defineEmits<{
  select: [alias: string, value: [GraphValue, GraphValue]];
}>();

const darkMode = inject(DarkMode) as Ref<boolean>;

const { generators, query, valueSets, legendWidth, handleLegendResize } = useVisualization(
  visualization,
  results,
  ref(null),
  ["time", "category"],
  ["value"],
  ["time", "value"]
);

interface Datum {
  time: Date;
  value: number;
  category?: string;
  tooltip: Record<string, string>;
  color: string;
}

const isStacked = computed(() => generators().category != null);
const colors = computed(() =>
  colorizer(
    query(),
    generators(),
    darkMode.value,
    isStacked.value ? "category" : undefined,
    isStacked.value
  )
);

const data = computed(function () {
  const config = visualization.value.config;
  const query = combinedQuerySignature(visualization.value.query);
  const timeName = generatorName(config.time, query);
  const valueName = generatorName(config.value, query);
  return valueSets.value.map(function (values): Datum {
    const vals = values.primary;
    const time = toNative(vals.time!.originalValue as DatetimeValue);
    const value = vals.value!.originalValue as FloatValue;
    const category = isStacked.value
      ? stringifyValue((vals.category ?? emptyFormattedValue()).formattedValue)
      : null;
    const tooltip: Record<string, string> = {
      [timeName]: formatTime(time),
    };
    if (category != null) {
      tooltip[generatorName(config.category!, query)] = category;
    }
    tooltip[valueName] = stringifyValue(vals.value!.formattedValue);
    return {
      time: time.toJSDate(),
      value: toNative(value),
      category: category ?? undefined,
      tooltip,
      color: colors.value(vals),
    };
  });
});

// Sets vega's time unit by peeking at the property def for our time buckets
// This determines the width of each time bucket bar
const timeUnit = computed(function () {
  const prop = timeProp();
  if (prop == null || isString(prop) || prop.op !== PropertyOpType.DateTrunc) return "day";
  return (
    {
      YEAR: "year",
      QUARTER: "quarter",
      MONTH: "month",
      WEEK: "week",
      DAY: "day",
      HOUR: "hours", // note that some of these are plural
      MINUTE: "minutes",
      SECOND: "seconds",
      MILLISECOND: "milliseconds",
    }[prop.bucket_size.toUpperCase()] ?? "day"
  );
});

const legend = computed(function () {
  if (!isStacked.value) return [];
  const categories = uniqWith(valueSets.value, (a, b) =>
    isEqual(a.primary.category, b.primary.category)
  );
  return categories.map(function (valueSet): LegendItem {
    const vals = valueSet.primary;
    return {
      identifier: vals.category?.originalValue as GraphValue | undefined,
      label: vals.category ?? emptyFormattedValue(),
      color: colors.value(vals),
    };
  });
});

const spec: ComputedRef<vega.Spec> = computed(function () {
  const theme = visualizationTheme(darkMode.value);
  const brushCollideExpr = `isArray(brush) && (brush[0] != brush[1]) && collide([scale('x', datum.time), scale('x', utcOffset('${timeUnit.value}', datum.time, 1))], brush)`;
  const spec: vega.Spec = {
    width: (width.value ?? 370) - legendWidth.value - 10,
    height: (height.value ?? 400) - 10,
    padding: 5,
    autosize: "fit",
    data: [
      {
        name: "table",
        values: data.value,
        transform: [
          {
            type: "stack",
            groupby: ["time"],
            sort: { field: "category" },
            field: "value",
          },
        ],
      },
    ],
    signals: [
      {
        name: "brush",
        value: 0,
        on: [
          {
            events: "pointerdown",
            update: "[x(), x()]",
          },
          {
            events: "[pointerdown, window:pointerup] > window:pointermove!",
            update: "[brush[0], clamp(x(), 0, width)]",
          },
          {
            events: { signal: "delta" },
            update: "clampRange([anchor[0] + delta, anchor[1] + delta], 0, width)",
          },
        ],
      },
      {
        name: "anchor",
        value: null,
        on: [{ events: "@brush:pointerdown", update: "slice(brush)" }],
      },
      {
        name: "xdown",
        value: 0,
        on: [{ events: "@brush:pointerdown", update: "x()" }],
      },
      {
        name: "delta",
        value: 0,
        on: [
          {
            events: "[@brush:pointerdown, window:pointerup] > window:pointermove!",
            update: "x() - xdown",
          },
        ],
      },
      {
        name: "selection",
        value: null,
        on: [{ events: "pointerup", update: "[invert('x', brush[0]), invert('x', brush[1])]" }],
      },
    ],
    scales: [
      {
        name: "x",
        type: "time",
        range: "width",
        domain: { data: "table", field: "time" },
        domainMax: {
          signal: `utcOffset('${timeUnit.value}', extent(pluck(data('table'), 'time'))[1], 1)`,
        },
      },
      {
        name: "y",
        type: "linear",
        range: "height",
        domain: { data: "table", field: "y1" },
        nice: true,
        zero: true,
      },
    ],
    axes: [
      {
        orient: "bottom",
        scale: "x",
        domain: false,
        ticks: false,
        labelPadding: 5,
        labelColor: theme.label,
        labelOverlap: true,
      },
      {
        scale: "y",
        orient: "left",
        labelColor: theme.label,
        labelLimit: 120,
        labelPadding: 5,
        labelOverlap: true,
      },
    ],
    marks: [
      {
        type: "rect",
        name: "brush",
        encode: {
          enter: {
            y: { signal: "range('y')[0]" },
            y2: { signal: "range('y')[1]" },
            fill: { value: theme.brush },
            fillOpacity: { value: 0.3 },
          },
          update: {
            x: { signal: "brush[0]" },
            x2: { signal: "brush[1]" },
          },
        },
      },
      {
        type: "rect",
        from: { data: "table" },
        encode: {
          update: {
            fill: { signal: "datum.color" },
            opacity: { signal: `${brushCollideExpr} ? 1 : 0.8` },
            x: { signal: 'scale("x", datum.time) + 1' },
            y: { scale: "y", field: "y0" },
            y2: { scale: "y", field: "y1" },
            x2: { signal: `scale("x", utcOffset('${timeUnit.value}', datum.time, 1))` },
            tooltip: { signal: "datum.tooltip" },
          },
        },
      },
    ],
  };
  return spec;
});

function handleSelect(selected: unknown) {
  const alias = generatorAlias(visualization.value.config.time);
  if (alias == null) return;
  const range = selected as [Date, Date];
  const dateTimeRange = [DateTime.fromJSDate(range[0]), DateTime.fromJSDate(range[1])];
  if (dateTimeRange[0].equals(dateTimeRange[1])) return;
  if (dateTimeRange[0].toMillis() > dateTimeRange[1].toMillis()) dateTimeRange.reverse();
  // This doesn't allow selecting anything more granular than a day. At some point we
  // will have to improve on that
  let unit = timeUnit.value;
  if (!["year", "quarter", "month", "week", "day"].includes(unit)) unit = "day";
  const dateOnly = filterValueType(timeProp()!) === GraphValueType.Date;
  const rangeValues = [
    dateTimeRange[0].startOf(unit as DateTimeUnit),
    dateTimeRange[1].endOf(unit as DateTimeUnit),
  ].map((dt) => ({
    _type: dateOnly ? GraphValueType.Date : GraphValueType.Datetime,
    value: dateOnly ? dt.toISODate() : dt.toISO(),
  }));
  emit("select", alias, rangeValues as [GraphValue, GraphValue]);
}

function timeProp() {
  const alias = generatorAlias(visualization.value.config.time);
  if (alias == null) return null;
  const query = combinedQuerySignature(visualization.value.query);
  return findDeepColumnByAlias(query, alias)!.property_type;
}

function formatTime(time: DateTime<boolean>): string {
  if (timeUnit.value === "month") {
    return stringifyValue(TRANSFORMERS["dateYM"](toValue(time)));
  } else if (timeUnit.value === "year") {
    return stringifyValue(TRANSFORMERS["dateY"](toValue(time)));
  }
  return time.toLocaleString(DateTime.DATE_SHORT);
}
</script>
