import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["colspan", "onClick"]
const _hoisted_2 = { class: "flex flex-row items-center font-bold" }
const _hoisted_3 = { class: "px-5 py-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Subtable = _resolveComponent("Subtable", true)!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.subtable.groups ?? [], (group) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: group.id
      }, [
        _createElementVNode("tr", null, [
          _createElementVNode("td", {
            class: "cursor-pointer hover:bg-gray-100",
            colspan: $props.columns.length,
            onClick: ($event: any) => ($setup.toggle($setup.collapsed, group.id)),
            style: _normalizeStyle($setup.indentStyle)
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode($setup["Icon"], {
                name: $setup.collapsed.has(group.id) ? 'md:keyboard_arrow_up' : 'md:keyboard_arrow_down',
                color: "medium-gray",
                size: "xl"
              }, null, 8 /* PROPS */, ["name"]),
              _createElementVNode("div", _hoisted_3, [
                _createVNode($setup["GraphValueComponent"], {
                  value: group.name
                }, null, 8 /* PROPS */, ["value"])
              ])
            ])
          ], 12 /* STYLE, PROPS */, _hoisted_1)
        ]),
        (!$setup.collapsed.has(group.id))
          ? (_openBlock(), _createBlock(_component_Subtable, {
              key: 0,
              subtable: group.contents,
              columns: $props.columns,
              depth: $setup.depth + 1
            }, null, 8 /* PROPS */, ["subtable", "columns", "depth"]))
          : _createCommentVNode("v-if", true)
      ], 64 /* STABLE_FRAGMENT */))
    }), 128 /* KEYED_FRAGMENT */)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.subtable.rows ?? [], (row, rowIndex) => {
      return (_openBlock(), _createElementBlock("tr", {
        key: rowIndex,
        class: "group light:hover:bg-gray-100 dark:odd:bg-gray-800 dark:odd:text-gray-100"
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.columns, (colDetails, colIndex) => {
          return (_openBlock(), _createElementBlock("td", {
            class: "p-10 align-baseline",
            key: colIndex
          }, [
            _createElementVNode("div", {
              style: _normalizeStyle(colIndex == 0 ? $setup.indentStyle : undefined)
            }, [
              (row[colIndex] != null)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass({ 'text-right': colDetails.isQuantitative })
                  }, [
                    _createVNode($setup["GraphValueComponent"], {
                      value: row[colIndex].formattedValue
                    }, null, 8 /* PROPS */, ["value"])
                  ], 2 /* CLASS */))
                : (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: _normalizeClass(["opacity-50", { 'text-right': colDetails.isQuantitative }])
                  }, "-", 2 /* CLASS */))
            ], 4 /* STYLE */)
          ]))
        }), 128 /* KEYED_FRAGMENT */))
      ]))
    }), 128 /* KEYED_FRAGMENT */))
  ], 64 /* STABLE_FRAGMENT */))
}