import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "absolute inset-0 flex min-w-0" }
const _hoisted_2 = { class: "basis-full overflow-hidden" }
const _hoisted_3 = { class: "relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode($setup["Chart"], {
        spec: $setup.spec,
        onSelect: $setup.handleSelect
      }, null, 8 /* PROPS */, ["spec"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode($setup["ResizeObserver"], {
        "emit-on-mount": true,
        onNotify: $setup.handleLegendResize
      }, null, 8 /* PROPS */, ["onNotify"]),
      _createVNode($setup["Legend"], {
        items: $setup.legend,
        total: $setup.total,
        onSelect: $setup.handleSelect
      }, null, 8 /* PROPS */, ["items", "total"])
    ])
  ]))
}