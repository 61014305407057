// Really quick cookie management functions.

import { isNil } from "lodash";

export function getCookie(name: string): string | undefined {
  const value = document.cookie
    .split("; ")
    .find((row) => row.startsWith(`${name}=`))
    ?.split("=")[1];
  return value;
}

export function setCookie(name: string, value?: string, maxAgeSeconds?: number) {
  if (isNil(value)) {
    clearCookie(name);
    return;
  }
  let cookie = `${name}=${value};Path=/;SameSite=Strict`;
  if (maxAgeSeconds !== undefined) {
    cookie = `${cookie};max-age=${maxAgeSeconds}`;
  }
  document.cookie = cookie;
}

export function clearCookie(name: string) {
  setCookie(name, "", 0);
}
