<template>
  <Select
    v-if="workspaces.status === AsyncStatus.Succeeded"
    label="Workspace"
    :options="workspaces.result"
    :option-value="(w: Warehouse) => w.id"
    :model-value="currentWorkspaceId"
    @update:model-value="selectWorkspace"
    option-label="nickname"
    :clearable="false"
    class="min-h-[62px]"
  >
    <template v-slot:option-data="workspace: Workspace">
      <span class="flex flex-row items-center gap-5">
        <Icon v-if="providerIcon(workspace.provider)" :name="providerIcon(workspace.provider)!" />
        <span>{{ workspace.nickname }}</span>
        <span v-if="!workspace.connected">(disconnected)</span>
      </span>
    </template>
  </Select>
  <div v-if="workspaces.status === AsyncStatus.InProgress" class="flex flex-col items-center">
    <Spinner />
  </div>
</template>

<script lang="ts" setup>
import Icon from "@/common/components/Icon.vue";
import { ProviderType, useWorkspaceStore, Warehouse, Workspace } from "../stores/workspaceStore";
import { storeToRefs } from "pinia";
import { environment } from "@/common/environments/environmentLoader";
import { AsyncStatus } from "../lib/async";
import Select from "@/common/components/Select.vue";
import Spinner from "./Spinner.vue";

const workspaceStore = useWorkspaceStore();
const { workspaces, currentWorkspaceId } = storeToRefs(workspaceStore);

function providerIcon(provider?: ProviderType): string | undefined {
  const key = "PROVIDER_LOGO_" + provider?.toUpperCase();
  return environment.get(key) ?? environment.require("PROVIDER_LOGO_DEFAULT");
}

async function selectWorkspace(workspaceName: string) {
  await workspaceStore.setCurrentWorkspace(workspaceName);
}
</script>
