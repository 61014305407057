<template>
  <template v-if="currentWorkspace && warehouses.status === AsyncStatus.Succeeded">
    <Select
      label="Warehouse"
      v-model="currentWorkspace.current_warehouse_id"
      v-if="warehouses.result.length > 0"
      :options="warehouses.result"
      :option-label="(w: Warehouse) => w.name"
      :option-value="(w: Warehouse) => w.id"
      @update:model-value="updateWarehouse"
      :clearable="false"
    >
      <template v-slot:option-data="warehouse: Warehouse">
        <span class="flex flex-row items-center justify-between gap-10">
          <span>{{ warehouse.name }}</span>
          <span class="text-sm text-gray-300">{{ warehouse.cluster_size }}</span>
        </span>
      </template>
    </Select>
    <div v-else class="flex w-full flex-col gap-5">
      <Label label="Warehouse" />

      <div class="text-center text-gray-100 opacity-[0.6]">No warehouses</div>
    </div>
  </template>
  <div v-if="warehouses.status === AsyncStatus.InProgress" class="flex w-full flex-col">
    <Label label="Warehouse" />
    <div class="flex flex-col items-center">
      <Spinner />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useWorkspaceStore, Warehouse } from "../stores/workspaceStore";
import { storeToRefs } from "pinia";
import { AsyncStatus } from "../lib/async";
import Select from "./Select.vue";
import Spinner from "./Spinner.vue";
import Label from "./Label.vue";

const workspaceStore = useWorkspaceStore();
const { warehouses, currentWorkspaceSync: currentWorkspace } = storeToRefs(workspaceStore);

async function updateWarehouse() {
  if (!currentWorkspace?.value) {
    return;
  }
  if (!currentWorkspace.value.current_warehouse_id) {
    return;
  }
  await workspaceStore.setCurrentWarehouse(
    currentWorkspace.value.id,
    currentWorkspace.value.current_warehouse_id
  );
}
</script>
