// This composable supports navigation between apps. Navigation within an app
// should use the app's router instead. (Also, this maybe should be a simple
// lib as it doesn't have any arguments or internal state).
export function useNavigation() {
  /**
   * Navigate to the specified project.
   * @param projectId
   */
  function openProject(projectId: string, newProject = false) {
    const query = newProject ? "?newProject=true" : "";
    window.location.href = `/editor/${projectId}${query}`;
  }

  /**
   * Go to the home page.
   */
  function goHome() {
    window.location.href = "/home";
  }

  function openUrl(url: string) {
    window.open(url);
  }

  function goToUrl(url: string) {
    window.location.href = url;
  }

  return { goHome, openProject, openUrl, goToUrl };
}
