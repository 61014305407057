import { toDisplayString as _toDisplayString, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.isEditing)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createTextVNode(_toDisplayString($setup.opDescription) + " ", 1 /* TEXT */),
        _createVNode($setup["InPlaceEdit"], {
          title: "Exact matches (one value per line)",
          onCancel: $setup.stopEditing,
          onCommit: $setup.commit,
          valid: $setup.isValid
        }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("textarea", {
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.valueUnderEdit) = $event)),
              ref: "valueInputEl",
              class: "w-full border-transparent bg-transparent"
            }, null, 512 /* NEED_PATCH */), [
              [_vModelText, $setup.valueUnderEdit]
            ])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["valid"])
      ]))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: "cursor-pointer truncate border-b border-dotted border-b-transparent font-bold hover:border-b-orange",
        onClick: $setup.startEditing
      }, _toDisplayString($setup.opDescription) + " " + _toDisplayString($setup.valueList), 1 /* TEXT */))
}