import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex" }
const _hoisted_2 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["Tooltip"], {
      class: "flex",
      disabled: $props.tooltip == null
    }, {
      popper: _withCtx(() => [
        _createTextVNode(_toDisplayString($props.tooltip), 1 /* TEXT */)
      ]),
      default: _withCtx(() => [
        _createElementVNode("button", {
          class: _normalizeClass(["cursor-pointer border-0 p-0", {
          'opacity-20': $props.disabled,
          'opacty-100 hover:opacity-70': $props.highlighted,
          'opacity-50 hover:opacity-100': !$props.highlighted && !$props.disabled,
        }]),
          disabled: $props.disabled
        }, [
          _createVNode($setup["Icon"], {
            name: $props.name,
            size: $props.size,
            color: $props.color
          }, null, 8 /* PROPS */, ["name", "size", "color"])
        ], 10 /* CLASS, PROPS */, _hoisted_2)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["disabled"])
  ]))
}