import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-a560bd4a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "property-group" }
const _hoisted_2 = { class: "type" }
const _hoisted_3 = { class: "values" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString($setup.propTypeLabel), 1 /* TEXT */),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.values, (value, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "value",
          key: index
        }, [
          _createVNode($setup["GraphValueComponent"], {
            value: value.formattedValue
          }, null, 8 /* PROPS */, ["value"])
        ]))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ]))
}