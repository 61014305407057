<template>
  <div class="absolute left-0 right-0 top-0 z-0 h-[32px] bg-gray-200 dark:bg-gray-800"></div>
  <div class="absolute inset-0 z-0 overflow-auto" data-test="table">
    <table class="z-10 border-collapse">
      <tr>
        <th>
          <div class="absolute inset-0 border-b border-r border-white dark:border-gray-900"></div>
        </th>
        <th
          v-for="colDetails in columns"
          :key="colDetails.column.alias"
          class="align-baseline"
          @click="toggleDropdownShown(colDetails.column.alias, true)"
        >
          <div
            class="flex items-center justify-between whitespace-nowrap border-b border-r border-white pl-10 dark:border-gray-900"
            :class="{ 'justify-end text-right': colDetails.isQuantitative }"
          >
            <div class="mx-auto">
              <span class="flex content-center items-center">
                <div
                  v-if="colDetails.column.alias !== renamingColumnAlias"
                  class="max-w-[400px] overflow-hidden overflow-ellipsis"
                >
                  {{ colDetails.label }}
                </div>
                <Dropdown
                  placement="bottom"
                  class="cursor-pointer flex-row"
                  :shown="isDropdownShown(colDetails.column.alias)"
                  @update:shown="toggleDropdownShown(colDetails.column.alias, $event)"
                  :triggers="[]"
                  v-if="colDetails.column.alias !== renamingColumnAlias"
                >
                  <template #popper>
                    <Menu :items="colDetails.menuItems" v-close-popper />
                  </template>

                  <IconButton name="chevron-down" size="xs" />
                </Dropdown>
                <Textbox
                  v-else
                  :autoselect="true"
                  v-model="renamingColumn"
                  @keyup.enter="deselectAndRename($event)"
                  @blur="rename(colDetails.column)"
                />
              </span>
            </div>
            <IconButton
              v-if="colDetails.sort !== undefined"
              :name="colDetails.sort.asc ? 'md:arrow_upward' : 'md:arrow_downward'"
              :label="colDetails.sort.asc ? 'Switch to descending' : 'Switch to ascending'"
              :preText="sortOrderLabel(colDetails.sort!.index)"
              size="s"
              @click.stop="toggleAscendingAt(colDetails.sort!.index)"
            />
          </div>
        </th>
      </tr>
      <tr
        v-for="(row, index) in table"
        :key="index"
        class="group light:hover:bg-gray-100 dark:odd:bg-gray-800 dark:odd:text-gray-100"
      >
        <td
          class="justify-end p-10 text-right align-baseline text-gray-200 group-hover:text-gray-500 dark:text-gray-500"
        >
          {{ formatWithLeadingZeros(index + 1, 2) }}
        </td>
        <td
          class="p-10 align-baseline"
          v-for="colDetails in columns"
          :key="colDetails.column.alias"
        >
          <template v-if="row[colDetails.column.alias].values.length > 0">
            <div v-for="(value, index) in row[colDetails.column.alias].values" :key="index">
              <PropertyValue
                :value="value"
                :column="colDetails.column"
                :quantitative="colDetails.isQuantitative"
              />
            </div>
            <div v-if="row[colDetails.column.alias].isTruncated">...</div>
          </template>
          <div class="opacity-50" :class="{ 'text-right': colDetails.isQuantitative }" v-else>
            -
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<style lang="css" scoped>
th {
  @apply sticky top-0 z-10 bg-gray-200 p-0 text-center light:text-gray-700 dark:bg-gray-800 dark:hover:bg-gray-900;
}
</style>

<script setup lang="ts">
import IconButton from "@/common/components/IconButtonV2.vue";
import Menu from "@/common/components/Menu.vue";
import { asyncNotStarted, asyncResultOr } from "@/common/lib/async";
import { GraphValueType, QUANTITATIVE_VALUE_TYPES } from "@/common/lib/value";
import { exploreMenu, ExploreMenuSubject } from "@/reader/lib/exploreMenus";
import { useExploreStore } from "@/reader/stores/explore";
import { Dropdown } from "floating-vue";
import { computed, Ref, ref } from "vue";
import PropertyValue from "./PropertyValue.vue";
import Textbox from "@/common/components/Textbox.vue";
import { propertyValueType } from "@/common/lib/derived";
import { columnName, QueryColumn } from "@/common/lib/query";
import { isFunction } from "lodash";

const dropdownShown = ref<string | null>(null);
const exploreStore = useExploreStore();
const renamingColumnAlias: Ref<string | undefined> = ref(undefined);
const renamingColumn: Ref<string | undefined> = ref(undefined);

const columns = computed(() =>
  (exploreStore.query?.columns ?? []).map((columnDef) => ({
    column: columnDef,
    label: columnName(columnDef),
    menuItems: columnMenuItems(columnDef.alias),
    sort: exploreStore.columnSortState(columnDef.alias),
    isQuantitative: QUANTITATIVE_VALUE_TYPES.includes(
      propertyValueType(columnDef.property_type) as GraphValueType
    ),
  }))
);

const columnMenuItems = (columnAlias: string) => {
  const items = exploreMenu(ExploreMenuSubject.Column, { columnAlias });
  items.push({
    key: "rename",
    label: "Rename",
    action: () => {
      renamingColumnAlias.value = columnAlias;
    },
  });
  return items;
};

const table = computed(() => asyncResultOr(exploreStore.table, []));

const isDropdownShown = (alias: string) => {
  return alias === dropdownShown.value;
};

const toggleDropdownShown = (alias: string, shown: boolean) => {
  dropdownShown.value = shown ? alias : null;
};

const sortOrderLabel = (order: number | undefined) => {
  if (order !== undefined && exploreStore.query!.order_by.length > 1) {
    return String(order + 1);
  }
};

function deselectAndRename(event: Event) {
  if (event.target && "blur" in event.target && isFunction(event.target.blur)) {
    event.target.blur();
  }
}

function rename(column: QueryColumn) {
  if (renamingColumn.value) {
    exploreStore.renameColumn(column.alias, renamingColumn.value);
    renamingColumn.value = undefined;
    exploreStore.sqlData = asyncNotStarted();
  }
  renamingColumnAlias.value = undefined;
}

function toggleAscendingAt(index: number) {
  const newOrderBy = [...exploreStore.query!.order_by];
  const target = newOrderBy[index];
  newOrderBy.splice(index, 1, { ...target, asc: !target.asc });
  exploreStore.setOrderBy(newOrderBy);
}

function formatWithLeadingZeros(number: number, length: number) {
  return String(number).padStart(length, "0");
}
</script>
