import { App } from "vue";
import GeopointValue from "./GeopointValue.vue";
import ImageValue from "./ImageValue.vue";
import NumberValue from "./NumberValue.vue";
import StringValue from "./StringValue.vue";

export default function (app: App): void {
  app.component("graph-value-string", StringValue);
  app.component("graph-value-number", NumberValue);
  app.component("graph-value-geopoint", GeopointValue);
  app.component("graph-value-image", ImageValue);
}
