import { createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["IconButtonV2"], {
      name: $setup.orderBy.asc ? 'sort-asc' : 'sort-desc',
      label: $setup.orderBy.asc ? 'Switch to Descending' : 'Switch to Ascending',
      onClick: $setup.toggleAsc,
      size: "m"
    }, null, 8 /* PROPS */, ["name", "label"]),
    _createVNode($setup["vSelect"], {
      class: _normalizeClass(["select basis-full", { 'light-select': !$setup.darkMode }]),
      "model-value": $setup.selectedColumn,
      options: $setup.exploreStore.query!.columns,
      getOptionLabel: $setup.columnName,
      "onOption:selected": $setup.updateColumn,
      clearable: false,
      placeholder: "Choose a Property"
    }, null, 8 /* PROPS */, ["class", "model-value", "options", "getOptionLabel"])
  ]))
}