import { httpClient as axios } from "@/common/http/http";
import {
  Async,
  asyncFailed,
  asyncInProgress,
  asyncNotStarted,
  asyncSucceeded,
} from "@/common/lib/async";
import { isEqual, remove, without } from "lodash";
import { defineStore } from "pinia";
import { SourceType } from "../lib/map";
import { Catalog, Table } from "./catalog";

// This should perhaps move once it is better understood
export interface SystemTable {
  catalog: string;
  schema: string;
  // group is catalog:schema
  group: string;
  name: string;
  table: Table;
  provider: SourceType;
  loaded: boolean;
}

export interface SourceSelection {
  table: SystemTable;
}

interface State {
  provider: SourceType;
  catalogs: Async<Catalog[]>;
  collapsedGroups: string[];
  search: string;
  selection: SourceSelection[];
  workspaceId?: string;
}

export const useSourceBrowserStore = defineStore("editor-sourceBrowser", {
  state: (): State => ({
    catalogs: asyncNotStarted(),
    collapsedGroups: [],
    search: "",
    selection: [],
    provider: SourceType.Unknown,
    workspaceId: undefined,
  }),
  getters: {
    isSelected(state) {
      return (table: SystemTable) => state.selection.find((sel) => isEqual(sel, { table }));
    },
  },
  actions: {
    toggleGroupCollapsed(group: string) {
      if (this.collapsedGroups.includes(group)) {
        this.collapsedGroups = without(this.collapsedGroups, group);
      } else {
        this.collapsedGroups.push(group);
      }
    },
    toggleSelected(table: SystemTable) {
      if (this.isSelected(table)) {
        remove(this.selection, (sel) => isEqual(sel, { table }));
      } else {
        this.selection.push({ table });
      }
    },
    reset() {
      this.$reset();
      this.catalogs = asyncInProgress();
    },
    clearSelected() {
      this.selection = [];
    },
    async loadSystemTables(workspaceId: string, forceRefresh = false) {
      if (workspaceId === this.workspaceId && !forceRefresh) {
        return;
      }
      this.reset();
      this.workspaceId = workspaceId;
      let response;
      try {
        const url = `/api/workspaces/${workspaceId}/catalogs`;
        const params = forceRefresh ? { refresh: true } : {};
        response = await axios.get(url, { params });
      } catch (error) {
        this.catalogs = asyncFailed("Can't load system contents");
        return;
      }
      this.provider = response.data.provider;
      this.catalogs = asyncSucceeded(response.data.tables);
    },
    async clearTableCaches(
      table: { catalog: string; schema: string; table: string },
      workspaceId: string
    ) {
      await axios.post(
        `/api/workspaces/${workspaceId}/catalogs/${table.catalog}/schemas/${table.schema}/tables/${table.table}/clear-caches`
      );
    },
    async calculateStatistics(
      table: { catalog: string; schema: string; table: string },
      workspaceId: string
    ) {
      const queryOptions = `?force_refresh=true&fetch_if_needed=true`;
      await axios.get(
        `/api/workspaces/${workspaceId}/catalogs/${table.catalog}/schemas/${table.schema}/tables/${table.table}/statistics${queryOptions}`
      );
    },
  },
});
