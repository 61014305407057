<template>
  <div class="m-10">
    <label class="mb-5 block">Type:</label>
    <div class="input">
      <v-select
        class="claritype-dropdown"
        v-model="valueType"
        :options="valueTypeOptions"
        :clearable="false"
        :appendToBody="true"
      />
    </div>
    <label class="mb-5 block">Value:</label>
    <Textbox
      v-model="inputValue"
      v-bind="inputType"
      :errorText="isInvalid ? 'Invalid value for selected type' : undefined"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watchEffect } from "vue";
import { GraphValue, GraphValueType } from "@/common/lib/value";
import { ConstantValuePropertyType, PropertyOpType } from "@/common/lib/derived";
import vSelect from "vue-select";
import Textbox from "@/common/components/Textbox.vue";

const model = defineModel<ConstantValuePropertyType | null>({ required: true });

const valueType = ref<GraphValueType>(GraphValueType.String);
const inputValue = ref<string | number>("");

const valueTypeOptions = computed(() =>
  Object.values(GraphValueType).filter(
    (type) => type !== GraphValueType.Geopoint && type !== GraphValueType.Bytes
  )
);

const isInvalid = computed(() => {
  if (inputValue.value === "") return true;

  switch (valueType.value) {
    case GraphValueType.Integer:
      return !Number.isInteger(Number(inputValue.value));
    case GraphValueType.Float:
      return isNaN(Number(inputValue.value));
    default:
      return false;
  }
});

const inputType = computed(() => {
  switch (valueType.value) {
    case GraphValueType.Integer:
      return { type: "number", step: "1" };
    case GraphValueType.Float:
      return { type: "number", step: "any" };
    case GraphValueType.Date:
      return { type: "date" };
    case GraphValueType.Time:
      return { type: "time" };
    case GraphValueType.Datetime:
      return { type: "datetime-local" };
    case GraphValueType.Bool:
      return { type: "checkbox" };
    default:
      return { type: "text" };
  }
});

watchEffect(() => {
  if (isInvalid.value) {
    model.value = null;
    return;
  }

  const inputStr = String(inputValue.value);
  let graphValue: GraphValue;

  switch (valueType.value) {
    case GraphValueType.Integer:
    case GraphValueType.Float:
      graphValue = {
        _type: valueType.value,
        value: inputStr,
      };
      break;
    case GraphValueType.Bool:
      graphValue = {
        _type: GraphValueType.Bool,
        value: inputStr === "true",
      };
      break;
    case GraphValueType.Date:
    case GraphValueType.Time:
    case GraphValueType.Datetime:
    case GraphValueType.Duration:
      graphValue = {
        _type: valueType.value,
        value: inputStr,
      };
      break;
    default:
      graphValue = {
        _type: GraphValueType.String,
        value: inputStr,
      };
  }

  model.value = {
    op: PropertyOpType.ConstantValue,
    value: graphValue,
  };
});

watchEffect(() => {
  const newValue = model.value?.value;
  if (newValue) {
    valueType.value = newValue._type;
    inputValue.value = String(newValue.value);
  }
});
</script>

<style lang="scss" scoped>
.input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;

  &:focus-within label {
    color: var(--CT-Orange);
  }
}
</style>
