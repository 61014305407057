<template>
  <span class="w-fit-content flex justify-center">
    <img
      :src="src"
      :style="{ width: `${sizePx}px`, height: `${sizePx}px` }"
      class="pointer-events-none block max-w-none"
      :class="colorClass"
    />
  </span>
</template>
<script lang="ts" setup>
import { computed, inject, Ref, toRefs } from "vue";
import { getIconSizePx, IconColor, IconColorSpec, IconSize, parseIconName } from "../lib/icons";
import { DarkMode } from "../lib/keys";
import { isString } from "lodash";

const props = withDefaults(
  defineProps<{
    name: string;
    size?: IconSize;
    color?: IconColorSpec;
  }>(),
  { size: "l", color: () => ({ dark: "white", light: "black" }) }
);

const { name, size, color } = toRefs(props);
const darkMode = inject(DarkMode) as Ref<boolean>;

const src = computed(() => parseIconName(name.value));
const sizePx = computed(() => getIconSizePx(size.value));
const colorClass = computed(function () {
  let effectiveColor: IconColor;
  if (isString(color.value)) {
    effectiveColor = color.value;
  } else {
    effectiveColor = darkMode.value ? color.value.dark : color.value.light;
  }
  return `svg-to-${effectiveColor}`;
});
</script>
