// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../node_modules/vue-resize/dist/vue-resize.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "[data-v-8d1c22e4]:root {\n  --Action-Bar-Dark: #2c2c2c;\n  --Black: #191919;\n  --Canvas: #222222;\n  --CT-Orange: #f75e0e;\n  --Dark-Gray: #474747;\n  --Light-Gray: #e8e8e8;\n  --Medium-Gray: #8a8a8a;\n  --White: #ffffff;\n}\n.control[data-v-8d1c22e4] {\n  --vs-border-style: none;\n}\n.control[data-v-8d1c22e4] .v-select .vs__dropdown-toggle {\n  padding: 1px 1px 3px 1px;\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
