import { defineComponent as _defineComponent } from 'vue'
import { Mode, useExploreStore } from "../stores/explore";
import { computed } from "vue";
import { environment } from "@/common/environments/environmentLoader";
import { isNil } from "lodash";
import { MenuItem } from "@/common/lib/menus";
import { Dropdown } from "floating-vue";
import Menu from "@/common/components/Menu.vue";
import { asyncHasValue, asyncResultOr } from "@/common/lib/async";


export default /*@__PURE__*/_defineComponent({
  __name: 'ExploreFooter',
  setup(__props, { expose: __expose }) {
  __expose();

const exploreStore = useExploreStore();

const showFooter = computed(() => {
  return exploreStore.mode === Mode.Table && limitMenuItems.value.length > 0;
});

const rowsReturned = computed(() => {
  return asyncResultOr(exploreStore.table, []).length.toLocaleString();
});

const queryLimit = computed(() => {
  return exploreStore.query?.size ?? environment.requireJson("EXPLORER_DEFAULT_QUERY_LIMIT");
});

const limitMenuItems = computed(() => {
  const query = exploreStore.query;
  if (isNil(query)) {
    return [];
  }
  if (!asyncHasValue(exploreStore.table)) {
    return [];
  }
  function makeMenuItem(limit: number): MenuItem {
    return {
      key: limit.toString(),
      label: limit.toLocaleString(),
      icon: queryLimit.value === limit ? "md:check" : null,
      action: async () => {
        query!.size = limit;
        await exploreStore.loadTable();
      },
      align: "end",
    };
  }
  return environment.requireJson("EXPLORER_QUERY_LIMIT_OPTIONS").map(makeMenuItem);
});

const __returned__ = { exploreStore, showFooter, rowsReturned, queryLimit, limitMenuItems, get Dropdown() { return Dropdown }, Menu }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})