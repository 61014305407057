import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import {
  DerivedPropertyType,
  PropertyOpType,
  propertyValueType,
  validDerivedPropertyTermType,
} from "@/common/lib/derived";
import useGraph from "@/common/composables/useGraph";
import useKnowledge from "@/common/composables/useKnowledge";
import { useExploreStore } from "@/reader/stores/explore";
import { last, pick } from "lodash";
import { computed, Ref, ref, toRefs, watchEffect } from "vue";
import vSelect from "vue-select";
import DividePropertyDef from "./DividePropertyDef.vue";
import DateDiffPropertyDef from "./DateDiffPropertyDef.vue";
import MultiplyPropertyDef from "./MultiplyPropertyDef.vue";
import SumPropertyDef from "./SumPropertyDef.vue";
import MinPropertyDef from "./MinPropertyDef.vue";
import CountPropertyDef from "./CountPropertyDef.vue";
import MaxPropertyDef from "./MaxPropertyDef.vue";
import AvgPropertyDef from "./AvgPropertyDef.vue";
import MedianPropertyDef from "./MedianPropertyDef.vue";
import AddPropertyDef from "./AddPropertyDef.vue";
import SubtractPropertyDef from "./SubtractPropertyDef.vue";
import ConstantValuePropertyDef from "./ConstantValuePropertyDef.vue";
import CurrentDatePropertyDef from "./CurrentDatePropertyDef.vue";
import { columnName, pathIsWithin, QueryColumn } from "@/common/lib/query";
import { PropertyKnowledgeRef } from "@/common/lib/knowledge";
import { relocatablePropertyTerm } from "@/common/lib/queryProperties";
import { findCurrentColumn } from "@/reader/lib/explore";
import { expandTreePath } from "@/reader/lib/exploreTree";

interface BaseOption {
  kind: "op" | "prop" | "column";
  label: string;
}

interface OpOption extends BaseOption {
  kind: "op";
  op: string;
}

interface PropOption extends BaseOption {
  kind: "prop";
  prop: PropertyKnowledgeRef;
}

interface ColumnOption extends BaseOption {
  kind: "column";
  column: QueryColumn;
}

type Option = OpOption | PropOption | ColumnOption;


export default /*@__PURE__*/_defineComponent({
  __name: 'DerivedPropertyDef',
  props: /*@__PURE__*/_mergeModels({
    parentOp: { type: String, required: false }
  }, {
    "modelValue": { required: true },
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;
const { parentOp } = toRefs(props);

const model = _useModel(__props, "modelValue");

const term: Ref<Option | null> = ref(null);
const componentValue: Ref<DerivedPropertyType | null> = ref(null);

// This is a hack to work around checking that the types of the component and model match.
// For now, we just assert that they do.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const anyComponentValue = componentValue as Ref<any>;

const OPS = [
  { label: "⛭ Add", op: PropertyOpType.Add, component: AddPropertyDef },
  { label: "⛭ Subtract", op: PropertyOpType.Subtract, component: SubtractPropertyDef },
  { label: "⛭ Multiply", op: PropertyOpType.Multiply, component: MultiplyPropertyDef },
  { label: "⛭ Divide", op: PropertyOpType.Divide, component: DividePropertyDef },
  { label: "⛭ Date difference", op: PropertyOpType.DateDiff, component: DateDiffPropertyDef },
  { label: "⛭ Total", op: PropertyOpType.Sum, component: SumPropertyDef },
  { label: "⛭ Minimum", op: PropertyOpType.Min, component: MinPropertyDef },
  { label: "⛭ Maximum", op: PropertyOpType.Max, component: MaxPropertyDef },
  { label: "⛭ Average", op: PropertyOpType.Avg, component: AvgPropertyDef },
  { label: "⛭ Median", op: PropertyOpType.Median, component: MedianPropertyDef },
  { label: "⛭ Count", op: PropertyOpType.Count, component: CountPropertyDef },
  {
    label: "⛭ Constant Value",
    op: PropertyOpType.ConstantValue,
    component: ConstantValuePropertyDef,
  },
  { label: "⛭ Current Date", op: PropertyOpType.CurrentDate, component: CurrentDatePropertyDef },
];

const exploreStore = useExploreStore();
const { typeLabel } = useKnowledge();
const { getConceptsOfType } = useGraph(() => exploreStore.metagraph);

const termOptions = computed(function (): Option[] {
  const conceptType = last(exploreStore.creatingCalculation)!.concept_type;
  const properties = getConceptsOfType(conceptType)[0].properties ?? [];
  const queryPath = expandTreePath(exploreStore.creatingCalculation!);
  const propOptions: Option[] = properties
    .filter(function (prop) {
      const valueType = propertyValueType(prop.type);
      return (
        (parentOp?.value == null || validDerivedPropertyTermType(parentOp.value, valueType)) &&
        !findCurrentColumn({ property_type: prop.type, path: queryPath })
      );
    })
    .map((p) => ({
      kind: "prop",
      label: `${typeLabel(conceptType)} ${typeLabel(p.type)}`,
      prop: p.type,
    }));
  const colOptions: Option[] = exploreStore
    .query!.columns.filter(function (col) {
      const valueType = propertyValueType(col.property_type);
      return (
        pathIsWithin(queryPath ?? [], col.path ?? []) &&
        (parentOp?.value == null || validDerivedPropertyTermType(parentOp.value, valueType))
      );
    })
    .map(function (col) {
      return {
        kind: "column",
        label: `Existing Column: ${columnName(col)}`,
        column: col,
      };
    });
  const opOptions: Option[] = OPS.map((op) => ({ kind: "op", ...pick(op, "label", "op") }));
  return [...colOptions, ...propOptions, ...opOptions];
});

const propertyDefComponent = computed(function () {
  if (term.value?.kind !== "op") return null;
  return OPS.find((o) => o.op === (term.value as OpOption).op)!.component;
});

watchEffect(function () {
  if (term.value == null) {
    model.value = null;
    return;
  }
  switch (term.value.kind) {
    case "prop":
      model.value = term.value.prop;
      break;
    case "op":
      model.value = componentValue.value;
      break;
    case "column":
      model.value = relocatablePropertyTerm(term.value.column);
      break;
  }
});

const __returned__ = { props, parentOp, model, term, componentValue, anyComponentValue, OPS, exploreStore, typeLabel, getConceptsOfType, termOptions, propertyDefComponent, get vSelect() { return vSelect } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})