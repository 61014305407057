<template>
  <div
    class="resize-wrapper"
    :style="{
      width: width > 0 ? undefined : initialWidth + 'px',
      height: height > 0 ? undefined : initialHeight + 'px',
    }"
  >
    <div
      v-if="horizontal"
      class="bar-horiz"
      @mousedown.prevent="startResizeEW"
      :style="{ right: horizontal == 'right' ? 0 : undefined }"
    >
      <span></span><span></span><span></span>
    </div>

    <div
      v-if="vertical"
      class="bar-vert"
      @mousedown.prevent="startResizeNS"
      :style="{ bottom: vertical == 'bottom' ? 0 : undefined }"
    >
      <span></span><span></span><span></span>
    </div>

    <div
      class="resize-content"
      :style="{
        width: width > 0 ? width + 'px' : '100%',
        height: height > 0 ? height + 'px' : '100%',
      }"
    >
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.resize-wrapper {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
}

.bar-horiz,
.bar-vert {
  position: absolute;
  background: $gray3;
  z-index: 2;

  display: flex;
  align-items: center;
  justify-content: center;

  > span {
    pointer-events: none;
    background-color: $gray7;
    border-radius: 100%;
    width: 4px;
    height: 4px;
  }
}

.bar-horiz {
  width: 5px;
  height: 100%;
  cursor: ew-resize;

  flex-flow: column nowrap;

  > span {
    margin: 2px 0;
  }
}
.bar-vert {
  height: 5px;
  width: 100%;
  cursor: ns-resize;

  flex-flow: row nowrap;

  > span {
    margin: 0 2px;
  }
}

.resize-content {
  min-width: 100%;
  min-height: 100%;

  max-height: 100%;
}
</style>

<script lang="ts" setup>
import { useAppStore } from "@/editor/stores/app";
import { onMounted, ref, toRefs } from "vue";
import { useResizeListener } from "@/common/composables/useResizeListener";

const appStore = useAppStore();

const height = ref(-1);
const resizeStartY = ref(0);
const resizeStartHeight = ref(0);

const width = ref(-1);
const resizeStartX = ref(0);
const resizeStartWidth = ref(0);

const userModified = ref(false);

const props = defineProps<{
  horizontal?: "right" | "left";
  vertical?: "top" | "bottom";
  initialWidth?: string;
  initialHeight?: string;
  minimumLength?: number;
  maximumLength?: number;
  identifier?: string;
}>();

const { horizontal, vertical, initialWidth, initialHeight, minimumLength, maximumLength } =
  toRefs(props);

const emit = defineEmits(["update:height", "update:width"]); // Define emits

defineExpose({ setWidth });

onMounted(() => {
  if (props.identifier && appStore.resizeWrapperStates[props.identifier]) {
    width.value = appStore.resizeWrapperStates[props.identifier].width;
    height.value = appStore.resizeWrapperStates[props.identifier].height;
  }
});

function startResizeNS(event: MouseEvent) {
  // Only start resizing on left click
  if (event.button != 0) {
    return;
  }

  resizeStartY.value = event.pageY;
  resizeStartHeight.value =
    (event.target as HTMLElement).parentElement?.getBoundingClientRect().height ?? 0; // should never need to fall back on 0

  document.addEventListener("mousemove", continueResizeNS);
  document.addEventListener("mouseup", finishResizeNS);
}

function continueResizeNS(event: MouseEvent) {
  userModified.value = true;
  if (vertical?.value == "top") {
    height.value = resizeStartHeight.value - (event.pageY - resizeStartY.value);
  } else {
    height.value = resizeStartHeight.value + (event.pageY - resizeStartY.value);
  }
  if (minimumLength.value) {
    height.value = Math.max(height.value, minimumLength.value);
  }
  if (maximumLength.value) {
    height.value = Math.min(height.value, maximumLength.value);
  }

  emit("update:height", height.value); // Emit new height value

  if (props.identifier) {
    appStore.resizeWrapperStates[props.identifier] = { width: width.value, height: height.value };
  }

  event.preventDefault();
}

function finishResizeNS() {
  document.removeEventListener("mouseup", finishResizeNS);
  document.removeEventListener("mousemove", continueResizeNS);
}

function startResizeEW(event: MouseEvent) {
  // Only start resizing on left click
  if (event.button != 0) {
    return;
  }

  resizeStartX.value = event.pageX;
  resizeStartWidth.value =
    (event.target as HTMLElement).parentElement?.getBoundingClientRect().width ?? 0; // should never need to fall back on 0

  document.addEventListener("mousemove", continueResizeEW);
  document.addEventListener("mouseup", finishResizeEW);
}

function continueResizeEW(event: MouseEvent) {
  userModified.value = true;
  if (horizontal?.value == "left") {
    width.value = resizeStartWidth.value - (event.pageX - resizeStartX.value);
  } else {
    width.value = resizeStartWidth.value + (event.pageX - resizeStartX.value);
  }

  if (minimumLength.value) {
    width.value = Math.max(width.value, minimumLength.value);
  }
  if (maximumLength.value) {
    width.value = Math.min(width.value, maximumLength.value);
  }

  emit("update:width", width.value); // Emit new width value

  if (props.identifier) {
    appStore.resizeWrapperStates[props.identifier] = { width: width.value, height: height.value };
  }

  event.preventDefault();
}

function finishResizeEW() {
  document.removeEventListener("mouseup", finishResizeEW);
  document.removeEventListener("mousemove", continueResizeEW);
}

function setWidth(newWidth: number) {
  if (userModified.value) {
    return;
  }
  width.value = newWidth;
}
const emitNewSizes = () => {
  emit("update:width", width.value);
  emit("update:height", height.value); // Emit new height value
};
useResizeListener(emitNewSizes);
</script>
