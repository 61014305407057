<template>
  <div class="mb-5 flex items-start" v-for="(term, index) of terms" :key="term.id">
    <DerivedPropertyDef
      class="basis-full"
      :parent-op="parentOp"
      :model-value="term.term"
      @update:model-value="(value) => setTerm(term.id, value)"
    />
    <IconButton
      class="ml-10 h-[40px]"
      name="delete"
      size="l"
      @click="deleteTerm(term.id)"
      v-if="terms.length > 1"
    />
    <IconButton
      class="ml-10 h-[40px]"
      :class="{ invisible: index != terms.length - 1 }"
      name="add-line"
      size="l"
      @click="addTerm()"
    />
  </div>
</template>

<script lang="ts" setup>
import { DerivedPropertyTerm, PropertyOpType } from "@/common/lib/derived";
import DerivedPropertyDef from "./DerivedPropertyDef.vue";
import IconButton from "@/common/components/IconButton.vue";
import { Ref, ref, watchEffect } from "vue";
import { v4 as uuidv4 } from "uuid";

defineProps<{ parentOp?: PropertyOpType }>();

interface MultiTerm {
  id: string;
  term: DerivedPropertyTerm | null;
}

const model = defineModel({ required: true });
const terms: Ref<MultiTerm[]> = ref([newTerm(), newTerm()]);

function newTerm() {
  return {
    id: uuidv4(),
    term: null,
  };
}

function setTerm(id: string, value: DerivedPropertyTerm | null) {
  terms.value.find((t) => t.id === id)!.term = value;
}

function deleteTerm(id: string) {
  terms.value.splice(
    terms.value.findIndex((t) => t.id === id),
    1
  );
}

function addTerm() {
  terms.value.push(newTerm());
}

watchEffect(() => (model.value = terms.value.map((t) => t.term)));
</script>
