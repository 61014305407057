import { toDisplayString as _toDisplayString, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.isEditing)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createTextVNode(_toDisplayString($setup.editingDescription) + " ", 1 /* TEXT */),
        _createVNode($setup["InPlaceEdit"], {
          title: "Substring matches (one value per line)",
          onCancel: $setup.stopEditing,
          onCommit: $setup.commit,
          valid: $setup.isValid
        }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("textarea", {
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.valueUnderEdit) = $event)),
              ref: "valueInputEl",
              class: "w-full border-transparent bg-transparent"
            }, null, 512 /* NEED_PATCH */), [
              [_vModelText, $setup.valueUnderEdit]
            ]),
            _createVNode($setup["Select"], {
              modelValue: $setup.matchType,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.matchType) = $event)),
              options: $setup.textFilterMatchOptions,
              "option-label": $setup.optionLabel,
              clearable: false
            }, null, 8 /* PROPS */, ["modelValue", "options"]),
            _createVNode($setup["Checkbox"], {
              label: "Ignore case?",
              modelValue: $setup.ignoreCase,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.ignoreCase) = $event))
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["valid"])
      ]))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: "cursor-pointer truncate border-b border-dotted border-b-transparent font-bold hover:border-b-orange",
        onClick: $setup.startEditing
      }, _toDisplayString($setup.opDescription) + " " + _toDisplayString($setup.valueList), 1 /* TEXT */))
}