<template>
  <button
    class="flex h-[75px] w-auto cursor-default items-center justify-around rounded border border-gray-700 bg-gray-800"
    :class="{
      'cursor-pointer hover:border-orange hover:bg-gray-900 active:bg-black': !static && !disabled,
      'cursor-not-allowed opacity-50': disabled,
    }"
    :disabled="disabled"
  >
    <img :src="imgSrc" :class="`h-[${height}]`" />
  </button>
</template>

<script setup lang="ts">
defineProps<{ imgSrc: string; height: string; static?: boolean; disabled?: boolean }>();
</script>
