import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-row items-center gap-10"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.currentWarehouse && $setup.currentWarehouse.state !== 'RUNNING')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        ($props.spinner)
          ? (_openBlock(), _createBlock($setup["Spinner"], { key: 0 }))
          : _createCommentVNode("v-if", true),
        _createElementVNode("span", null, "Warehouse \"" + _toDisplayString($setup.currentWarehouse.name) + "\" is " + _toDisplayString($setup.currentWarehouse.state), 1 /* TEXT */)
      ]))
    : _createCommentVNode("v-if", true)
}