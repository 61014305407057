<template>
  <div class="metagraph-pane">
    <div class="absolute inset-0 overflow-hidden" ref="canvas">
      <ResizeObserver :emit-on-mount="true" @notify="handleResize" />
      <Metalink
        v-for="link in metalinks"
        :key="link.id"
        :link-type="link.type"
        :from-x="link.fromX"
        :from-y="link.fromY"
        :to-x="link.toX"
        :to-y="link.toY"
      />
      <div class="absolute inset-0 cursor-grab" @mousedown.prevent="startPan"></div>
      <Metaconcept
        v-for="metaconcept in metaconcepts"
        ref="metaconceptComponents"
        :concept-type="metaconcept.type"
        :position-x="metaconceptXPosition(metaconcept.type)"
        :position-y="metaconceptYPosition(metaconcept.type)"
        :key="metaconcept.id"
        @move="(x: number, y: number) => moveConcept(metaconcept.type, x, y)"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import useGraph from "@/common/composables/useGraph";
import { useExploreStore } from "@/reader/stores/explore";
import { useExploreMetagraphStore } from "@/reader/stores/exploreMetagraph";
import { computed, Ref, ref } from "vue";
import { ResizeObserver } from "vue-resize";
import Metaconcept from "./Metaconcept.vue";
import Metalink from "./Metalink.vue";

const exploreStore = useExploreStore();
const exploreMetagraphStore = useExploreMetagraphStore();

const canvas: Ref<HTMLDivElement | undefined> = ref(undefined);
const canvasWidth: Ref<number> = ref(0);
const canvasHeight: Ref<number> = ref(0);
const panStartX = ref(0);
const panStartY = ref(0);
const prePanOrigin = ref([0, 0]);
const metaconceptComponents: Ref<InstanceType<typeof Metaconcept>[]> = ref([]);

const metaconcepts = computed(() => exploreStore.metagraph.concepts);

const metalinks = computed(function () {
  const { getConcept } = useGraph(() => exploreStore.metagraph);

  return exploreStore.metagraph.links.map((link) => {
    const fromConcept = getConcept(link.from);
    const toConcept = getConcept(link.to);
    return {
      id: link.id,
      type: link.type,
      fromX: metaconceptXPosition(fromConcept.type),
      fromY: metaconceptYPosition(fromConcept.type),
      toX: metaconceptXPosition(toConcept.type),
      toY: metaconceptYPosition(toConcept.type),
    };
  });
});

function metaconceptXPosition(conceptType: string) {
  return (
    canvasWidth.value / 2 +
    exploreMetagraphStore.panOrigin[0] +
    (exploreMetagraphStore.layoutByConcept[conceptType]?.x || 0)
  );
}

function metaconceptYPosition(conceptType: string) {
  return (
    canvasHeight.value / 2 +
    exploreMetagraphStore.panOrigin[1] +
    (exploreMetagraphStore.layoutByConcept[conceptType]?.y || 0)
  );
}

function moveConcept(nodeId: string, x: number, y: number) {
  exploreMetagraphStore.layoutByConcept[nodeId].x =
    x - canvasWidth.value / 2 - exploreMetagraphStore.panOrigin[0];
  exploreMetagraphStore.layoutByConcept[nodeId].y =
    y - canvasHeight.value / 2 - exploreMetagraphStore.panOrigin[1];
}

function handleResize({ width, height }: { width: number; height: number }) {
  canvasWidth.value = width;
  canvasHeight.value = height;
}

function continuePan(event: MouseEvent) {
  exploreMetagraphStore.panOrigin = [
    prePanOrigin.value[0] + (event.pageX - panStartX.value),
    prePanOrigin.value[1] + (event.pageY - panStartY.value),
  ];
  event.preventDefault();
}

function finishPan(event: MouseEvent) {
  document.removeEventListener("mouseup", finishPan);
  document.removeEventListener("mousemove", continuePan);
  continuePan(event); // Final position update
}

function startPan(event: MouseEvent) {
  // Only start panning on left click
  if (event.button != 0) {
    return;
  }

  panStartX.value = event.pageX;
  panStartY.value = event.pageY;
  prePanOrigin.value = exploreMetagraphStore.panOrigin;
  document.addEventListener("mousemove", continuePan);
  document.addEventListener("mouseup", finishPan);
}
</script>
