<template>
  <Dropdown class="flex justify-start self-center">
    <template #popper>
      <BookmarksList
        v-close-popper
        @updateBookmark="updateBookmark"
        @displayError="displayError"
        @deleteBookmark="deleteBookmark"
        :projectId="props.projectId"
      />
    </template>
    <Tooltip :delay="{ show: 1000, hide: 100 }">
      <template #popper>Show bookmarks</template>
      <div class="file-menu" data-test="project-menu">
        <IconButton2 name="md:folder_special" size="xl" />
      </div>
    </Tooltip>
  </Dropdown>
</template>

<script lang="ts" setup>
import BookmarksList from "../explore/BookmarksList.vue";
import IconButton2 from "@/common/components/IconButtonV2.vue";
import { Bookmark } from "@/common/stores/userModuleStore";
import { Dropdown, Tooltip } from "floating-vue";
const props = defineProps<{ projectId: string }>();
const emit = defineEmits(["updateBookmark", "displayError", "deleteBookmark"]);

function updateBookmark(bookmark: Bookmark) {
  emit("updateBookmark", bookmark);
}

function displayError(bookmark: Bookmark, errors: string[]) {
  emit("displayError", bookmark, errors);
}

function deleteBookmark(bookmark: Bookmark) {
  emit("deleteBookmark", bookmark);
}
</script>
