<template>
  <div class="flex flex-1 flex-col justify-end">
    <ResizeWrapper
      vertical="top"
      @update:height="handleHeightUpdate"
      :minimum-length="HEADER_HEIGHT"
      :maximum-length="maxHeight"
      :initialHeight="String(height)"
      identifier="SourceBrowser.resources"
      v-if="sourceBrowserExpanded"
    >
      <span
        class="mt-[-2px] flex flex-row flex-nowrap items-center justify-between border-t-[2px] border-solid border-gray-900 pl-15 pr-[2px]"
      >
        <div class="text-base font-bold">Source Browser</div>
        <IconButton
          name="md:bottom_panel_close"
          data-test="expand-sources"
          @click="toggleExpanded"
          size="l"
          :targetSize="32"
        />
      </span>
      <div
        class="relative"
        :class="{
          'flex-grow transition-all duration-500 ease-[cubic-bezier(0,1,0.5,1)]':
            sourceBrowserExpanded,
          'flex-grow-0 duration-500': !sourceBrowserExpanded,
        }"
        :style="{
          height: height > 0 ? height + 'px' : '100%',
        }"
      >
        <div
          v-if="workspacesState !== WorkspacesState.Loading"
          class="absolute inset-0 flex flex-col"
        >
          <div class="flex flex-row gap-[8px] py-[5px] pl-[12px] pr-[2px]">
            <IconButton
              :name="showServerDetails ? 'md:collapse_all' : 'md:expand_all'"
              :label="showServerDetails ? 'Collapse' : 'Expand'"
              size="l"
              :targetSize="24"
              @click="toggleServerDetails()"
            />
            <div class="flex w-full flex-col gap-[8px]">
              <div class="flex min-h-[20px] flex-row items-center gap-[8px]">
                <Icon v-if="workspaceIcon" :name="workspaceIcon" />
                <div class="title">
                  <div>{{ workspace?.nickname ?? "No current workspace" }}</div>
                  <span v-if="workspace && !workspace.connected">(disconnected)</span>
                </div>
              </div>
              <div class="min-h-[20px]" v-if="workspace?.connected">
                <WarehouseStatus v-if="currentWarehouse" :warehouse="currentWarehouse" />
                <div
                  v-else-if="
                    warehouses.status === AsyncStatus.Succeeded && warehouses.result.length === 0
                  "
                  class="text-center text-gray-100 opacity-[0.6]"
                >
                  No warehouses
                </div>
                <Spinner v-else />
              </div>
              <div v-if="showServerDetails">
                <div v-if="allowWorkspaceSwitching" class="pb-10 pt-5">
                  <WorkspaceChooser />
                </div>

                <div
                  v-if="workspace"
                  class="min-h-[58px]"
                  :class="{ 'pt-10': !allowWorkspaceSwitching }"
                >
                  <WarehouseChooser />
                </div>
              </div>
            </div>
            <IconButton
              name="md:restart_alt"
              label="Refresh catalog"
              @click.stop="refreshTables()"
              size="l"
              :targetSize="32"
              v-if="workspace?.connected"
            />
          </div>
          <template v-if="workspaces.status === AsyncStatus.Failed">
            <MessageBar mode="error">
              Error loading workspaces: {{ workspaces.message }}
            </MessageBar>
          </template>
          <template v-if="warehouses.status === AsyncStatus.Failed">
            <MessageBar mode="error">
              Error loading warehouses: {{ warehouses.message }}
            </MessageBar>
          </template>
          <template v-if="currentWorkspace.status === AsyncStatus.Failed">
            <MessageBar mode="error"> Error loading current workspace </MessageBar>
          </template>
          <MessageBar mode="warning" v-if="workspace?.connected === false" class="mt-10">
            Workspace is not connected
            <template #actions>
              <TextButton label="Reconnect" @click="reconnect()" mode="warning" />
            </template>
          </MessageBar>

          <SourceCatalog
            v-if="currentWorkspaceId && workspace?.connected"
            :workspace-id="currentWorkspaceId"
            @select-tables="selectTables"
          />
        </div>
        <div v-else-if="workspacesState == WorkspacesState.Loading" class="text-center">
          <Spinner />
        </div>
      </div>
    </ResizeWrapper>
    <div v-else>
      <span
        class="flex flex-row flex-nowrap items-center justify-between border-t-[2px] border-solid border-gray-900 px-15"
      >
        <div class="text-base font-bold">Source Browser</div>
        <IconButton name="md:top_panel_close" @click="toggleExpanded" size="xl" :targetSize="32" />
      </span>
    </div>
  </div>
  <Dialog
    v-if="deletedWarehouseWarning"
    title="DELETED WAREHOUSE"
    success-label="Go to settings"
    @succeeded="useNavigation().goToUrl(CONNECTIONS_URL)"
    @cancelled="deletedWarehouseWarning = false"
  >
    {{ currentWarehouse?.name }} has been deleted. You must select a new warehouse to continue.
  </Dialog>
</template>
<script lang="ts" setup>
import Icon from "@/common/components/Icon.vue";
import SourceCatalog from "@/common/components/SourceCatalog.vue";
import { SourceSelection, useSourceBrowserStore } from "@/common/stores/sourceBrowser";
import { computed, onMounted, ref, watch } from "vue";
import { ProviderType, useWorkspaceStore, WorkspacesState } from "../stores/workspaceStore";
import { storeToRefs } from "pinia";
import IconButton from "./IconButtonV2.vue";
import { environment } from "@/common/environments/environmentLoader";
import ResizeWrapper from "@/editor/components/ResizeWrapper.vue";
import { AsyncStatus } from "../lib/async";
import Dialog from "@/common/components/Dialog.vue";
import { useRoute } from "vue-router";
import { useNavigation } from "@/common/composables/useNavigation";
import MessageBar from "./MessageBar.vue";
import WorkspaceChooser from "./WorkspaceChooser.vue";
import WarehouseChooser from "./WarehouseChooser.vue";
import WarehouseStatus from "./WarehouseStatus.vue";
import Spinner from "./Spinner.vue";
import TextButton from "./TextButton.vue";
import { useDynamicTimer } from "../composables/useDynamicTimer";

const HEADER_HEIGHT = 32;
const CONNECTIONS_URL = "/home/connections";

const props = withDefaults(
  defineProps<{
    startExpanded?: boolean;
    parentHeight?: number;
    allowWorkspaceSwitching?: boolean;
  }>(),
  {
    startExpanded: true,
    allowWorkspaceSwitching: false,
  }
);

const emit = defineEmits<{
  (e: "select-tables", selections: SourceSelection[]): void;
  (e: "expanded", expanded: boolean): void;
}>();

const height = ref(props.parentHeight ?? 0);
const showServerDetails = ref(false);
const deletedWarehouseWarning = ref(false);
const sourceBrowserExpanded = ref(props.startExpanded);
const maxHeight = ref(props.parentHeight);

const route = useRoute();
const sourceBrowserStore = useSourceBrowserStore();
const workspaceStore = useWorkspaceStore();
const workspaceStoreRefs = storeToRefs(workspaceStore);
const {
  currentWarehouse,
  warehouses,
  workspaces,
  currentWorkspaceId,
  currentWorkspace,
  workspacesState,
} = workspaceStoreRefs;

defineExpose({ sourceBrowserExpanded });

function handleHeightUpdate(newHeight: number) {
  height.value = newHeight - HEADER_HEIGHT;
}

function toggleExpanded() {
  sourceBrowserExpanded.value = !sourceBrowserExpanded.value;
  emit("expanded", sourceBrowserExpanded.value);
}

function toggleServerDetails() {
  showServerDetails.value = !showServerDetails.value;
}

const workspace = computed(() => {
  return workspaceStoreRefs.currentWorkspaceSync?.value;
});

const workspaceIcon = computed(() => providerIcon(workspace.value?.provider));

function reconnect() {
  useNavigation().goToUrl(CONNECTIONS_URL);
}

function providerIcon(provider?: ProviderType): string | undefined {
  const key = "PROVIDER_LOGO_" + provider?.toUpperCase();
  return environment.get(key) ?? environment.require("PROVIDER_LOGO_DEFAULT");
}

function refreshTables() {
  const workspaceId = currentWorkspaceId?.value;
  if (!workspaceId) {
    return;
  }
  sourceBrowserStore.loadSystemTables(workspaceId, true);
}

function selectTables(selection: SourceSelection[]) {
  emit("select-tables", selection);
}

const timer = useDynamicTimer(environment.requireNumber("WAREHOUSE_LOADING_CHECK_WAIT_MS"));

watch(
  () => props.parentHeight,
  (newVal) => {
    maxHeight.value = newVal;
    height.value = newVal ?? 0;
  }
);

watch(
  () => workspace,
  () => {
    refreshTables;
  }
);

watch(
  () => currentWarehouse?.value?.state,
  (newWarehouseState, oldWarehouseState) => {
    if (
      newWarehouseState === "DELETED" &&
      newWarehouseState !== oldWarehouseState &&
      route.path !== "/connections"
    ) {
      deletedWarehouseWarning.value = true;
    }
  }
);

onMounted(async () => {
  await workspaceStore.initializeWorkspaceState();
  timer.start(async () => {
    await workspaceStore.refreshCurrentWarehouse();
    if (workspaceStore.currentWarehouse?.state === "RUNNING") {
      return environment.requireNumber("WAREHOUSE_PERIODIC_CHECK_WAIT_MS");
    }
    return environment.requireNumber("WAREHOUSE_LOADING_CHECK_WAIT_MS");
  });

  maxHeight.value = props.parentHeight ?? 0;
  height.value = maxHeight.value * 0.85;
});
</script>
