<template>
  <div class="absolute inset-0 flex flex-col items-center justify-center">
    <div v-if="primaryDatum != null" :style="{ fontSize: `${primaryTextHeight}px` }">
      <GraphValueComponent :value="primaryDatum.formattedValue" />
    </div>
    <div v-else>&mdash;</div>
    <div
      v-if="comparisonDatum != null"
      :style="{ fontSize: `${comparisonTextHeight}px` }"
      class="flex items-center"
    >
      <div
        v-if="diffDatum != null"
        class="mr-10 font-semibold"
        :class="{
          'text-green-500': comparisonIsGood,
          'text-red-500': !comparisonIsGood,
        }"
      >
        <GraphValueComponent :value="diffDatum.formattedValue" />
      </div>
      <div class="flex items-center font-light text-gray-500">
        <Icon name="md:target" color="medium-gray" />
        <GraphValueComponent :value="comparisonDatum.formattedValue" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { IndicatorVisualization } from "@/reader/lib/visualizationTypes";
import { computed, toRefs } from "vue";
import GraphValueComponent from "@/common/components/GraphValue.vue";
import { UseQueryResult } from "@/reader/composables/useQuery";
import { FloatValue, GraphValue, isValue, stringifyValue, toNative } from "@/common/lib/value";
import useVisualization from "@/reader/composables/useVisualization";
import { ValueGenerator } from "@/reader/lib/visualization";
import Icon from "@/common/components/Icon.vue";

const props = defineProps<{
  visualization: IndicatorVisualization;
  results: UseQueryResult[];
  comparisonResults: UseQueryResult[] | null;
  height: number;
  width: number;
}>();
const { visualization, results, comparisonResults, height, width } = toRefs(props);

defineEmits<{ select: [alias: ValueGenerator, value: GraphValue | null] }>();

const { valueSets } = useVisualization(
  visualization,
  results,
  comparisonResults,
  [],
  ["value"],
  ["value"]
);

const primaryDatum = computed(function () {
  if (valueSets.value.length !== 1) return null;
  return valueSets.value[0].primary["value"];
});

const comparisonDatum = computed(function () {
  if (valueSets.value.length !== 1) return null;
  return valueSets.value[0].comparison?.["value"];
});

const diffDatum = computed(function () {
  if (valueSets.value.length !== 1) return null;
  return valueSets.value[0].diff?.["value"];
});

const primaryTextHeight = computed(() => {
  let size = height.value / 5 + 25;
  const stringLength = stringifyValue(primaryDatum.value?.formattedValue).length;

  if (stringLength * (size / 1.5) > width.value) {
    size = (1.5 * width.value) / stringLength;
  }
  return size;
});

const comparisonTextHeight = computed(() =>
  Math.min(Math.max(primaryTextHeight.value / 3, 14), 30)
);

const comparisonIsGood = computed(function () {
  const prim = primaryDatum.value?.originalValue;
  const comp = comparisonDatum.value?.originalValue;
  if (!isValue(comp) || !isValue(prim)) return false;
  const isPositive = toNative(prim as FloatValue) > toNative(comp as FloatValue);
  if (visualization.value.config.lower_is_better) return !isPositive;
  return isPositive;
});
</script>
