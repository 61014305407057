<template>
  <div>
    <div class="mb-2 text-xxs text-orange">{{ title }}</div>
    <div class="flex rounded border border-orange bg-gray-300 dark:bg-black">
      <div class="control flex-initial rounded-l bg-white dark:bg-gray-900"><slot /></div>
      <IconButton
        name="check"
        @click="emit('commit')"
        size="l"
        class="ml-5"
        :disabled="!valid"
        :color="buttonColor"
      />
      <IconButton name="close" @click="emit('cancel')" size="l" class="mr-5" :color="buttonColor" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.control {
  --vs-border-style: none;

  &:deep(.v-select .vs__dropdown-toggle) {
    padding: 1px 1px 3px 1px;
  }
}
</style>

<script lang="ts" setup>
import IconButton from "@/common/components/IconButton.vue";
import { DarkMode } from "../lib/keys";
import { computed, inject, Ref } from "vue";

const darkMode = inject(DarkMode) as Ref<boolean>;

withDefaults(defineProps<{ title: string; valid?: boolean }>(), {
  valid: true,
});

const emit = defineEmits<{
  (e: "cancel"): void;
  (e: "commit"): void;
}>();

const buttonColor = computed(() => (darkMode.value ? "white" : "dark-gray"));
</script>
