// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../node_modules/vue-resize/dist/vue-resize.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root {\n  --Action-Bar-Dark: #2c2c2c;\n  --Black: #191919;\n  --Canvas: #222222;\n  --CT-Orange: #f75e0e;\n  --Dark-Gray: #474747;\n  --Light-Gray: #e8e8e8;\n  --Medium-Gray: #8a8a8a;\n  --White: #ffffff;\n}\n.v-popper--theme-icon-button-tooltip .v-popper__inner{\n  border-radius: 0.25rem;\n  --tw-bg-opacity: 1;\n  background-color: rgb(255 255 255 / var(--tw-bg-opacity));\n  padding-right: 2px;\n  padding-left: 4px;\n  font-size: 10px;\n  line-height: 12px;\n  --tw-text-opacity: 1;\n  color: rgb(25 25 25 / var(--tw-text-opacity));\n}\n.v-popper--theme-icon-button-tooltip .v-popper__inner:where(.dark, .dark *){\n  --tw-bg-opacity: 1;\n  background-color: rgb(25 25 25 / var(--tw-bg-opacity));\n  --tw-text-opacity: 1;\n  color: rgb(255 255 255 / var(--tw-text-opacity));\n}\n.v-popper--theme-icon-button-tooltip .v-popper__arrow-inner{\n  visibility: hidden;\n}\n.v-popper--theme-icon-button-tooltip .v-popper__arrow-outer{\n  visibility: hidden;\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
