<template>
  <div class="absolute inset-0 flex flex-col dark:bg-gray-800">
    <TabSelect
      :tabs="tabs"
      v-model="exploreStore.activeTool"
      class="border-b border-b-gray-200 light:bg-gray-100 dark:border-b-gray-900"
    />
    <div class="relative basis-full overflow-y-auto p-10">
      <div v-if="exploreStore.activeTool === Tool.Concept">
        <template v-if="exploreStore.conceptPage">
          <ConceptPage
            :address="exploreStore.conceptPage"
            @navigate="exploreStore.showConceptPage"
            @pivot="pivot"
          />
        </template>
        <div class="p-20 text-center text-xl text-gray-500" v-else>
          Click on an underlined value and choose the "View" menu item to see Concept details here.
        </div>
      </div>
      <div v-if="exploreStore.activeTool === Tool.Insights">
        <div class="p-20 text-center text-xl text-gray-500">
          No visualizations are available for the current perspective.
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import TabSelect from "@/common/components/TabSelect.vue";
import { computed } from "vue";
import { Tool, useExploreStore } from "@/reader/stores/explore";
import { ConceptKnowledgeRef } from "@/common/lib/knowledge";
import ConceptPage from "../concept/ConceptPage.vue";
import { QueryFilter } from "@/common/lib/query";

const exploreStore = useExploreStore();

const tabs = computed(() => [
  { label: "Insights", key: Tool.Insights },
  { label: "Concept", key: Tool.Concept },
]);

function pivot(ctype: ConceptKnowledgeRef, filters: QueryFilter[]) {
  exploreStore.pivot(ctype, filters[0]); // This will fail on multi-key merges
}
</script>
