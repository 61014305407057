import { Tab } from "@/common/components/tab";
import { environment } from "@/common/environments/environmentLoader";
import { defineStore } from "pinia";
import { RouteRecordNameGeneric } from "vue-router";

export type SelectedTab = "user-settings" | "connection" | "preferences";
export type SubPage = "modules" | "user-settings";

export enum Routes {
  Home = "home",
  Connections = "connections",
  UserSettings = "user-settings",
  Preferences = "preferences",
}

interface State {
  selectedTab: SelectedTab;
  userSelected: boolean;
  availableTabs: Tab[];
}

const canEditDatabricksConnection = environment.requireBoolean("EDIT_DATABRICKS_CONNECTION");
const showPreferences = environment.requireBoolean("SHOW_PREFERENCES");

const availableTabs = [{ label: "User settings", key: "user-settings" }];
if (showPreferences) {
  availableTabs.push({ label: "Preferences", key: "preferences" });
}

if (canEditDatabricksConnection) {
  availableTabs.push({ label: "Connection", key: "connection" });
}

export const useSettingsStore = defineStore("settings", {
  state: (): State => ({
    selectedTab: "user-settings",
    userSelected: false,
    availableTabs: availableTabs,
  }),
  getters: {
    subPage: (state) => subPage(state.userSelected),
  },
  actions: {
    openConnectionPage() {
      if (!canEditDatabricksConnection) {
        return;
      }
      this.userSelected = true;
      this.selectedTab = "connection";
    },
    openUserSettings() {
      this.userSelected = true;
      this.selectedTab = "user-settings";
    },
    openPreferences() {
      this.userSelected = true;
      this.selectedTab = "preferences";
    },
    openHome() {
      this.userSelected = false;
    },
    handleRouteChange(routeName: RouteRecordNameGeneric) {
      switch (routeName) {
        case Routes.Connections:
          this.openConnectionPage();
          break;
        case Routes.UserSettings:
          this.openUserSettings();
          break;
        case Routes.Preferences:
          if (showPreferences) {
            this.openPreferences();
          }
          break;
        default:
          this.openHome();
          break;
      }
    },
  },
});

function subPage(userSelected: boolean): SubPage {
  if (userSelected) {
    return "user-settings";
  }
  return "modules";
}
