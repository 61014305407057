export const CONCEPT_COLORS = [
  "#C2423F",
  "#66C37A",
  "#00B5F7",
  "#F4C84B",
  "#FF9616",
  "#6E899C",
  "#FED4C4",
  "#EEA6FB",
  "#0DF9FF",
  "#6A76FC",
  "#22FFA7",
  "#DC587D",
  "#479B55",
  "#FC6955",
  "#B68E00",
  "#C9FBE5",
  "#D626FF",
  "#E3EE9E",
  "#7E7DCD",
  "#FF0092",
  "#BC7196",
  "#86CE00",
  "#E48F72",
  "#EA1ECD",
];

export const RECORD_CONCEPT_COLOR = "#777777";
export const MISSING_CONCEPT_COLOR = "#DDDDDD";

export function conceptColorAtIndex(index: number): string {
  return CONCEPT_COLORS[index % CONCEPT_COLORS.length];
}
