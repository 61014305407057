export type FailureSource = "frontend" | "backend";

export enum FailureType {
  Runner = "runner",
  Planner = "planner",
  Explorer = "explorer",
  Api = "api",
  FrontendComponent = "frontend_component",
  FrontendSystem = "frontend_system",
}

export interface FailureOptions {
  type: FailureType;
  description: string;
  error?: unknown;
  hideUndo?: boolean;
}

/**
 * An error from the backend with a user-readable error message.
 */
export class BackendUserError extends Error {
  constructor(
    public message: string,
    public cause?: Error
  ) {
    super(message, { cause });
  }
}

/**
 * Helper function to handle errors.
 * @param action
 * @param onError
 */
export async function tryWithError(
  action: () => Promise<void>,
  onError: (error: BackendUserError) => Promise<void> | void,
  failureHandler: (failure: FailureOptions) => void,
  failureMessage: string
) {
  try {
    await action();
  } catch (error: unknown) {
    if (!(error instanceof BackendUserError)) {
      const failure: FailureOptions = {
        type: FailureType.Api,
        description: failureMessage,
        error,
      };
      return failureHandler(failure);
    }
    onError(error);
  }
}
