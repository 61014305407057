// Helper functions for working with asynchronous code.

/**
 * Create a Promise that sleeps for the specified number of milliseconds.
 * @param ms milliseconds to wait before resolving.
 * @returns
 */
export function sleep(ms: number): Promise<undefined> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * Create a promise that waits until a given predicate is true.
 * @param predicate
 * @param resolution optional time between checks in milliseconds.
 * @returns true on completion
 */
export async function waitFor(predicate: () => boolean, resolution = 100): Promise<boolean> {
  while (!predicate()) {
    await sleep(resolution);
  }
  return true;
}
