// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../node_modules/vue-resize/dist/vue-resize.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "[data-v-13265874]:root {\n  --Action-Bar-Dark: #2c2c2c;\n  --Black: #191919;\n  --Canvas: #222222;\n  --CT-Orange: #f75e0e;\n  --Dark-Gray: #474747;\n  --Light-Gray: #e8e8e8;\n  --Medium-Gray: #8a8a8a;\n  --White: #ffffff;\n}\n.modal[data-v-13265874],\n.shade[data-v-13265874],\n.content[data-v-13265874] {\n  position: absolute;\n  left: 0;\n  right: 0;\n  top: 0;\n  bottom: 0;\n}\n.shade[data-v-13265874] {\n  background: #111111;\n  opacity: 0.5;\n}\n.content[data-v-13265874] {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.dialog[data-v-13265874] {\n  background: var(--Action-Bar-Dark);\n  border: 1px solid var(--Dark-Gray);\n  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);\n  padding: 10px;\n  max-width: 440px;\n  overflow-wrap: break-word;\n}\n.failure[data-v-13265874] {\n  display: flex;\n  flex-direction: column;\n}\n.details[data-v-13265874] {\n  flex: 100% 0 1;\n  overflow-y: scroll;\n  font-family: inherit;\n  background: black;\n  padding: 10px;\n  color: inherit;\n  border: none;\n  resize: none;\n  margin-top: 10px;\n  width: 100%;\n  height: 300px;\n}\n.buttons[data-v-13265874] {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  gap: 10px;\n}\n.buttons button[data-v-13265874] {\n  margin-top: 5px;\n}\n.details-toggle[data-v-13265874] {\n  color: var(--Light-Gray);\n  border: 1px solid var(--Medium-Gray);\n  border-radius: 3px;\n  display: inline-flex;\n  padding: 5px;\n  justify-content: center;\n  align-items: center;\n  gap: 5px;\n  font-weight: 400;\n  margin-top: 5px;\n  text-align: center;\n  font-size: 14px;\n}\n.details-toggle-selected[data-v-13265874] {\n  border: none;\n  background-color: var(--Canvas);\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
