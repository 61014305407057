<template>
  <svg
    class="pointer-events-none absolute inset-0 z-30 cursor-pointer fill-none stroke-white"
    width="100%"
    height="100%"
  >
    <path class="z-20 stroke-gray-500 stroke-1" :d="path"></path>
  </svg>
</template>

<script lang="ts" setup>
import { computed, toRefs } from "vue";

const props = defineProps<{
  linkType: string;
  fromX: number;
  fromY: number;
  toX: number;
  toY: number;
}>();
const { fromX, toX, fromY, toY } = toRefs(props);

const path = computed(() => `M ${fromX.value},${fromY.value} L ${toX.value},${toY.value}`);
</script>
