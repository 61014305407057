<template>
  {{ string }}
</template>

<script lang="ts" setup>
import { GraphValue, stringifyValue } from "@/common/lib/value";
import { computed, toRefs } from "vue";

const props = defineProps<{ value: GraphValue }>();
const { value } = toRefs(props);

const string = computed(() => stringifyValue(value.value));
</script>
