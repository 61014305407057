<template>
  <div class="flex">
    <Tooltip class="flex" :disabled="tooltip == null">
      <template #popper>{{ tooltip }}</template>
      <button
        class="cursor-pointer border-0 p-0"
        :class="{
          'opacity-20': disabled,
          'opacty-100 hover:opacity-70': highlighted,
          'opacity-50 hover:opacity-100': !highlighted && !disabled,
        }"
        :disabled="disabled"
      >
        <Icon :name="name" :size="size" :color="color" />
      </button>
    </Tooltip>
  </div>
</template>

<script lang="ts" setup>
import Icon from "@/common/components/Icon.vue";
import { Tooltip } from "floating-vue";
import { IconColor, IconSize } from "../lib/icons";

withDefaults(
  defineProps<{
    name: string;
    size?: IconSize;
    color?: IconColor;
    tooltip?: string;
    highlighted?: boolean;
    disabled?: boolean;
  }>(),
  { size: "m", highlighted: false, disabled: false }
);
</script>
