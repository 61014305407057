<template>
  {{ opDescription }}
</template>

<script setup lang="ts">
import { ExistenceFilter } from "@/common/lib/fetchApi";
import { QueryFilter } from "@/common/lib/query";
import { computed, toRefs } from "vue";

const props = defineProps<{ filter: QueryFilter<ExistenceFilter> }>();
const { filter } = toRefs(props);

const opDescription = computed(function () {
  return filter.value.negated ? "has no value" : "has any value";
});
</script>
