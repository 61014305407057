// Toggles an element in a set (removes it if it exists, otherwise adds it)
// For convenience, if true or false is passed for "force," the element will
// always be added or removed respectively

import { intersection } from "lodash";

export function toggle<T>(set: Set<T>, element: T, force?: boolean) {
  if (force == null) {
    if (set.has(element)) {
      set.delete(element);
    } else {
      set.add(element);
    }
  } else {
    if (force) {
      set.add(element);
    } else {
      set.delete(element);
    }
  }
}

// Because this isn't quite ready for prime time yet:
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Set/intersection
export function intersectSets<T>(set1: Set<T>, set2: Set<T>) {
  return new Set(intersection(Array.from(set1), Array.from(set2)));
}
