import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "z-10 w-full select-none" }
const _hoisted_2 = { class: "scrollbar-none flex overflow-x-auto overflow-y-hidden" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "max-w-[150px] overflow-hidden text-ellipsis whitespace-nowrap leading-[32px]" }
const _hoisted_5 = {
  key: 0,
  class: "h-2 bg-orange"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.tabs, (tab) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["group relative flex cursor-pointer flex-col hover:bg-gray-200 dark:hover:bg-gray-900 dark:active:bg-black", {
          'text-gray-700 dark:text-gray-200': tab.key === $setup.props.modelValue,
          'text-gray-500 hover:text-gray-700 dark:hover:text-gray-200':
            tab.key !== $setup.props.modelValue,
          'pointer-events-none opacity-30': tab.disabled,
        }]),
          key: tab.key,
          onClick: ($event: any) => ($setup.emit('update:modelValue', tab.key))
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["m-0 mx-10 flex items-center gap-5", {
            'color-unset': tab.key === $setup.props.modelValue,
          }])
          }, [
            (tab.icon)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createVNode($setup["Icon"], {
                    class: "flex group-hover:hidden",
                    name: tab.icon,
                    size: "m",
                    color: 
                tab.key === $setup.props.modelValue
                  ? { light: 'dark-gray', dark: 'light-gray' }
                  : 'medium-gray'
              
                  }, null, 8 /* PROPS */, ["name", "color"]),
                  _createVNode($setup["Icon"], {
                    class: "hidden group-hover:flex",
                    name: tab.icon,
                    size: "m",
                    color: { light: 'dark-gray', dark: 'light-gray' }
                  }, null, 8 /* PROPS */, ["name"])
                ], 64 /* STABLE_FRAGMENT */))
              : _createCommentVNode("v-if", true),
            _createElementVNode("div", _hoisted_4, _toDisplayString(tab.label), 1 /* TEXT */)
          ], 2 /* CLASS */),
          (tab.key === $setup.props.modelValue)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5))
            : _createCommentVNode("v-if", true)
        ], 10 /* CLASS, PROPS */, _hoisted_3))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ]))
}