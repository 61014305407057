<template>
  <ul class="mx-2 my-4 bg-white text-gray-800 dark:bg-black dark:text-gray-200">
    <template v-for="item in items" :key="item.key">
      <Dropdown
        :disabled="!item.submenu"
        placement="right-start"
        :style="{ 'min-width': minWidth }"
        :distance="10"
      >
        <template #popper>
          <Menu :items="item.submenu!" @select="emit('select')" v-close-popper.all />
        </template>
        <li
          @click="select(item)"
          class="group flex cursor-pointer items-center gap-10 px-10 leading-[25px]"
          :class="{
            'cursor-default text-gray-500': item.disabled,
            'hover:bg-orange hover:text-white': !item.disabled,
            'border-b border-b-gray-300 dark:border-b-gray-700': item.section,
          }"
        >
          <div class="mb-2 flex min-w-[16px]" v-if="useIcons">
            <Icon v-if="item.icon" :name="item.icon" size="l" />
          </div>
          <div class="basis-full overflow-hidden whitespace-nowrap">{{ item.label }}</div>
          <Icon
            v-if="item.submenu"
            name="submenu"
            size="xxs"
            class="ml-5 flex-shrink-0 flex-grow"
          />
          <div v-if="item.shortcut" class="cursor-default opacity-50">{{ item.shortcut }}</div>
        </li>
      </Dropdown>
    </template>
  </ul>
</template>

<script lang="ts" setup>
import { MenuItem } from "@/common/lib/menus";
import { Dropdown } from "floating-vue";
import Icon from "./Icon.vue";

const props = defineProps<{ items: MenuItem[]; minWidth?: string }>();
const emit = defineEmits<{ (e: "select"): void }>();

const useIcons = props.items.find((item) => !!item.icon) ?? false;

function select(item: MenuItem) {
  if (!item.disabled) {
    item.action?.();
    emit("select");
  }
}
</script>
