import { httpClient } from "@/common/http/http";
import {
  ConceptType,
  EnrichmentType,
  GeneratorType,
  KnowledgeItem,
  KnowledgeMetadata,
  KnowledgeModule,
  LinkType,
  ParserType,
  PropertyType,
  ShapeType,
} from "@/common/lib/knowledge";
import { AxiosResponse } from "axios";
import { isFunction, mapValues, sortBy } from "lodash";
import { DateTime } from "luxon";
import { defineStore } from "pinia";
import { CTMap, extractMapKnowledge } from "../lib/map";

interface State {
  initialized: boolean;

  _currentModuleId?: string;

  /**
   * Non ad hoc knowledge, as a single list.
   */
  knowledgeItems: Record<string, KnowledgeItem>;

  /**
   * Ad hoc knowledge
   */
  localKnowledge: Record<string, KnowledgeItem>;

  /**
   * Current version of ctlib.
   */
  ctlibVersion: string;

  modules: Record<string, KnowledgeModule>;
  availableModulesList: AvailableModule[];

  links: Record<string, LinkType>;
  shapes: Record<string, ShapeType>;
  enrichments: Record<string, EnrichmentType>;
  parsers: Record<string, ParserType>;
  generators: Record<string, GeneratorType>;

  concepts: Record<string, ConceptType>;
  properties: Record<string, PropertyType>;
}

export const useKnowledgeStore = defineStore("knowledgeStore", {
  state: (): State => ({
    initialized: false,

    _currentModuleId: undefined,

    ctlibVersion: "",

    knowledgeItems: {},
    localKnowledge: {},

    modules: {},
    availableModulesList: [],
    links: {},
    enrichments: {},
    shapes: {},
    parsers: {},
    generators: {},

    concepts: {},
    properties: {},
  }),
  getters: {
    currentModuleId(): string {
      if (!this._currentModuleId) {
        throw new Error("No current module ID");
      }
      return this._currentModuleId;
    },
    listModules(): KnowledgeModule[] {
      return Object.values(this.modules);
    },
    listAvailableModules(): AvailableModule[] {
      return this.availableModulesList;
    },
    listConcepts(): ConceptType[] {
      return Object.values(this.concepts);
    },
    listProperties(): PropertyType[] {
      return Object.values(this.properties);
    },
    listShapes(): ShapeType[] {
      return Object.values(this.shapes);
    },
    combinedKnowledge(): Record<string, KnowledgeItem> {
      return Object.assign({}, this.knowledgeItems, this.localKnowledge);
    },
  },
  actions: {
    async load(moduleId: string) {
      const url = `/api/projects/${moduleId}/knowledge`;
      const knowledgeResponse: AxiosResponse<KnowledgeResponse> = await httpClient.get(url);
      this.loadResponse(knowledgeResponse.data);
      this.initialized = true;
      this._currentModuleId = moduleId;
    },
    extractMapKnowledge(map: CTMap) {
      this.localKnowledge = extractMapKnowledge(map, this.getKnowledgeItem);
    },
    getKnowledgeItem(itemId: string): KnowledgeItem {
      const kitem = this.knowledgeItems[itemId];
      if (kitem === undefined) {
        throw `FAILED to find knowledge item with ID ${itemId}`;
      }
      return kitem;
    },
    getModule(moduleId?: string): KnowledgeModule | undefined {
      if (!moduleId) {
        return undefined;
      }
      return this.modules[moduleId];
    },
    getConcept(conceptId?: string): ConceptType | undefined {
      if (!conceptId) {
        return undefined;
      }
      return this.concepts[conceptId];
    },
    getProperty(propertyId?: string): PropertyType | undefined {
      if (!propertyId) {
        return undefined;
      }
      return this.properties[propertyId];
    },
    getShape(shapeId?: string): ShapeType | undefined {
      if (!shapeId) {
        return undefined;
      }
      return this.shapes[shapeId];
    },
    loadResponse(data: KnowledgeResponse) {
      this.ctlibVersion = data.ctlib_version;
      this.modules = mapValues(data.modules, toModule);
      this.availableModulesList = Object.values(data.available_modules).map((m) => {
        const module = toModule(m.module);
        return Object.assign(m, { module });
      });
      this.concepts = data.concepts;
      this.properties = fixPropertyConstructors(data.properties);
      this.links = data.links;
      this.enrichments = data.enrichments;
      this.shapes = data.shapes;
      this.parsers = data.parsers;
      this.generators = data.generators;

      this.knowledgeItems = this.concatKnowledge();
    },
    async reload() {
      await this.modifyKnowledge(() =>
        httpClient.post(`/api/projects/${this.currentModuleId}/knowledge/reload`)
      );
    },
    async update(knowledge_item: KnowledgeItem) {
      const params = {
        module_id: this.currentModuleId,
        knowledge_item,
      };
      await this.modifyKnowledge(() => httpClient.put("/api/knowledge", params));
    },
    async delete(knowledge_item: KnowledgeItem) {
      const params = {
        module_id: this.currentModuleId,
        knowledge_item,
      };
      await this.modifyKnowledge(() => httpClient.put("/api/knowledge/delete", params));
    },
    async createModule(module: KnowledgeMetadata) {
      const project_id = this.currentModuleId;
      await this.modifyKnowledge(() =>
        httpClient.put(`/api/knowledge/${module.id}`, { module, project_id })
      );
    },
    async modifyKnowledge(f: () => Promise<AxiosResponse<KnowledgeResponse>>) {
      const knowledgeResponse = await f();
      this.loadResponse(knowledgeResponse.data);
    },
    /**
     * Concat all knowledge items together for useKnowledge provider.
     * @returns
     */
    concatKnowledge(): Record<string, KnowledgeItem> {
      const items: Record<string, KnowledgeItem> = {};
      for (const ktype of [
        this.concepts,
        this.properties,
        this.links,
        this.shapes,
        this.enrichments,
        this.parsers,
      ]) {
        Object.assign(items, ktype);
      }
      return items;
    },
    async importModule(mapFile: File) {
      const project_id = this.currentModuleId;
      const mapJson = await mapFile.text();
      const module: Module = JSON.parse(mapJson);
      await httpClient.post(`/api/knowledge/${module.manifest.id}`, { project_id, module });
      this.reload();
    },
    async exportModule(module: KnowledgeModule) {
      const response = await httpClient.get(`/api/knowledge/${module.id}`);
      const moduleJson = response.data;
      const blob = new Blob([JSON.stringify(moduleJson, undefined, 2)], {
        type: "application/json",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${module.id}.json`;
      link.target = "_blank";
      link.click();
      URL.revokeObjectURL(link.href);
    },
  },
});

interface Module {
  manifest: {
    id: string;
  };
}

function fixPropertyConstructors(
  properties?: Record<string, PropertyType>
): Record<string, PropertyType> {
  if (properties === undefined) {
    return {};
  }
  return Object.fromEntries(
    Object.entries(properties).map(([id, property]) => {
      property = fixPropertyConstructor(property);
      return [id, property];
    })
  );
}

/**
 * Remove "constructor" key if it's a function rather than a string value.
 * Javascript objects aren't dictionaries but we treat them as such.
 * @param property
 * @returns
 */
function fixPropertyConstructor(property: PropertyType) {
  if (isFunction(property.constructor)) {
    property.constructor = undefined;
  }
  return property;
}

export interface ServerKnowledgeModule {
  id: string;
  label: string;
  icon_name?: string;
  description?: string;
  dependencies?: string[];

  created?: string;
  modified?: string;

  concepts?: Record<string, ConceptType>;
  properties?: Record<string, PropertyType>;
  shapes?: Record<string, ShapeType>;
  enrichments?: Record<string, EnrichmentType>;
}

export type ModuleCategory = "required" | "core" | "user" | "recommended" | "optional";

export function categorySort(category: ModuleCategory): number {
  switch (category) {
    case "required":
      return 0;
    case "core":
      return 1;
    case "user":
      return 2;
    case "recommended":
      return 3;
    case "optional":
      return 4;
  }
}

export interface ServerAvailableModule {
  category: ModuleCategory;
  enabled: boolean;
  module: ServerKnowledgeModule;
}

export interface AvailableModule {
  category: ModuleCategory;
  enabled: boolean;
  module: KnowledgeModule;
}

export interface KnowledgeResponse {
  /**
   * Version of ctlib.
   */
  ctlib_version: string;

  modules: Record<string, ServerKnowledgeModule>;

  concepts: Record<string, ConceptType>;
  properties: Record<string, PropertyType>;
  links: Record<string, LinkType>;
  shapes: Record<string, ShapeType>;
  enrichments: Record<string, EnrichmentType>;
  parsers: Record<string, ParserType>;
  generators: Record<string, GeneratorType>;

  available_modules: Record<string, ServerAvailableModule>;
}

function toModule(serverModule: ServerKnowledgeModule): KnowledgeModule {
  const id = serverModule.id;
  const label = serverModule.label;
  const description = serverModule.description;
  const iconName = serverModule.icon_name;
  const dependencies = serverModule.dependencies;
  const created = serverModule.created ? DateTime.fromISO(serverModule.created) : undefined;
  const modified = serverModule.modified ? DateTime.fromISO(serverModule.modified) : undefined;

  const concepts = sortByLabel(serverModule.concepts);
  const properties = sortByLabel(serverModule.properties);
  const shapes = sortByLabel(serverModule.shapes);
  const enrichments = sortByLabel(serverModule.enrichments);
  return {
    id,
    label,
    description,
    iconName,
    dependencies,
    created,
    modified,
    concepts,
    properties,
    shapes,
    enrichments,
  };
}

function sortByLabel<T extends KnowledgeItem>(items?: Record<string, T>): T[] {
  return sortBy(Object.values(items ?? {}), (item) => item.label);
}
