<template>
  <label
    class="pointer-events-none flex items-start gap-5 pl-10 text-xs font-bold text-gray-500"
    :class="[
      requiredText,
      {
        'text-orange': focus,
      },
    ]"
    >{{ label }}</label
  >
</template>

<script lang="ts" setup>
import { computed } from "vue";
const props = defineProps<{
  label?: string;
  disabled?: boolean;
  focus?: boolean;
  required?: boolean;
}>();

const requiredText = computed(() => {
  if (props.required) {
    return 'after:content-["*"]';
  } else {
    return "";
  }
});
</script>
