import useGraph from "@/common/composables/useGraph";
import useKnowledge from "@/common/composables/useKnowledge";
import { GROUP_BY_ALL } from "@/common/lib/fetchApi";
import { Graph, LinkDescriptor } from "@/common/lib/graph";
import { ROLE_LINK_TYPE } from "@/common/lib/knowledge";
import { Query, validateQuery } from "@/common/lib/query";
import { cloneDeep, fromPairs, isString, pick, uniq } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { useAppStore } from "../stores/app";
import { useExploreStore } from "../stores/explore";

export interface AskResponse {
  query?: Partial<Query>;
  error?: string;
}

export class AskValidationError extends Error {
  public failedQuery;
  public problems;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(failedQuery: Partial<Query>, problems: string[], ...params: any[]) {
    super(...params);
    this.failedQuery = failedQuery;
    this.problems = problems;
  }
}

export class AskLLMRefusalError extends Error {
  public message;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(message: string, ...params: any[]) {
    super(...params);
    this.message = message;
  }
}

export function buildAskRequest(question: string, previousError?: AskValidationError) {
  const exploreStore = useExploreStore();
  const provider_id = exploreStore.llmProvider;
  return {
    scheme: previousError == null ? "query" : "query_repair",
    inputs: {
      request: question, // TODO Sanitize
      metagraph: JSON.stringify(simplifiedMetagraph(exploreStore.metagraph)),
      knowledge: JSON.stringify(selectedKnowledge(exploreStore.metagraph)),
      errors: previousError?.problems.map((e) => `* ${e}`).join("\n"),
      failedQuery: JSON.stringify(previousError?.failedQuery),
    },
    provider_id,
  };
}

export function processAskResponse(response: AskResponse) {
  const appStore = useAppStore();
  if (response.error != null) throw new AskLLMRefusalError(response.error);
  if (response.query == null) throw "Response from the LLM was non-conforming";
  let problems: string[] = [];
  const putativeQuery = {
    columns: [],
    filters: [],
    order_by: [],
    group_by: [],
    ...cloneDeep(response.query),
  };
  if (!isString(putativeQuery.root_concept_type))
    throw new AskValidationError(response.query, ["No root concept type"]);
  // Give filters aliases
  for (const filter of putativeQuery.filters) {
    filter.alias = uuidv4();
  }
  // Check for some common self-inconsistencies
  if (putativeQuery.group_by === GROUP_BY_ALL) {
    const nonaggregates = putativeQuery.columns.filter((c) => isString(c.property_type));
    nonaggregates.forEach((na) =>
      problems.push(`Column ${na.alias}: Only aggregate columns allowed with group by all`)
    );
  }
  const query = putativeQuery as Query;
  problems = [...problems, ...validateQuery(query, appStore.metagraph)];
  if (problems.length > 0) {
    throw new AskValidationError(response.query, problems);
  }
  return query;
}

function simplifiedMetagraph(metagraph: Graph) {
  const { getConcept } = useGraph(() => metagraph);
  return {
    concepts: metagraph.concepts.map((mc) => ({
      concept_type: mc.type,
      property_types: (mc.properties ?? []).map((mp) => mp.type),
    })),
    links: metagraph.links.map((ml) => ({
      descriptor: ml.type === ROLE_LINK_TYPE ? LinkDescriptor.AsA : LinkDescriptor.RelatedTo,
      from: getConcept(ml.from).type,
      to: getConcept(ml.to).type,
    })),
  };
}

function selectedKnowledge(metagraph: Graph) {
  const { getKnowledgeItem } = useKnowledge();
  const allConceptTypes = uniq(metagraph.concepts.map((mc) => mc.type));
  const allPropertyTypes = uniq(
    metagraph.concepts.flatMap((mc) => mc.properties ?? []).map((mp) => mp.type)
  );
  return {
    concept_types: fromPairs(
      allConceptTypes.map((ct) => [
        ct,
        pick(getKnowledgeItem(ct), "label", "description", "aliases"),
      ])
    ),
    property_types: fromPairs(
      allPropertyTypes.map((ct) => [
        ct,
        pick(getKnowledgeItem(ct), "label", "description", "aliases", "value_type"),
      ])
    ),
  };
}
