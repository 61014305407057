import { environment } from "@/common/environments/environmentLoader";
export type IconSize = "xxs" | "xs" | "s" | "m" | "l" | "xl" | "xxl";

// "none" means don't modify the color of the original SVG
export type IconColor =
  | "black"
  | "white"
  | "gray2"
  | "orange"
  | "light-gray"
  | "medium-gray"
  | "dark-gray"
  | "none";

interface IconColorsPerTheme {
  dark: IconColor;
  light: IconColor;
}

export type IconColorSpec = IconColorsPerTheme | IconColor;

export type IconType = "svg" | "css";

export type IconSrc =
  | { type: "svg"; src: string }
  | { type: "css"; iconName: string; styleClasses: string[] };

const SIZES = { xxs: 8, xs: 10, s: 12, m: 16, l: 20, xl: 24, xxl: 32 };

/**
 * Parse an icon name into a local or remote SVG file.
 * @param name
 * @returns
 */
export function parseIconName(name: string): string {
  if (name.startsWith("md:")) {
    return parseMdIconName(name.substring(3));
  }
  return `/images/icons/${name}.svg`;
}

/**
 * Parse a Material Symbol Icon name.
 * @param name Material Symbol icon name, with optional -outline or -fill suffix.
 * @returns
 */
function parseMdIconName(iconName: string): string {
  const basePath = environment.require("MATERIAL_ICONS_URL");
  const weight = 200;
  const opsz = 24;
  let fill = false;
  if (iconName.endsWith("-fill")) {
    iconName = iconName.replace(/-fill$/, "");
    fill = true;
  }
  if (iconName.endsWith("-outline")) {
    iconName = iconName.replace(/-outline$/, "");
    fill = false;
  }
  const fillString = fill ? "fill1" : "";
  return `${basePath}/${iconName}/${iconName}_wght${weight}${fillString}_${opsz}px.svg`;
}

/**
 * Convert an IconSize to size in px.
 * @param size
 * @returns
 */
export function getIconSizePx(size: IconSize): number {
  return SIZES[size];
}
