import { DerivedPropertyTerm } from "./derived";
import { GraphCompoundValue } from "./graph";
import {
  COMPOSITE_PROPERTY_VALUE_TYPE,
  ConceptKnowledgeRef,
  PropertyKnowledgeRef,
} from "./knowledge";
import { GraphValue, GraphValueType } from "./value";

// This is the low-level query interface!
// For the friendlier high-level interface, see ./query.ts

export interface FetchNRequest extends FetchNPathNode {
  neighbors?: Record<string, FetchNNeighborhood>;
  columns?: FetchNColumn[];
  size?: number;
  order_by?: FetchNOrderBy[];
}

export type FetchNNeighborhood = Array<string | FetchNPathNode>;

export const GROUP_BY_ALL = "all";

export interface FetchNPathNode {
  concept_type: ConceptKnowledgeRef;
  properties?: Record<string, DerivedPropertyTerm>;
  filters?: FetchNFilter[];
  tag?: string;
  group_by?: DerivedPropertyTerm[] | typeof GROUP_BY_ALL;
}

export interface FetchNColumn {
  alias: string;
  name?: string;
}

export interface FetchNResponse {
  paths: Array<RootAndNeighborRefs>;
  data: Record<string, FetchNConcept>;
  problems: FetchNProblem[];
}

export type RootAndNeighborRefs = Record<string, string[][]> & {
  root_id: string;
};

// https://github.com/claritype/ct/issues/3225
export const NON_NEIGHBORHOOD_PATH_KEYS = ["root_id", "truncated"];

export type FetchNPropertySet = Record<string, Array<GraphValue | GraphCompoundValue>>;

export interface FetchNConcept {
  concept_type: ConceptKnowledgeRef;
  properties: FetchNPropertySet;
  truncated: string[];
}

export interface FetchNProblem {
  level: "error" | "warning";
  type: string;
  message: string;
  context?: Record<string, unknown>;
}

export interface FetchNOrderBy {
  on: string;
  on_tag?: string;
  asc: boolean;
}

export enum FilterType {
  Equality = "eq",
  Text = "text",
  Range = "range",
  Not = "not",
  Or = "or",
  And = "and",
  Exists = "exists",
}

export const FILTER_TYPES_FOR_PROPERTY_VALUE_TYPE: Record<
  GraphValueType | typeof COMPOSITE_PROPERTY_VALUE_TYPE,
  FilterType[]
> = {
  [GraphValueType.Date]: [FilterType.Exists, FilterType.Equality, FilterType.Range],
  [GraphValueType.Time]: [FilterType.Exists, FilterType.Equality, FilterType.Range],
  [GraphValueType.Datetime]: [FilterType.Exists, FilterType.Equality, FilterType.Range],
  [GraphValueType.Duration]: [FilterType.Exists, FilterType.Equality, FilterType.Range],
  [GraphValueType.Float]: [FilterType.Exists, FilterType.Equality, FilterType.Range],
  [GraphValueType.Integer]: [FilterType.Exists, FilterType.Equality, FilterType.Range],
  [GraphValueType.String]: [FilterType.Exists, FilterType.Equality, FilterType.Text],
  [GraphValueType.Bool]: [FilterType.Exists, FilterType.Equality],
  [GraphValueType.Bytes]: [FilterType.Exists],
  [GraphValueType.Geopoint]: [FilterType.Exists],
  [COMPOSITE_PROPERTY_VALUE_TYPE]: [FilterType.Exists],
};

// This details which value types can appear as filter values, depending on the
// property the filter is being applied to. Most of the time you want them to
// match, but there are some exceptions: for instance, you can apply a date
// filter to a datetime type and the query engine can figure out what you mean.
export const FILTER_VALUE_TYPES_FOR_PROPERTY_VALUE_TYPE: Record<
  GraphValueType | typeof COMPOSITE_PROPERTY_VALUE_TYPE,
  GraphValueType[]
> = {
  [GraphValueType.Date]: [GraphValueType.Date],
  [GraphValueType.Time]: [GraphValueType.Time],
  [GraphValueType.Datetime]: [GraphValueType.Datetime, GraphValueType.Date],
  [GraphValueType.Duration]: [GraphValueType.Duration],
  [GraphValueType.Float]: [GraphValueType.Float, GraphValueType.Integer],
  [GraphValueType.Integer]: [GraphValueType.Integer],
  [GraphValueType.String]: [GraphValueType.String],
  [GraphValueType.Bool]: [GraphValueType.Bool],
  [GraphValueType.Bytes]: [],
  [GraphValueType.Geopoint]: [],
  [COMPOSITE_PROPERTY_VALUE_TYPE]: [],
};

export interface BaseFilter {
  type: FilterType;
}

export interface BasePropertyFilter extends BaseFilter {
  property_type: PropertyKnowledgeRef;
  on_tag?: string;
}

export interface EqualityFilter extends BasePropertyFilter {
  type: FilterType.Equality;
  value: GraphValue;
}

export interface TextFilter extends BasePropertyFilter {
  type: FilterType.Text;
  value: GraphValue;
  match?: "start" | "end" | "contain" | "full";
  case_sensitive?: boolean;
}

export interface RangeFilter extends BasePropertyFilter {
  type: FilterType.Range;
  lt?: GraphValue;
  gt?: GraphValue;
  lte?: GraphValue;
  gte?: GraphValue;
}

export interface ExistenceFilter extends BasePropertyFilter {
  type: FilterType.Exists;
}

export interface NotFilter extends BaseFilter {
  type: FilterType.Not;
  filter: FetchNFilter;
}

export interface OrFilter extends BaseFilter {
  type: FilterType.Or;
  filters: FetchNFilter[];
}

export interface AndFilter extends BaseFilter {
  type: FilterType.And;
  filters: FetchNFilter[];
}

export type PropertyFilter = EqualityFilter | TextFilter | RangeFilter | ExistenceFilter;
export type FetchNFilter = PropertyFilter | NotFilter | OrFilter | AndFilter;

// This is a type for filters that are still in the process of being created.
// It makes everything but type and property_type optional.
export type FilterValue<T extends PropertyFilter = PropertyFilter> = Omit<
  T,
  "type" | "property_type" | "on_tag"
>;

export type AliasLocations = Record<
  string,
  {
    neighborhood: string;
    position: number;
  }
>;
