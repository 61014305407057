<template>
  <div
    class="inline-flex items-center justify-center gap-5 rounded px-[6px] py-[4px] text-center leading-4"
    :class="[
      `text-${textColor}`,
      {
        'border-none text-lg uppercase': !icon,
      },
    ]"
  >
    <Spinner size="l" class="mr-1 px-2" />
    <span>{{ label }}</span>
  </div>
</template>

<script lang="ts" setup>
/*
 * This isn't actually a button, but a status block that is meant to replace a button.
 */
import Spinner from "@/common/components/Spinner.vue";
import { computed, inject, Ref, toRefs } from "vue";
import { DarkMode } from "@/common/lib/keys";

const props = defineProps<{ label: string; icon?: boolean }>();

const { icon, label } = toRefs(props);
const darkMode = inject(DarkMode) as Ref<boolean>;

const textColor = computed(function () {
  if (icon.value) {
    return darkMode.value ? "gray-200" : "gray-700";
  }
  return "orange";
});
</script>
