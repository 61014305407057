<template>
  <label>
    <div class="mt-0 flex items-center px-[6px] py-0">
      <div class="h-[32px] w-[32px] rounded-2xl p-4 hover:bg-gray-200 dark:hover:bg-gray-900">
        <input type="checkbox" v-model="value" :id="id" class="mr-10" />
      </div>
      <div class="ml-2">
        {{ label }}
      </div>
    </div>
  </label>
</template>

<style lang="scss" scoped>
input[type="checkbox"] {
  @apply relative m-4 h-[16px] w-[16px] cursor-pointer appearance-none rounded-[3px] border border-gray-500 p-0 text-gray-500 hover:bg-gray-200 dark:border-gray-200 dark:text-gray-200 dark:hover:bg-gray-900;
}
input[type="checkbox"]:checked {
  @apply border-orange bg-orange text-white dark:text-gray-800;
}
input[type="checkbox"]:checked::after {
  @apply absolute left-5 top-2 h-8 w-4 rotate-45 border-b border-r border-white content-[''] dark:border-gray-800;
}
</style>

<script lang="ts" setup>
import { computed } from "vue";

const props = defineProps<{
  label: string;
  modelValue: boolean;
  ctOrange?: boolean;
  id?: string;
}>();
const emit = defineEmits(["update:modelValue"]);

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});
</script>
