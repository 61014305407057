import { App, inject, Plugin, Ref, ref } from "vue";
import { RouteLocationNormalized } from "vue-router";
import { httpClient } from "../http/http";
import { Sentry } from "../monitoring/sentry/sentry";
import { Auth0AuthProvider } from "./auth0AuthProvider";
import { AuthProvider } from "./authProvider";
import { BackendAuthProvider } from "./backendAuthProvider";
import { NoopAuthProvider } from "./noopAuthProvider";

const authProvider: Ref<AuthProvider | undefined> = ref(undefined);

/**
 * Creates the Auth Provider plugin.
 */
export function createAuthProvider(type: string, sentry?: Sentry): Plugin {
  return new AuthStorePlugin(type, sentry);
}

class AuthStorePlugin {
  constructor(
    private type: string,
    private sentry?: Sentry
  ) {}

  public install(app: App): void {
    authProvider.value = this.buildAuthProvider(app);
    app.provide("AUTH_PROVIDER", authProvider.value);
    httpClient.auth = authProvider.value;
  }

  private buildAuthProvider(app: App): AuthProvider {
    switch (this.type) {
      case "auth0": {
        return new Auth0AuthProvider(this.sentry, app);
      }
      case "backend": {
        return new BackendAuthProvider(this.sentry);
      }
      case "none": {
        return new NoopAuthProvider();
      }
    }
    throw new Error("Unknown auth type: " + this.type);
  }
}

/**
 * Returns the registered AuthProvider instance using Vue's `inject`.
 * @returns An instance of AuthProvider
 */
export function useAuthProvider(): AuthProvider {
  return inject("AUTH_PROVIDER") as AuthProvider;
}

/**
 * Create a Vue Router Guard.
 * This is implemented in a roundabout way because I couldn't figure out how to access injected variables in a route guard.
 * @param to
 * @returns
 */
export function authGuard(to: RouteLocationNormalized): Promise<boolean> | boolean | void {
  if (authProvider.value) {
    return authProvider.value.authGuard(to);
  }
  return;
}

/**
 * Supported auth types.
 */
export type AuthType = "auth0" | "none";
