import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-8d1c22e4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mb-2 text-xxs text-orange" }
const _hoisted_2 = { class: "flex rounded border border-orange bg-gray-300 dark:bg-black" }
const _hoisted_3 = { class: "control flex-initial rounded-l bg-white dark:bg-gray-900" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString($props.title), 1 /* TEXT */),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      _createVNode($setup["IconButton"], {
        name: "check",
        onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.emit('commit'))),
        size: "l",
        class: "ml-5",
        disabled: !$props.valid,
        color: $setup.buttonColor
      }, null, 8 /* PROPS */, ["disabled", "color"]),
      _createVNode($setup["IconButton"], {
        name: "close",
        onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.emit('cancel'))),
        size: "l",
        class: "mr-5",
        color: $setup.buttonColor
      }, null, 8 /* PROPS */, ["color"])
    ])
  ]))
}