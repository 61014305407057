<template>
  <span class="flex justify-between pr-[4px] align-text-top">
    <Tooltip placement="bottom" :delay="{ show: 100, hide: 100 }">
      <template #popper>
        <div class="tooltip-content">{{ warehouseInfo.name }} is {{ warehouseInfo.status }}</div>
      </template>
      <StatusPill
        :color="warehouseStatusColor"
        :label="capitalize(warehouseInfo.status) + ' ' + upperCase(warehouseInfo.name)"
        :sublabel="warehouseInfo.size"
      />
    </Tooltip>
  </span>
</template>

<script lang="ts" setup>
import StatusPill from "@/common/components/StatusPill.vue";
import { Tooltip } from "floating-vue";
import { computed, toRefs } from "vue";
import { Warehouse } from "../stores/workspaceStore";
import { capitalize, upperCase } from "lodash";

const props = defineProps<{
  warehouse: Warehouse;
}>();

const { warehouse } = toRefs(props);

const warehouseInfo = computed(() => getWarehouseInfo(warehouse.value));

const warehouseStatusColor = computed(() => {
  switch (warehouse.value.state) {
    case "RUNNING":
      return "green";
    case "STARTING":
      return "yellow";
    case "STOPPED":
    case "STOPPING":
      return "red";
    case "DELETED":
    case "DELETING":
      return "black";
  }
  return "black";
});

function getWarehouseInfo(warehouse: Warehouse) {
  return {
    status: warehouse.state,
    name: warehouse.name,
    size: warehouse.cluster_size,
  };
}
</script>
