import { combinedQuerySignature } from "@/common/lib/combiningQuery";
import { TRANSFORMERS } from "@/common/lib/format";
import { GraphValue, isNumeric, toNative, toValue } from "@/common/lib/value";
import { isEqual, isString, pick, sum } from "lodash";
import { computed, ref, Ref } from "vue";
import {
  generatePropertyValue,
  generateValueSets,
  ValueGenerator,
  ValueGeneratorType,
  ValueSet,
} from "../lib/visualization";
import { Visualization } from "../lib/visualizationTypes";
import { UseQueryResult } from "./useQuery";

// This composable is designed to make life easier for relatively straightforward
// Visualization views. At least in its current state, it won't work so well if
// your visualization config doesn't have a static set of generators defined at
// the top level.

export default function useVisualization(
  visualization: Ref<Visualization>,
  primaryResults: Ref<UseQueryResult[]>,
  comparisonResults: Ref<UseQueryResult[] | null>,
  indexProps: string[],
  valueProps: string[],
  requiredProps: string[]
) {
  const legendWidth = ref(0);

  function generators() {
    const gens = pick(visualization.value.config, ...indexProps, ...valueProps);
    return gens as Record<string, ValueGenerator>;
  }

  function query() {
    return combinedQuerySignature(visualization.value.query);
  }

  const valueSets = computed(function () {
    const primaries = generateValueSets(generators(), primaryResults.value, query(), requiredProps);
    const comparisons = generateValueSets(
      generators(),
      comparisonResults.value ?? [],
      query(),
      requiredProps
    );
    return primaries.map((primary) => {
      const comparison = comparisons.find(function (candidateComparison) {
        for (const prop of indexProps) {
          if (!isEqual(primary[prop], candidateComparison[prop])) return false;
        }
        return true;
      });
      const diff = diffValueSets(primary, comparison);
      return { primary, comparison, diff };
    });
  });

  function diffValueSets(primarySet: ValueSet, comparisonSet: ValueSet | undefined) {
    if (comparisonSet == undefined) return undefined;
    // Later this will presumably depend on what comparison mode we're in
    const diffSet: ValueSet = {};
    for (const [alias, primary] of Object.entries(primarySet)) {
      const comparison = comparisonSet[alias];
      if (
        primary != null &&
        comparison != null &&
        isNumeric(primary.originalValue) &&
        isNumeric(comparison.originalValue)
      ) {
        const difference = toValue(
          toNative(primary.originalValue) / toNative(comparison.originalValue)
        );
        diffSet[alias] = {
          originalValue: difference,
          formattedValue: TRANSFORMERS["percent"](difference),
        };
      }
    }
    return diffSet;
  }

  function propTransformer(alias: string) {
    const generator = generators()[alias];
    if (!isString(generator) && generator.type === ValueGeneratorType.Property) {
      return generator.transformer;
    }
    return undefined;
  }

  function handleLegendResize({ width: x }: { width: number }) {
    legendWidth.value = x;
  }

  function propSum(alias: string) {
    const totalNum = sum(
      valueSets.value.map((vs) => toNative(vs.primary[alias]!.originalValue as GraphValue))
    );
    return generatePropertyValue(generators()[alias], toValue(totalNum), query());
  }

  return {
    propTransformer,
    generators,
    query,
    valueSets,
    legendWidth,
    handleLegendResize,
    propSum,
  };
}
