<template>
  <div v-if="isEditing">
    {{ opDescription }}
    <InPlaceEdit
      title="Exact matches (one value per line)"
      @cancel="stopEditing"
      @commit="commit"
      :valid="isValid"
    >
      <textarea
        v-model="valueUnderEdit"
        ref="valueInputEl"
        class="w-full border-transparent bg-transparent"
      ></textarea>
    </InPlaceEdit>
  </div>

  <div
    v-else
    class="cursor-pointer truncate border-b border-dotted border-b-transparent font-bold hover:border-b-orange"
    @click="startEditing"
  >
    {{ opDescription }}
    {{ valueList }}
  </div>
</template>

<script setup lang="ts">
import InPlaceEdit from "@/common/components/InPlaceEdit.vue";
import { EqualityFilter } from "@/common/lib/fetchApi";
import { QueryFilter } from "@/common/lib/query";
import { stringifyValue, toValue } from "@/common/lib/value";
import { useExploreStore } from "@/reader/stores/explore";
import { computed, nextTick, onMounted, Ref, ref, toRefs } from "vue";

const props = defineProps<{ filter: QueryFilter<EqualityFilter> }>();
const { filter } = toRefs(props);

const valueUnderEdit: Ref<string | null> = ref(null);
const valueInputEl: Ref<HTMLInputElement | null> = ref(null);
const emit = defineEmits(["toggleEditing"]);

const opDescription = computed(function () {
  let desc = filter.value.negated ? "is not" : "is";
  if (filter.value.values.length > 1 || isEditing.value) {
    desc = desc + " any of";
  }
  return desc;
});
const isEditing = computed(() => valueUnderEdit.value !== null);
const valueList = computed(() =>
  filter.value.values.length === 0
    ? "(click to set)"
    : filter.value.values.map((v) => stringifyValue(v.value)).join(", ")
);
const isValid = computed(() => cleanEditedValue().length > 0);

const exploreStore = useExploreStore();

function startEditing() {
  if (!isEditing.value)
    valueUnderEdit.value = filter.value.values.map((f) => f.value.value).join("\n");
  emit("toggleEditing", true);
}

function cleanEditedValue() {
  return valueUnderEdit
    .value!.split("\n")
    .map((v) => v.trim())
    .filter((f) => f.length > 0);
}

function commit() {
  filter.value.values = cleanEditedValue().map((v) => ({ value: toValue(v) }));
  stopEditing();
  exploreStore.load();
}

function stopEditing() {
  valueUnderEdit.value = null;
  emit("toggleEditing", false);
}

onMounted(function () {
  if (filter.value.values.length === 0) {
    startEditing();
    nextTick(() => valueInputEl.value?.focus());
  }
});
</script>
