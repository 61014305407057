import useKnowledge from "@/common/composables//useKnowledge";
import useGraph from "@/common/composables/useGraph";
import { TableIntermediate } from "@/common/lib/api";
import { asyncResultOr } from "@/common/lib/async";
import { emptyGraph, Graph } from "@/common/lib/graph";
import { ConceptKnowledgeRef } from "@/common/lib/knowledge";
import {
  CTMap,
  getMapClause,
  getMapSection,
  locationsForPropertyClause,
  MapInConnectionClause,
  MapLocationType,
  MapSectionKey,
  transformLineageForDataPath,
} from "@/common/lib/map";
import { useAppStore } from "@/editor/stores/app";
import { compact, last, partition, without } from "lodash";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useDataset(datasetId: () => string) {
  const appStore = useAppStore();
  const { isRecordConceptType } = useKnowledge();

  function map(): CTMap {
    return appStore.map ?? {};
  }

  function finalDecodeClause() {
    return getMapClause(map(), MapSectionKey.InEncodings, datasetId());
  }

  // XXX This will fail on extract clauses that are not directly connected to an input
  function connectionClause() {
    return getMapClause(map(), MapSectionKey.InConnections, finalDecodeClause().input as string);
  }

  function sourceGraph(index: number) {
    const intermediates = asyncResultOr(appStore.intermediates, null);
    if (intermediates == null) return undefined;
    const graph = intermediates.iterate_in[datasetId()]?.[index];
    return graph != null ? useGraph(() => graph) : undefined;
  }

  function sourceName() {
    const name = sourceLongName();
    return last(name.split(/\/|\./)) as string;
  }

  function sourceLongName() {
    const decode = finalDecodeClause();
    if (decode.input != null) {
      const connection = connectionClause();
      return buildSourceName(connection);
    } else {
      return "<Container>"; // TODO Something sensible
    }
  }

  function tableIntermediate(): TableIntermediate | undefined {
    const intermediates = asyncResultOr(appStore.intermediates, null);
    const result = intermediates?.in_connections;
    if (result == null) {
      return undefined;
    }
    return result[datasetId()];
  }

  function bytes(): number | undefined {
    return tableIntermediate()?.bytes;
  }

  function rows(): number | undefined {
    return tableIntermediate()?.rows;
  }

  function catalogPath() {
    const decode = finalDecodeClause();
    if (decode.input == null) {
      return undefined;
    }
    const connection = connectionClause();
    const table = connection.table_name;
    const schema = connection.schema_name;
    const catalog = connection.catalog_name;
    if (!table || !schema || !catalog) {
      return undefined;
    }
    return { catalog, schema, table };
  }

  function buildSourceName(connection: MapInConnectionClause): string {
    const name = connection.filename ?? connection.url;
    if (name) {
      return name;
    }
    let fullName = connection.table_name;
    if (fullName === undefined) {
      return "<Container>"; // TODO Something sensible
    }
    if (connection.schema_name) {
      fullName = `${connection.schema_name}.${fullName}`;
      if (connection.catalog_name) {
        fullName = `${connection.catalog_name}.${fullName}`;
      }
    }
    return fullName;
  }

  function description() {
    return tableIntermediate()?.table_description;
  }

  function documents() {
    const intermediates = asyncResultOr(appStore.intermediates, null);
    const result = intermediates?.transform_in ?? intermediates?.in_encoding;
    if (result == null) return [];
    return result[datasetId()]?.documents ?? [];
  }

  function fields() {
    // XXX Only handles flat documents
    const mapVal = map();
    const datasetIdVal = datasetId();
    const intermediates = asyncResultOr(appStore.intermediates, null);
    const result = intermediates?.transform_in ?? intermediates?.in_encoding;
    if (result == null) return [];
    const headers = result[datasetId()]?.headers ?? [];
    const fields = headers.map((h) => h.field_name);
    const [transformedFields, plainFields] = partition(
      fields,
      (field) => transformLineageForDataPath(mapVal, datasetIdVal, [field]).length > 0
    );
    transformedFields.sort();
    return [...plainFields, ...transformedFields];
  }

  function headerForDataPath(path: string[]) {
    const intermediates = asyncResultOr(appStore.intermediates, null);
    const result = intermediates?.transform_in ?? intermediates?.in_encoding;
    if (result == null) return undefined;
    const headers = result[datasetId()]?.headers ?? [];
    return headers.find((header) => header.field_name === path[0]);
  }

  function displayNameForDataPath(path: string[]) {
    const mapValue = map();
    const lineage = transformLineageForDataPath(mapValue, datasetId(), path);
    const pathName = path.join("/");
    if (lineage.length === 0) return pathName;
    let name = "";
    const transforms = [];
    for (const clauseKey of lineage) {
      const clause = getMapClause(mapValue, MapSectionKey.InTransforms, clauseKey);
      if (name.length === 0) name = clause.from_path?.join("/") ?? "?";
      switch (clause.type) {
        case "split":
          transforms.push(`split on '${clause.delimiter}'`);
          break;
        case "get_at_index":
          transforms.push(`element ${(clause.index as number) + 1}`);
          break;
        case "str_to_int":
          transforms.push("as int");
          break;
        default:
          transforms.push(clause.type);
      }
    }
    if (name !== pathName) {
      return pathName; // If renamed from the original field, trust user to have given a descriptive name
    } else {
      return `${name} (${transforms.join(" ")})`;
    }
  }

  function iteratorClauseKeys() {
    const clauses = getMapSection(map(), MapSectionKey.InIterators);
    return Object.keys(clauses).filter((key) => clauses[key].dataset === datasetId());
  }

  function conceptClauseKeys() {
    const iterators = iteratorClauseKeys();
    const clauses = getMapSection(map(), MapSectionKey.InConcepts);
    return Object.keys(clauses).filter((key) => iterators.includes(clauses[key].source_iterator));
  }

  function unassignedConceptClauseKey() {
    // This boldly assumes that there will only ever be one record per dataset
    for (const ckey of conceptClauseKeys()) {
      const clause = getMapClause(map(), MapSectionKey.InConcepts, ckey);
      if (isRecordConceptType(clause.concept_type)) {
        return ckey;
      }
    }
    return null;
  }

  function propertyClauseKeys() {
    const allProps = getMapSection(map(), MapSectionKey.InProperties);
    return conceptClauseKeys().flatMap((ckey) =>
      Object.keys(allProps).filter((pkey) => allProps[pkey].on_concept === ckey)
    );
  }

  function conceptClauseKeysForDataPath(path: string[]) {
    // This function assumes that we're in a flat, simple one-iterator situation.
    // It's meant for use by the flat data table, which we won't use outside of
    // those circumstances.
    return conceptClauseKeys().filter(function (conceptKey) {
      const propertyMappings = Object.values(getMapSection(map(), MapSectionKey.InProperties));
      for (const propClause of propertyMappings) {
        if (propClause.on_concept === conceptKey) {
          const locs = locationsForPropertyClause(propClause);
          for (const loc of locs) {
            if (loc.location === MapLocationType.Property && loc.property_type === path[0])
              return true;
          }
        }
      }
      return false;
    });
  }

  function propertyClauseKeysForDataPath(path: string[]) {
    // This function assumes that we're in a flat, simple one-iterator situation.
    // It's meant for use by the flat data table, which we won't use outside of
    // those circumstances.
    return propertyClauseKeys().filter(function (propertyKey) {
      const propClause = getMapClause(map(), MapSectionKey.InProperties, propertyKey);
      const locs = locationsForPropertyClause(propClause);
      for (const loc of locs) {
        if (loc.location === MapLocationType.Property && loc.property_type === path[0]) return true;
      }
      return false;
    });
  }

  function linkClauseKeys() {
    const conceptKeys = conceptClauseKeys();
    const clauses = getMapSection(map(), MapSectionKey.InLinks);
    return Object.keys(clauses).filter((key) => conceptKeys.includes(clauses[key].from_concept));
  }

  function activeParserForPropertyMapping(propClauseKey: () => string) {
    const parsers = Object.entries(getMapSection(map(), MapSectionKey.InPropertyParsers)).filter(
      ([, parser]) => parser.on_property === propClauseKey()
    );
    if (parsers.length === 1) return parsers[0][0];
    return null;
  }

  function filteredConceptGraph(): Graph {
    const intermediates = asyncResultOr(appStore.intermediates, null);
    const map_in = intermediates?.map_in;
    if (map_in == null) return emptyGraph();
    const dataset = datasetId();
    const concepts = map_in.graph.concepts.filter((c) => c.trace?.in_connections === dataset);
    const conceptIds = concepts.map((c) => c.id);
    return {
      concepts,
      links: map_in.graph.links.filter(
        (l) => conceptIds.includes(l.from) && conceptIds.includes(l.to)
      ),
    };
  }

  function mappingsAsGraph(): Graph {
    const unassigned = unassignedConceptClauseKey();
    const concepts = without(conceptClauseKeys(), unassigned) as string[];
    return {
      concepts: concepts.map((cck) => ({ id: cck, type: cck as ConceptKnowledgeRef })),
      links: compact(
        linkClauseKeys().map(function (lck) {
          const link = getMapClause(map(), MapSectionKey.InLinks, lck);
          if (link.from_concept === unassigned || link.to_concept === unassigned) return undefined;
          return { id: lck, type: link.link_type, from: link.from_concept, to: link.to_concept };
        })
      ),
    };
  }

  function eligibleToLink(conceptKey1: string, conceptKey2: string) {
    // Are we trying to link a concept to itself?
    if (conceptKey1 === conceptKey2) return false;
    // Are these two concepts already linked?
    for (const lckey of linkClauseKeys()) {
      const clause = getMapClause(appStore.mapOrEmptyMap, MapSectionKey.InLinks, lckey);
      const ends = [clause.from_concept, clause.to_concept];
      if (ends.includes(conceptKey1) && ends.includes(conceptKey2)) return false;
    }
    // XXXNST Check for "One Rule For Roles"
    return true;
  }

  function ignoredPaths() {
    return finalDecodeClause().exclude_paths ?? [];
  }

  return {
    sourceGraph,
    finalDecodeClause,
    connectionClause,
    sourceName,
    sourceLongName,
    description,
    rows,
    bytes,
    documents,
    fields,
    displayNameForDataPath,
    iteratorClauseKeys,
    conceptClauseKeys,
    linkClauseKeys,
    conceptClauseKeysForDataPath,
    propertyClauseKeysForDataPath,
    activeParserForPropertyMapping,
    unassignedConceptClauseKey,
    filteredConceptGraph,
    mappingsAsGraph,
    eligibleToLink,
    headerForDataPath,
    ignoredPaths,
    catalogPath,
  };
}
