import { SystemTable } from "@/common/stores/sourceBrowser";
import { cloneDeep, isEqual, last, omit, some } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { ConceptKnowledgeRef, PropertyKnowledgeRef, RECORD_CONCEPT_TYPE } from "./knowledge";
import {
  allDependentsOfClause,
  CTMap,
  getMapClause,
  getMapSection,
  MapAdhocTypeClause,
  MapClauseReference,
  MapConceptClause,
  MapEnrichmentClause,
  MapInEncodingClause,
  MapLinkClause,
  MapPropertyClause,
  MapPropertyParserClause,
  MapSection,
  MapSectionKey,
  MapSectionToClauseType,
  MapTransformClause,
  SourceType,
} from "./map";

export class MapAction {
  readonly map: CTMap;
  readonly originalMap: CTMap;

  constructor(map: CTMap) {
    this.originalMap = map;
    this.map = cloneDeep(map);
  }

  isChanged() {
    return !isEqual(this.originalMap, this.map);
  }

  addLocalFile(filename: string, mimeType: string, friendlyName: string) {
    const readClauseId = this.generateClauseKey(MapSectionKey.InConnections, friendlyName);
    this.setClause(MapSectionKey.InConnections, readClauseId, {
      type: SourceType.LocalFile,
      filename: filename,
    });
    const extractClauseId = this.generateClauseKey(MapSectionKey.InEncodings, friendlyName);
    this.setClause(MapSectionKey.InEncodings, extractClauseId, {
      type: "read_documents",
      input: readClauseId,
      mime_type: mimeType,
    });
    return extractClauseId;
  }

  addUrl(url: string, mimeType: string, friendlyName: string) {
    const readClauseId = this.generateClauseKey(MapSectionKey.InConnections, friendlyName);
    this.setClause(MapSectionKey.InConnections, readClauseId, {
      type: SourceType.FromURL,
      url: url,
    });
    const extractClauseId = this.generateClauseKey(MapSectionKey.InEncodings, friendlyName);
    this.setClause(MapSectionKey.InEncodings, extractClauseId, {
      type: "read_documents",
      input: readClauseId,
      mime_type: mimeType,
    });
    return extractClauseId;
  }

  addDbxTable(table: SystemTable) {
    const readClauseId = this.generateClauseKey(MapSectionKey.InConnections, table.name);
    this.setClause(MapSectionKey.InConnections, readClauseId, {
      type: table.provider,
      catalog_name: table.catalog,
      schema_name: table.schema,
      table_name: table.name,
    });
    const extractClauseId = this.generateClauseKey(MapSectionKey.InEncodings, table.name);
    this.setClause(MapSectionKey.InEncodings, extractClauseId, {
      type: "read_documents",
      input: readClauseId,
      mime_type: "application/dbx",
    });
    return extractClauseId;
  }

  removeConnection(connectionClauseKey: string) {
    this.removeClauseAndDependents(MapSectionKey.InConnections, connectionClauseKey);
  }

  editInEncoding(inEncodingKey: string, newValues: Partial<MapInEncodingClause>) {
    const encClause = getMapClause(this.map, MapSectionKey.InEncodings, inEncodingKey);
    this.setClause(MapSectionKey.InEncodings, inEncodingKey, {
      ...encClause,
      ...newValues,
    });
  }

  addInTransform(transformClause: MapTransformClause) {
    const clauseKey = this.generateClauseKey(
      MapSectionKey.InTransforms,
      `${transformClause.type}-${(transformClause.from_path ?? []).join("-")}`
    );
    this.setClause(MapSectionKey.InTransforms, clauseKey, transformClause);
    return clauseKey;
  }

  editInTransform(inTransformKey: string, newValues: Partial<MapTransformClause>) {
    const transformClause = getMapClause(this.map, MapSectionKey.InTransforms, inTransformKey);
    this.setClause(MapSectionKey.InTransforms, inTransformKey, {
      ...transformClause,
      ...newValues,
    });
  }

  editConcept(mapConceptKey: string, newValues: Partial<MapConceptClause>) {
    const clause = getMapClause(this.map, MapSectionKey.InConcepts, mapConceptKey);
    this.setClause(MapSectionKey.InConcepts, mapConceptKey, { ...clause, ...newValues });
  }

  editProperty(mapPropertyKey: string, newValues: Partial<MapPropertyClause>) {
    const propClause = getMapClause(this.map, MapSectionKey.InProperties, mapPropertyKey);
    this.setClause(MapSectionKey.InProperties, mapPropertyKey, { ...propClause, ...newValues });
  }

  addPropertyParser(parserClause: MapPropertyParserClause) {
    const clauseKey = this.generateClauseKey(
      MapSectionKey.InPropertyParsers,
      `$parse-${parserClause.on_property}`
    );
    this.setClause(MapSectionKey.InPropertyParsers, clauseKey, parserClause);
    return clauseKey;
  }

  editPropertyParser(mapPropertyParserKey: string, newValues: Partial<MapPropertyParserClause>) {
    const parserClause = getMapClause(
      this.map,
      MapSectionKey.InPropertyParsers,
      mapPropertyParserKey
    );
    this.setClause(MapSectionKey.InPropertyParsers, mapPropertyParserKey, {
      ...parserClause,
      ...newValues,
    });
  }

  editLink(mapLinkKey: string, newValues: Partial<MapLinkClause>) {
    const linkClause = getMapClause(this.map, MapSectionKey.InLinks, mapLinkKey);
    this.setClause(MapSectionKey.InLinks, mapLinkKey, { ...linkClause, ...newValues });
  }

  mergeConcepts(fromConceptClauseKey: string, toConceptClauseKey: string) {
    // 1. Move all properties from "from" concept to "to" concept
    for (const [propClauseKey, propClause] of Object.entries(
      getMapSection(this.map, MapSectionKey.InProperties)
    )) {
      if (
        propClause.on_concept === fromConceptClauseKey ||
        propClause.on_concept === toConceptClauseKey
      ) {
        this.setClause(MapSectionKey.InProperties, propClauseKey, {
          ...propClause,
          on_concept: toConceptClauseKey,
        });
      }
    }
    // 2. Redirect asserted links from "from" concept to "to" concept, unless
    // that would create a link between the "to" concept and itself, or if
    // there is already an asserted link between those concepts
    const assertedLinks = Object.entries(getMapSection(this.map, MapSectionKey.InLinks));
    for (const [linkClauseKey, linkClause] of assertedLinks) {
      const partners = [linkClause.from_concept, linkClause.to_concept];
      if (partners.includes(fromConceptClauseKey)) {
        const repaired = partners.map((p) => (p === fromConceptClauseKey ? toConceptClauseKey : p));
        if (repaired[0] === repaired[1]) continue;
        // "prettier" is so incredibly ugly sometimes
        if (
          some(
            assertedLinks,
            ([, al]) =>
              (al.from_concept === repaired[1] && al.to_concept === repaired[0]) ||
              (al.from_concept === repaired[0] && al.to_concept === repaired[1])
          )
        )
          continue;
        this.setClause(MapSectionKey.InLinks, linkClauseKey, {
          ...linkClause,
          from_concept: repaired[0],
          to_concept: repaired[1],
        });
      }
    }
    // 3. Unassert "from" concept (if it was asserted). This will also unassert
    // any self-pointing links that got left behind in step 2
    this.removeClauseAndDependents(MapSectionKey.InConcepts, fromConceptClauseKey);
  }

  addConcept(conceptClause: MapConceptClause) {
    const clauseKey = this.generateClauseKey(MapSectionKey.InConcepts, conceptClause.concept_type);
    this.setClause(MapSectionKey.InConcepts, clauseKey, conceptClause);
    return clauseKey;
  }

  removeConcept(conceptClauseKey: string) {
    this.removeClauseAndDependents(MapSectionKey.InConcepts, conceptClauseKey);
  }

  addLink(linkClause: MapLinkClause) {
    const clauseKey = this.generateClauseKey(
      MapSectionKey.InLinks,
      `${linkClause.link_type}-${linkClause.from_concept}-${linkClause.to_concept}`
    );
    this.setClause(MapSectionKey.InLinks, clauseKey, linkClause);
    return clauseKey;
  }

  removeLink(linkClauseKey: string) {
    this.removeClauseAndDependents(MapSectionKey.InLinks, linkClauseKey);
  }

  addProperty(propClause: MapPropertyClause) {
    const clauseKey = this.generateClauseKey(
      MapSectionKey.InProperties,
      `${propClause.on_concept}-${propClause.property_type}`
    );
    this.setClause(MapSectionKey.InProperties, clauseKey, propClause);
    return clauseKey;
  }

  removeProperty(propertyClauseKey: string) {
    this.removeClauseAndDependents(MapSectionKey.InProperties, propertyClauseKey);
  }

  removeParsersForProperty(propertyClauseKey: string) {
    for (const [parserKey, parserClause] of Object.entries(
      getMapSection(this.map, MapSectionKey.InPropertyParsers)
    )) {
      if (parserClause.on_property === propertyClauseKey) {
        this.removeClauseAndDependents(MapSectionKey.InPropertyParsers, parserKey);
      }
    }
  }

  addEnrichment(
    enrichmentTypeId: string,
    enrichmentSignatureId: string,
    dependsOnClauseKey?: string[]
  ) {
    const enrichClauseId = this.generateClauseKey(
      MapSectionKey.Enrichments,
      last(enrichmentTypeId.split(".")) as string
    );
    const clause: MapEnrichmentClause = {
      type: enrichmentTypeId,
      signature: enrichmentSignatureId,
      depends_on: dependsOnClauseKey,
    };
    this.setClause(MapSectionKey.Enrichments, enrichClauseId, clause);
    return enrichClauseId;
  }

  removeEnrichment(enrichmentClauseKey: string) {
    this.removeClauseAndDependents(MapSectionKey.Enrichments, enrichmentClauseKey);
  }

  addResolution(conceptType: ConceptKnowledgeRef, propertyTypes: PropertyKnowledgeRef[]) {
    const clauseKey = this.generateClauseKey(
      MapSectionKey.Resolutions,
      `${conceptType}--${propertyTypes.join("-")}`
    );
    (this.map[MapSectionKey.Resolutions] ??= {})[clauseKey] = {
      type: conceptType,
      on: propertyTypes,
    };
    return clauseKey;
  }

  removeResolutions(conceptType: string, propertyTypes: string[]) {
    const resolutions = getMapSection(this.map, MapSectionKey.Resolutions);
    const toDelete = Object.keys(resolutions).filter(
      (key) => resolutions[key].type === conceptType && isEqual(resolutions[key].on, propertyTypes)
    );
    for (const resKey of toDelete)
      this.removeClauseAndDependents(MapSectionKey.Resolutions, resKey);
  }

  addAdHocConceptType(adHocClause: MapAdhocTypeClause) {
    const clauseKey = this.generateClauseKey(MapSectionKey.AdhocConcepts, adHocClause.type);
    this.setClause(MapSectionKey.AdhocConcepts, clauseKey, adHocClause);
    return clauseKey;
  }

  addAdHocPropertyType(adHocClause: MapAdhocTypeClause) {
    const clauseKey = this.generateClauseKey(MapSectionKey.AdhocProperties, adHocClause.type);
    this.setClause(MapSectionKey.AdhocProperties, clauseKey, adHocClause);
    return clauseKey;
  }

  addUnassignedConcept(sourceIterator: string) {
    const recordTypeRef: ConceptKnowledgeRef = `concept._._record_${uuidv4().replaceAll("-", "_")}`;
    this.addAdHocConceptType({
      label: "Unassigned Properties",
      parent: RECORD_CONCEPT_TYPE,
      type: recordTypeRef,
    });
    return this.addConcept({
      concept_type: recordTypeRef,
      source_iterator: sourceIterator,
    });
  }

  private setClause<T extends MapSectionKey>(
    sectionKey: T,
    clauseKey: string,
    contents: MapSectionToClauseType[T]
  ) {
    const section = (this.map[sectionKey] ??= {}) as MapSection<typeof contents>;
    section[clauseKey] = contents;
  }

  private removeClauseAndDependents(sectionKey: MapSectionKey, clauseKey: string) {
    const clauseRef: MapClauseReference = [sectionKey, clauseKey];
    const removeList = [clauseRef, ...allDependentsOfClause(this.map, clauseRef)];
    for (const [section, clause] of removeList) {
      this.map[section] = omit(this.map[section] ?? {}, clause);
    }
  }

  private generateClauseKey(sectionKey: MapSectionKey, suggestion: string) {
    const candidate = suggestion.toLowerCase().replace(/[^a-z0-9-_]/gi, "");
    if (getMapSection(this.map, sectionKey)[candidate] == null) {
      return candidate;
    } else {
      return uuidv4();
    }
  }
}
