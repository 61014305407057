import useGraph from "@/common/composables/useGraph";
import { MetagraphLayout } from "@/common/lib/webcola";
import { findKey } from "lodash";
import { defineStore } from "pinia";
import { toRaw } from "vue";
import { useAppStore } from "./app";

let metagraphLayout = new MetagraphLayout();

interface State {
  selectedConceptType: string | null;
  selectedConceptIndex: number | null;
  layoutByConcept: Record<string, { x: number; y: number; w: number; h: number; index: number }>;
  panOrigin: [number, number];
  scale: {
    scale: number;
    size: string;
    pos: string;
  };
}

export const useUnifiedConceptViewStore = defineStore("editor-unifiedConceptView", {
  state: (): State => ({
    selectedConceptIndex: null,
    selectedConceptType: null,
    layoutByConcept: {},
    panOrigin: [0, 0],
    scale: {
      scale: 1,
      size: "100%",
      pos: "0",
    },
  }),
  actions: {
    selectConceptType(conceptTypeId: string | null) {
      this.selectedConceptType = conceptTypeId;
      if (conceptTypeId == null) {
        this.selectedConceptIndex = null;
      } else {
        this.selectedConceptIndex = 0;
      }
    },
    selectConceptIndex(index: number) {
      this.selectedConceptIndex = index;
    },
    ensureSelectedConceptTypeInSet(concepts: string[]) {
      if (concepts.length === 0) {
        this.selectConceptType(null);
      } else {
        if (!this.selectedConceptType || !concepts.includes(this.selectedConceptType)) {
          this.selectConceptType(concepts[0]);
        }
      }
    },
    initializeLayout() {
      const appStore = useAppStore();
      if (appStore.metagraph == null) return;
      const metagraph = useGraph(() => toRaw(appStore.metagraph!)).metagraphWithoutRecords();
      const newLayoutNodes: typeof this.layoutByConcept = {};
      metagraph.concepts.forEach((concept, index) => {
        const prior = this.layoutByConcept[concept.type];
        newLayoutNodes[concept.type] = {
          x: prior?.x || 0,
          y: prior?.y || 0,
          w: 0,
          h: 0,
          index,
        };
      });
      this.layoutByConcept = newLayoutNodes;
      metagraphLayout = new MetagraphLayout();
      metagraphLayout.avoidOverlaps(true);
      metagraphLayout.onUpdateView = this.layoutEngineTick;
      const { getConcept } = useGraph(() => metagraph);
      metagraphLayout.links(
        metagraph.links.map((link) => {
          const from = getConcept(link.from);
          const to = getConcept(link.to);
          return {
            source: this.layoutByConcept[from.type].index,
            target: this.layoutByConcept[to.type].index,
          };
        })
      );
    },
    layoutEngineTick() {
      // This callback fires when the layout engine has a new layout for us
      for (const node of metagraphLayout.nodes()) {
        const key = findKey(this.layoutByConcept, (l) => l.index === node.index);
        if (key != null) {
          this.layoutByConcept[key].x = node.x;
          this.layoutByConcept[key].y = node.y;
        }
      }
    },
    startLayout() {
      metagraphLayout.nodes().forEach((node, index) => {
        const layout = Object.values(toRaw(this.layoutByConcept)).find((l) => l.index === index);
        if (layout != null) {
          node.x = layout.x;
          node.y = layout.y;
          node.width = layout.w;
          node.height = layout.h;
        }
      });
      metagraphLayout.jaccardLinkLengths(200);
      metagraphLayout.start();
    },
  },
});
