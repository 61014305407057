<template>
  <div class="absolute inset-0 overflow-auto px-15">
    <div class="flex min-h-[36px] flex-row content-center gap-10">
      <TextButton label="Copy SQL" class="copy-button" @click="copyContents" />
      <div
        class="rounded-full bg-gray-900 px-20 py-10 text-gray-200 shadow-snackbar"
        v-if="displaySnackbar"
      >
        Copied!
      </div>
    </div>
    <div class="p-10 font-monospace dark:bg-gray-900">
      <span
        v-for="([line, indent], index) of sqlLines"
        :key="index"
        :style="{ marginLeft: indent }"
      >
        <span
          v-for="(segment, segmentIndex) of line"
          :key="`${index}:${segmentIndex}`"
          class="select-text"
          :class="'sql-' + segment.name"
          >{{ segment.content }}</span
        >
        <br />
      </span>
    </div>
  </div>
</template>
<style lang="css" scoped>
.sql-function {
  color: #6fbff9;
}
.sql-string {
  color: #c5947c;
}
.sql-keyword {
  color: #bc89bd;
  font-weight: 500;
}
.sql-bracket {
  color: #ebcc46;
}
.sql-number {
  color: #bacdab;
}
.sql-comment {
  color: #698855;
}
.sql-default {
  color: #dadbae;
}
.sql-special {
  color: #c36f6c;
}
</style>

<script setup lang="ts">
import TextButton from "@/common/components/TextButton.vue";
import { asyncResultOr } from "@/common/lib/async";
import { sleep } from "@/common/lib/asynchronous";
import { useExploreStore } from "@/reader/stores/explore";
import { format as sql_format } from "sql-formatter";
import { getSegments, Segment } from "sql-highlight";
import { computed, ref } from "vue";

const exploreStore = useExploreStore();

const displaySnackbar = ref(false);

const sqlData = computed(() => {
  const sql = asyncResultOr(exploreStore.sqlData, "");
  try {
    return sql_format(sql, {
      language: "spark",
      tabWidth: 4,
      keywordCase: "upper",
      linesBetweenQueries: 2,
    });
  } catch (error: unknown) {
    return sql;
  }
});

const sqlLines = computed(() => formatSql(sqlData.value));

function formatSql(sql: string): [Segment[], string][] {
  return sql.split(/\n/).map((line) => {
    const trimmed = line.trimStart();
    const indent = line.length - trimmed.length;
    const tokenized = getSegments(trimmed);
    return [tokenized, `${indent * 4}px`];
  });
}

async function copyContents() {
  await navigator.clipboard.writeText(sqlData.value);
  displaySnackbar.value = true;
  await sleep(2000);
  displaySnackbar.value = false;
}
</script>
