<template>
  <div
    class="pb-4 font-bold leading-[16px] text-orange"
    :class="{ 'text-white opacity-25': chill }"
    :style="`font-size: ${sizePx}px`"
  >
    {{ character }}
  </div>
</template>

<script lang="ts" setup>
import { computed, onBeforeUnmount, onMounted, ref, toRefs } from "vue";
import { getIconSizePx, IconSize } from "../lib/icons";

const props = withDefaults(
  defineProps<{
    chill?: boolean;
    size?: IconSize;
  }>(),
  { size: "xl" }
);
const { chill, size } = toRefs(props);

const sizePx = computed(() => getIconSizePx(size.value));

const CHARACTERS = ["◴", "◷", "◶", "◵"];

const characterIndex = ref(0);
let intervalId: number;

function cycleCharacter() {
  if (characterIndex.value === CHARACTERS.length - 1) {
    characterIndex.value = 0;
  } else {
    characterIndex.value += 1;
  }
}

const character = computed(() => CHARACTERS[characterIndex.value]);

onMounted(() => (intervalId = setInterval(cycleCharacter, 200)));
onBeforeUnmount(() => clearInterval(intervalId));
</script>
