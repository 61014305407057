<template>
  <Chart :spec="spec" @select="handleSelect" />
</template>

<script lang="ts" setup>
import {
  generateValue,
  generatorName,
  oversimplifyValues,
  TimeDistributionVisualization,
  visualizationTheme,
} from "@/reader/lib/visualization";
import { ComputedRef, Ref, computed, inject, toRefs } from "vue";
import * as vega from "vega";
import Chart from "@/common/components/Chart.vue";
import { DarkMode } from "@/common/lib/keys";
import {
  DatetimeValue,
  FloatValue,
  GraphValue,
  GraphValueType,
  stringifyValue,
  toNative,
} from "@/common/lib/value";
import { DateTime, DateTimeUnit } from "luxon";
import { compact, find, isString, mapValues } from "lodash";
import { filterValueType, PropertyOpType } from "@/common/lib/derived";
import { UseQueryResult } from "@/reader/composables/useQuery";

const props = defineProps<{
  visualization: TimeDistributionVisualization;
  results: UseQueryResult[];
  width?: number;
  height?: number;
}>();
const { visualization, results, width, height } = toRefs(props);

const emit = defineEmits<{
  select: [alias: string, value: [GraphValue, GraphValue]];
}>();

const darkMode = inject(DarkMode) as Ref<boolean>;

interface Datum {
  time: Date;
  value: number;
  category?: string;
  tooltip: Record<string, string>;
}

const data = computed(function () {
  const config = visualization.value.config;
  const query = visualization.value.query;
  const timeName = generatorName(config.time, query);
  const valueName = generatorName(config.value, query);
  return compact(
    results.value.map(function (row): Datum | null {
      const values = mapValues(row.valuesByAlias, (v) => oversimplifyValues(v));
      if (values[config.time] == null || values[config.value] == null) return null;
      const time = toNative(values[config.time] as DatetimeValue);
      const value = values[config.value] as FloatValue;
      const category =
        config.category == null
          ? null
          : stringifyValue(generateValue(config.category, row, query)?.formattedValue);
      const tooltip: Record<string, string> = {
        [timeName]: time.toLocaleString(DateTime.DATE_SHORT),
      };
      if (category != null) {
        tooltip[generatorName(config.category!, query)] = category;
      }
      tooltip[valueName] = stringifyValue(generateValue(config.value, row, query)?.formattedValue);
      return {
        time: time.toJSDate(),
        value: toNative(value),
        category: category ?? undefined,
        tooltip,
      };
    })
  );
});

// Sets vega's time unit by peeking at the property def for our time buckets
// This determines the width of each time bucket bar
const timeUnit = computed(function () {
  const prop = timeProp();
  if (prop == null || isString(prop) || prop.op !== PropertyOpType.DateTrunc) return "day";
  return (
    {
      YEAR: "year",
      QUARTER: "quarter",
      MONTH: "month",
      WEEK: "week",
      DAY: "day",
      HOUR: "hours", // note that some of these are plural
      MINUTE: "minutes",
      SECOND: "seconds",
      MILLISECOND: "milliseconds",
    }[prop.bucket_size.toUpperCase()] ?? "day"
  );
});

const spec: ComputedRef<vega.Spec> = computed(function () {
  const theme = visualizationTheme(darkMode.value);
  const isStacked = visualization.value.config.category != null;
  const brushCollideExpr = `isArray(brush) && (brush[0] != brush[1]) && collide([scale('x', datum.time), scale('x', utcOffset('${timeUnit.value}', datum.time, 1))], brush)`;
  let rectAttributes;
  if (isStacked) {
    rectAttributes = {
      fill: { scale: "color", field: "category" },
      opacity: { signal: `${brushCollideExpr} ? 0.6 : 1` },
    };
  } else {
    rectAttributes = {
      fill: { signal: `${brushCollideExpr} ? "${theme.selectedDatum}" : "${theme.datum}"` },
    };
  }
  const spec: vega.Spec = {
    width: (width.value ?? 370) - 10,
    height: (height.value ?? 400) - 10,
    padding: 5,
    autosize: "fit",
    data: [
      {
        name: "table",
        values: data.value,
        transform: [
          {
            type: "stack",
            groupby: ["time"],
            sort: { field: "category" },
            field: "value",
          },
        ],
      },
    ],
    signals: [
      {
        name: "brush",
        value: 0,
        on: [
          {
            events: "pointerdown",
            update: "[x(), x()]",
          },
          {
            events: "[pointerdown, window:pointerup] > window:pointermove!",
            update: "[brush[0], clamp(x(), 0, width)]",
          },
          {
            events: { signal: "delta" },
            update: "clampRange([anchor[0] + delta, anchor[1] + delta], 0, width)",
          },
        ],
      },
      {
        name: "anchor",
        value: null,
        on: [{ events: "@brush:pointerdown", update: "slice(brush)" }],
      },
      {
        name: "xdown",
        value: 0,
        on: [{ events: "@brush:pointerdown", update: "x()" }],
      },
      {
        name: "delta",
        value: 0,
        on: [
          {
            events: "[@brush:pointerdown, window:pointerup] > window:pointermove!",
            update: "x() - xdown",
          },
        ],
      },
      {
        name: "selection",
        value: null,
        on: [{ events: "pointerup", update: "[invert('x', brush[0]), invert('x', brush[1])]" }],
      },
    ],
    scales: [
      {
        name: "x",
        type: "time",
        range: "width",
        domain: { data: "table", field: "time" },
        domainMax: {
          signal: `utcOffset('${timeUnit.value}', extent(pluck(data('table'), 'time'))[1], 1)`,
        },
      },
      {
        name: "y",
        type: "linear",
        range: "height",
        domain: { data: "table", field: "y1" },
        nice: true,
        zero: true,
      },
      {
        name: "color",
        type: "ordinal",
        range: { scheme: "category20" },
        domain: { data: "table", field: "category" },
      },
    ],
    axes: [
      {
        orient: "bottom",
        scale: "x",
        domain: false,
        ticks: false,
        labelPadding: 5,
        labelColor: theme.label,
      },
      {
        scale: "y",
        orient: "left",
        labelColor: theme.label,
        labelLimit: 120,
        labelPadding: 5,
      },
    ],
    marks: [
      {
        type: "rect",
        name: "brush",
        encode: {
          enter: {
            y: { signal: "range('y')[0]" },
            y2: { signal: "range('y')[1]" },
            fill: { value: theme.brush },
            fillOpacity: { value: 0.3 },
          },
          update: {
            x: { signal: "brush[0]" },
            x2: { signal: "brush[1]" },
          },
        },
      },
      {
        type: "rect",
        from: { data: "table" },
        encode: {
          update: {
            ...rectAttributes,
            x: { signal: 'scale("x", datum.time) + 1' },
            y: { scale: "y", field: "y0" },
            y2: { scale: "y", field: "y1" },
            x2: { signal: `scale("x", utcOffset('${timeUnit.value}', datum.time, 1))` },
            tooltip: { signal: "datum.tooltip" },
          },
        },
      },
    ],
    legends: isStacked
      ? [
          {
            fill: "color",
            encode: {
              symbols: {
                name: "legendSymbol",
                enter: {
                  strokeWidth: { value: 2 },
                  size: { value: 200 },
                },
              },
              labels: {
                name: "legendLabel",
                update: {
                  fontSize: { value: 12 },
                },
              },
            },
          },
        ]
      : [],
  };
  return spec;
});

function handleSelect(selected: unknown) {
  const range = selected as [Date, Date];
  const dateTimeRange = [DateTime.fromJSDate(range[0]), DateTime.fromJSDate(range[1])];
  if (dateTimeRange[0].equals(dateTimeRange[1])) return;
  if (dateTimeRange[0].toMillis() > dateTimeRange[1].toMillis()) dateTimeRange.reverse();
  // This doesn't allow selecting anything more granular than a day. At some point we
  // will have to improve on that
  let unit = timeUnit.value;
  if (!["year", "quarter", "month", "week", "day"].includes(unit)) unit = "day";
  const dateOnly = filterValueType(timeProp()) === GraphValueType.Date;
  const rangeValues = [
    dateTimeRange[0].startOf(unit as DateTimeUnit),
    dateTimeRange[1].endOf(unit as DateTimeUnit),
  ].map((dt) => ({
    _type: dateOnly ? GraphValueType.Date : GraphValueType.Datetime,
    value: dateOnly ? dt.toISODate() : dt.toISO(),
  }));
  emit("select", visualization.value.config.time, rangeValues as [GraphValue, GraphValue]);
}

function timeProp() {
  return find(visualization.value.query.columns, {
    alias: visualization.value.config.time,
  })!.property_type;
}
</script>
