<template>
  <div
    v-if="currentWarehouse && currentWarehouse.state !== 'RUNNING'"
    class="flex flex-row items-center gap-10"
  >
    <Spinner v-if="spinner" />
    <span>Warehouse "{{ currentWarehouse.name }}" is {{ currentWarehouse.state }}</span>
  </div>
</template>

<script lang="ts" setup>
import { useTimer } from "@/common/composables/useTimer";
import { environment } from "@/common/environments/environmentLoader";
import { useWorkspaceStore } from "@/common/stores/workspaceStore";
import { storeToRefs } from "pinia";
import { watch } from "vue";
import Spinner from "./Spinner.vue";

defineProps<{ spinner?: boolean }>();
const workspaceStore = useWorkspaceStore();
const { currentWarehouse } = storeToRefs(workspaceStore);

const timer = useTimer(environment.requireNumber("WAREHOUSE_LOADING_CHECK_WAIT_MS"));

watch(
  () => currentWarehouse?.value,
  (warehouse) => {
    if (warehouse?.state !== "RUNNING") {
      timer.start(async () => await workspaceStore.refreshCurrentWarehouse());
    } else {
      timer.stop();
    }
  },
  { immediate: true }
);
</script>
