import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height", "view-box"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    ref: "svgEl",
    width: $setup.width,
    height: $setup.height,
    "view-box": [0, 0, $setup.width, $setup.height]
  }, null, 8 /* PROPS */, _hoisted_1))
}