<template>
  <div class="m-10">Count of concepts or groups</div>
</template>

<script lang="ts" setup>
import { CountPropertyType, PropertyOpType } from "@/common/lib/derived";
import { onMounted } from "vue";

const OP = PropertyOpType.Count;

const model = defineModel<CountPropertyType | null>({ required: true });

onMounted(() => (model.value = { op: OP, approx: false }));
</script>
