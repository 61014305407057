import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "relative inline-block h-[14px] w-[22px]" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = {
  key: 0,
  class: "ml-20 mt-10 text-gray-700 dark:text-gray-200"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["flex flex-row items-center gap-5", { 'opacity-50': $props.disabled }])
    }, [
      _createElementVNode("label", _hoisted_1, [
        _withDirectives(_createElementVNode("input", {
          type: "checkbox",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.value) = $event)),
          disabled: $props.disabled,
          class: "size-0 opacity-0"
        }, null, 8 /* PROPS */, _hoisted_2), [
          [_vModelCheckbox, $setup.value]
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["track absolute inset-0 cursor-pointer rounded-full transition duration-300", { 'bg-orange': $setup.value, 'bg-gray-500 dark:bg-gray-700': !$setup.value }])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["knob absolute bottom-4 left-4 size-[6px] rounded-full bg-white transition duration-300 dark:bg-gray-800", { 'translate-x-10': $setup.value }])
          }, null, 2 /* CLASS */)
        ], 2 /* CLASS */)
      ]),
      _createElementVNode("label", {
        class: _normalizeClass(["pb-1 text-gray-500", { 'text-base font-bold': $props.size === 'l', 'text-xs': $props.size === 's' }])
      }, _toDisplayString($props.label), 3 /* TEXT, CLASS */),
      ($setup.slots.help)
        ? (_openBlock(), _createBlock($setup["Icon"], {
            key: 0,
            class: "ml-5 opacity-50",
            name: "help",
            color: "white",
            size: "m",
            onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.toggleDescription()))
          }))
        : _createCommentVNode("v-if", true)
    ], 2 /* CLASS */),
    ($setup.showDescription)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "help")
        ]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}