import { EventType, Layout } from "webcola";

const MAX_TICKS = 30; // don't keep trying to converge longer than this
const TICK_TIME = 16; // milliseconds. decrease for faster/choppier animation, increase for slower/smoother

export class MetagraphLayout extends Layout {
  private previousTimestamp: number | null = null;
  private remainingTicks = 0;
  public onUpdateView: (() => void) | null = null;

  private animate() {
    requestAnimationFrame((timestamp: number) => {
      this.remainingTicks -= 1;
      let backlog = 1;
      if (this.previousTimestamp != null) {
        backlog = Math.ceil((timestamp - this.previousTimestamp) / TICK_TIME);
        if (backlog > this.remainingTicks) backlog = this.remainingTicks;
      }
      this.previousTimestamp = timestamp;
      for (; backlog > 0; backlog--) {
        if (this.tick()) {
          this.remainingTicks = 0;
          break; // Layout has converged; stop ticking
        }
      }
      this.onUpdateView?.(); // Only update view once after backlog has cleared
      if (this.remainingTicks > 0) this.animate();
    });
  }

  public kick() {
    this.remainingTicks = MAX_TICKS;
    this.animate();
  }
}

// A version designed for offscreen rendering rather than animation
export class OffscreenMetagraphLayout extends Layout {
  private animate(remainingIters: number) {
    if (remainingIters < 1) {
      this.trigger({ type: EventType.end, alpha: 0 });
      return;
    }
    if (!this.tick()) this.animate(remainingIters - 1);
  }

  public kick() {
    this.animate(MAX_TICKS);
  }
}
