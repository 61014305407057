import useKnowledge from "@/common/composables/useKnowledge";
import { propertyName, propertyValueType } from "@/common/lib/derived";
import { formatValue, noOpFormatValue, ValueWithFormattedValue } from "@/common/lib/format";
import { GraphCompoundValue } from "@/common/lib/graph";
import {
  COMPOSITE_PROPERTY_VALUE_TYPE,
  ConceptKnowledgeRef,
  PropertyKnowledgeRef,
  VALUE_TYPE_BASE_PROPERTY_TYPES,
} from "@/common/lib/knowledge";
import { findDeepColumnByAlias, Query, QueryPathNode } from "@/common/lib/query";
import { GraphValue, GraphValueType, isValue, toValue } from "@/common/lib/value";
import { every, isEqual, isString, last, mapValues } from "lodash";
import * as vega from "vega";
import { UseQueryResult } from "../composables/useQuery";
import { conceptPropertyValueDisplay, readerConceptTitle } from "./concept";

// Basic types and utilities for the use of visualizations

export enum ValueGeneratorType {
  Property = "property",
  Title = "title",
}

interface BaseValueGenerator {
  type: ValueGeneratorType;
}

export interface PropertyValueGenerator extends BaseValueGenerator {
  type: ValueGeneratorType.Property;
  alias: string;
  transformer?: string; // Temporary - hold your nose and take a look at format.ts' TRANSFORMERS
}

interface TitleValueGenerator extends BaseValueGenerator {
  type: ValueGeneratorType.Title;
  path?: QueryPathNode[]; // If not specified, root concept is used
}

type NormalizedValueGenerator = PropertyValueGenerator | TitleValueGenerator;

// If you specify a string, it should be an alias (and you get a PropertyValueGenerator)
export type ValueGenerator = NormalizedValueGenerator | string;

export type ValueSet = Record<string, ValueWithFormattedValue | null>;

export function emptyFormattedValue(): ValueWithFormattedValue {
  return {
    originalValue: toValue(""),
    formattedValue: toValue("(None)"),
  };
}

export function generateValue(
  generator: ValueGenerator,
  result: UseQueryResult,
  query: Query
): ValueWithFormattedValue | null {
  generator = normalizeGenerator(generator);
  switch (generator.type) {
    case ValueGeneratorType.Property: {
      const value = oversimplifyValues(result.valuesByAlias[generator.alias] ?? []);
      if (value == null) return null;
      return generatePropertyValue(generator, value, query);
    }
    case ValueGeneratorType.Title: {
      const concept =
        generator.path != null
          ? result.conceptsByPath.find((cbp) => isEqual(cbp.path, generator.path))?.concepts[0]
          : result.root;
      if (concept == null) return null;
      const title = readerConceptTitle(concept);
      if (title == null) return null;
      return noOpFormatValue(toValue(title));
    }
  }
}

export function generatePropertyValue(
  generator: ValueGenerator,
  value: GraphValue,
  query: Query
): ValueWithFormattedValue {
  generator = normalizeGenerator(generator) as PropertyValueGenerator;
  const propType =
    findDeepColumnByAlias(query, generator.alias)?.property_type ??
    VALUE_TYPE_BASE_PROPERTY_TYPES[value._type];
  return formatValue(propType, value, generator.transformer);
}

export function generateValueSets(
  generators: Record<string, ValueGenerator>,
  results: UseQueryResult[],
  query: Query,
  requiredValues: string[] = []
): ValueSet[] {
  // Perhaps warn or report about invalid value sets
  return results
    .map((row) => generateValues(generators, row, query))
    .filter((valueSet) => every(requiredValues, (rv) => valueSet[rv] != null));
}

export function generateValues(
  generators: Record<string, ValueGenerator>,
  result: UseQueryResult,
  query: Query
) {
  return mapValues(generators, (generator) => generateValue(generator, result, query));
}

// Gives the value type that a generator will return
export function generatorOutputType(
  generator: ValueGenerator,
  query: Query
): GraphValueType | null {
  generator = normalizeGenerator(generator);
  switch (generator.type) {
    case ValueGeneratorType.Property: {
      const propDef = findDeepColumnByAlias(query, generator.alias)?.property_type;
      if (propDef == null) return null;
      const valueType = propertyValueType(propDef);
      if (valueType === COMPOSITE_PROPERTY_VALUE_TYPE) return null;
      return valueType;
    }
    case ValueGeneratorType.Title:
      return GraphValueType.String;
  }
}

// Tries to come up with a reasonable label for a generator's output
export function generatorName(generator: ValueGenerator, query: Query): string {
  generator = normalizeGenerator(generator);
  switch (generator.type) {
    case ValueGeneratorType.Property: {
      const propDef = findDeepColumnByAlias(query, generator.alias)?.property_type;
      if (propDef == null) return "Value";
      return propertyName(propDef, undefined); // TODO Probably include concept
    }
    case ValueGeneratorType.Title: {
      const conceptType = generator.path
        ? last(generator.path)!.concept_type
        : query.root_concept_type;
      return useKnowledge().typeLabel(conceptType);
    }
  }
}

export function generatorAlias(generator: ValueGenerator) {
  generator = normalizeGenerator(generator);
  if (generator.type === ValueGeneratorType.Property) {
    return generator.alias;
  } else {
    return null;
  }
}

// Returns a function that applies colors to results.
// coloredAlias is the alias that represents categories to be colored.
// If all values should have the same color, specify undefined.
// Looks for a color designated for this concept-type/property-type/value,
// and failing that, uses a color from a repeating category color scheme
// (provided useArbitraryColors is true)
export function colorizer(
  query: Query,
  generators: Record<string, ValueGenerator>,
  darkMode: boolean,
  coloredAlias: string | undefined,
  useArbitraryColors: boolean = true
) {
  const schemeColor = (index: number) => (vega.scheme("category10") as string[])[index % 10];
  const theme = visualizationTheme(darkMode);
  const seenValues: (GraphValue | GraphCompoundValue)[] = [];
  const generator = coloredAlias == null ? null : normalizeGenerator(generators[coloredAlias]);
  let conceptType: ConceptKnowledgeRef;
  let propertyType: PropertyKnowledgeRef;
  if (generator?.type === ValueGeneratorType.Property) {
    const propDef = findDeepColumnByAlias(query, generator.alias);
    if (propDef != null && isString(propDef.property_type)) {
      propertyType = propDef.property_type;
      if (propDef.path == null) {
        conceptType = query.root_concept_type;
      } else {
        conceptType = last(propDef.path)!.concept_type;
      }
    }
  }

  return function (valueSet: ValueSet) {
    if (coloredAlias == null) return theme.datum;
    const value = valueSet[coloredAlias]?.originalValue;
    if (value == null) return theme.nullDatum;
    if (conceptType != null) {
      const valueDisplay = conceptPropertyValueDisplay(conceptType, propertyType, value);
      if (valueDisplay?.color != null) return valueDisplay.color;
    }
    let index = seenValues.findIndex((v) => isEqual(value, v));
    if (index == -1) {
      seenValues.push(value);
      index = seenValues.length - 1;
    }
    if (useArbitraryColors) {
      return schemeColor(index);
    } else {
      return theme.datum;
    }
  };
}

// Hardcoded for now, but we know this is going to change at runtime
// These ought to match a color in tailwind.config.js
export function visualizationTheme(darkMode: boolean) {
  return {
    datum: "rgba(31, 119, 180, 1)",
    nullDatum: "#EEEEEE",
    label: darkMode ? "#FFFFFF" : "#191919",
    axis: darkMode ? "#FFFFFF" : "#191919",
    brush: "#DDDDDD",
    compareWorse: "#720000",
    compareBetter: "#40855A",
  };
}

function normalizeGenerator(generator: ValueGenerator): NormalizedValueGenerator {
  if (isString(generator)) {
    return { type: ValueGeneratorType.Property, alias: generator };
  } else {
    return generator;
  }
}

function oversimplifyValues(values: (GraphValue | GraphCompoundValue)[]) {
  if (values.length != 1) return null;
  if (!isValue(values[0])) return null; // Conveniently ignoring compound values for now
  return values[0] as GraphValue;
}
