// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../node_modules/vue-resize/dist/vue-resize.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "[data-v-6cc27b7f]:root {\n  --Action-Bar-Dark: #2c2c2c;\n  --Black: #191919;\n  --Canvas: #222222;\n  --CT-Orange: #f75e0e;\n  --Dark-Gray: #474747;\n  --Light-Gray: #e8e8e8;\n  --Medium-Gray: #8a8a8a;\n  --White: #ffffff;\n}\n.resize-wrapper[data-v-6cc27b7f] {\n  position: relative;\n  display: flex;\n  flex-flow: row nowrap;\n}\n.bar-horiz[data-v-6cc27b7f],\n.bar-vert[data-v-6cc27b7f] {\n  position: absolute;\n  background: #2c2c2c;\n  z-index: 2;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.bar-horiz > span[data-v-6cc27b7f],\n.bar-vert > span[data-v-6cc27b7f] {\n  pointer-events: none;\n  background-color: #535353;\n  border-radius: 100%;\n  width: 4px;\n  height: 4px;\n}\n.bar-horiz[data-v-6cc27b7f] {\n  width: 5px;\n  height: 100%;\n  cursor: ew-resize;\n  flex-flow: column nowrap;\n}\n.bar-horiz > span[data-v-6cc27b7f] {\n  margin: 2px 0;\n}\n.bar-vert[data-v-6cc27b7f] {\n  height: 5px;\n  width: 100%;\n  cursor: ns-resize;\n  flex-flow: row nowrap;\n}\n.bar-vert > span[data-v-6cc27b7f] {\n  margin: 0 2px;\n}\n.resize-content[data-v-6cc27b7f] {\n  min-width: 100%;\n  min-height: 100%;\n  max-height: 100%;\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
