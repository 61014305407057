import { httpClient as axios } from "@/common/http/http";
import {
  Async,
  asyncFailed,
  asyncHasValue,
  asyncInProgress,
  asyncNotStarted,
  asyncSucceeded,
  asyncValue,
} from "@/common/lib/async";
import { underlyingPropertyTypes } from "@/common/lib/derived";
import { convertRequestFormat } from "@/common/lib/derivedV2";
import {
  AliasLocations,
  FetchNConcept,
  FetchNPropertySet,
  FetchNResponse,
  RootAndNeighborRefs,
} from "@/common/lib/fetchApi";
import { GraphCompoundValue, GraphConcept } from "@/common/lib/graph";
import { BASE_CONCEPT_TYPE } from "@/common/lib/knowledge";
import { CTMap } from "@/common/lib/map";
import { allQueryBranches, Query, QueryPathNode } from "@/common/lib/query";
import { expandQuery } from "@/common/lib/queryToFetch";
import { GraphValue } from "@/common/lib/value";
import { AxiosResponse } from "axios";
import { compact, isEqual, isString, isUndefined, omitBy, uniqWith } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { computed, Ref, ref, watchEffect } from "vue";

interface ConceptsByPath {
  path: QueryPathNode[];
  concepts: GraphConcept[];
}

export interface UseQueryResult {
  valuesByAlias: FetchNPropertySet;
  root: GraphConcept;
  conceptsByPath: ConceptsByPath[];
}

export default function useQuery(
  module: string,
  getQuery: () => Query,
  map?: () => CTMap,
  ids?: { page_id?: string; widget_key?: string },
  disabled?: () => boolean
) {
  const queryResults: Ref<Async<FetchNResponse>> = ref(asyncInProgress());
  let query: Query;
  let latestQueryId = ""; // Guards against query races by ensuring only the latest result gets loaded
  let aliasLocations: AliasLocations = {};

  watchEffect(async function () {
    if (disabled?.()) {
      queryResults.value = asyncNotStarted();
      return;
    }
    const ourQueryId = uuidv4();
    latestQueryId = ourQueryId;
    query = getQuery(); // Important to call this just once, as it may be nondeterministic due to uuids
    queryResults.value = asyncInProgress("Loading your data...");
    const expandedQuery = expandQuery(query);
    aliasLocations = expandedQuery[1];
    const request = { ...expandedQuery[0], map: map?.() };
    const requestV2 = convertRequestFormat(request);
    const params = ids;
    let response: AxiosResponse<FetchNResponse>;
    try {
      response = await axios.post(`/api/projects/${module}/query`, requestV2, { params });
    } catch (error) {
      queryResults.value = asyncFailed("Sorry, we couldn't load your data.");
      return;
    }
    if (latestQueryId === ourQueryId) queryResults.value = asyncSucceeded(response.data);
  });

  function rootConcept() {
    if (!asyncHasValue(queryResults.value)) return emptyConcept();
    const results = asyncValue(queryResults.value)!;
    const rootId = results.paths[0].root_id;
    return dataToConcept(results.data[rootId]);
  }

  function rootConcepts() {
    if (!asyncHasValue(queryResults.value)) return [];
    const results = asyncValue(queryResults.value)!;
    return results.paths.map((path) => dataToConcept(results.data[path.root_id]));
  }

  const results = computed(function (): UseQueryResult[] {
    if (!asyncHasValue(queryResults.value)) return [];
    // Get a list of unique paths and where they can be found
    const pathLocs = uniqWith(
      query.columns
        .filter((col) => col.path != null)
        .map((col) => ({ path: col.path!, loc: aliasLocations[col.alias] })),
      (pl1, pl2) => isEqual(pl1.path, pl2.path)
    );
    const results = asyncValue(queryResults.value)!;
    return results.paths.map(function (refs: RootAndNeighborRefs) {
      const valuesByAlias: Record<string, (GraphValue | GraphCompoundValue)[]> = {};
      for (const branch of allQueryBranches(query)) {
        for (const column of branch.columns) {
          const loc = aliasLocations[column.alias];
          if (loc == null) {
            valuesByAlias[column.alias] = results.data[refs.root_id].properties[column.alias];
          } else {
            const neighborhoods = refs[loc.neighborhood] ?? [];
            valuesByAlias[column.alias] = neighborhoods.flatMap(
              (neigh) => results.data[neigh[loc.position]].properties[column.alias] ?? []
            );
          }
        }
      }

      const conceptsByPath: ConceptsByPath[] = [];
      for (const { path, loc } of pathLocs) {
        const neighborhoods = refs[loc.neighborhood] ?? [];
        conceptsByPath.push({
          path,
          concepts: neighborhoods.flatMap((n) => dataToConcept(results.data[n[loc.position]])),
        });
      }

      return {
        valuesByAlias: omitBy(valuesByAlias, isUndefined),
        root: dataToConcept(results.data[refs.root_id]),
        conceptsByPath,
      };
    });
  });

  const isDone = computed(() => asyncHasValue(queryResults.value));

  const isEmpty = computed(() => asyncValue(queryResults.value)!.paths.length == 0);

  function dataToConcept(data: FetchNConcept): GraphConcept {
    return {
      id: "",
      type: data.concept_type,
      properties: Object.entries(data.properties).flatMap(function ([alias, values]) {
        return compact(
          values.map(function (value) {
            const propDef = query.columns.find((c) => c.alias === alias)!.property_type;
            if (!isString(propDef)) return null; // Leave derived properties out of this
            return { id: "", type: underlyingPropertyTypes(propDef)[0], value };
          })
        );
      }),
    };
  }

  function emptyConcept(): GraphConcept {
    return { id: "", type: BASE_CONCEPT_TYPE, properties: [] };
  }

  return {
    queryResults,
    results,
    rootConcept,
    rootConcepts,
    isDone,
    isEmpty,
  };
}
