import { normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["vSelect"], {
    modelValue: $setup.model,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.model) = $event)),
    class: _normalizeClass(["select", { 'light-select': !$setup.darkMode }]),
    options: $setup.options,
    getOptionLabel: $setup.groupByLabel,
    clearable: false,
    placeholder: "Choose a Property"
  }, null, 8 /* PROPS */, ["modelValue", "class", "options"]))
}