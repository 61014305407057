import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-fit-content flex justify-center" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createElementVNode("img", {
      src: $setup.src,
      style: _normalizeStyle({ width: `${$setup.sizePx}px`, height: `${$setup.sizePx}px` }),
      class: _normalizeClass(["pointer-events-none block max-w-none", $setup.colorClass])
    }, null, 14 /* CLASS, STYLE, PROPS */, _hoisted_2)
  ]))
}