<template>
  <img :src="path" />
</template>

<style lang="scss" scoped>
img {
  max-width: 100%;
  text-align: center;
}
</style>

<script lang="ts" setup>
import { blobPath } from "@/common/lib/api";
import { BytesValue } from "@/common/lib/value";
import { computed, toRefs } from "vue";

const props = defineProps<{ value: BytesValue }>();

const { value } = toRefs(props);

const path = computed(() => blobPath(value.value));
</script>
