import useKnowledge from "@/common/composables/useKnowledge";
import { Graph } from "@/common/lib/graph";
import { parseIconName } from "@/common/lib/icons";
import { OffscreenMetagraphLayout } from "@/common/lib/webcola";
import { extent } from "d3";
import { fromPairs, uniq } from "lodash";

const THUMB_WIDTH = 496; // px
const THUMB_HEIGHT = 400;
const CONCEPT_RADIUS = 24;
const ICON_SIZE = 30;
const MAX_CONCEPT_SCALE = 2;
const EXTRA_MARGIN = 10;
const MARGIN = CONCEPT_RADIUS * MAX_CONCEPT_SCALE + EXTRA_MARGIN;
const SAFE_WIDTH = THUMB_WIDTH - MARGIN * 2;
const SAFE_HEIGHT = THUMB_HEIGHT - MARGIN * 2;

export async function createMetagraphThumbnail(
  metagraph: Graph,
  conceptColors: Record<string, string>
) {
  const canvas = new OffscreenCanvas(THUMB_WIDTH, THUMB_HEIGHT);
  const ctx = canvas.getContext("2d");
  const { getConceptIconName } = useKnowledge();
  const { positions, scale } = await layoutGraph(metagraph);
  const conceptIcons = fromPairs(
    uniq(metagraph.concepts.map((c) => c.type)).map((conceptType) => [
      conceptType,
      getConceptIconName(conceptType),
    ])
  );
  const images = await loadConceptTypeIcons(uniq(Object.values(conceptIcons)));
  if (ctx != null) {
    ctx.strokeStyle = "#444444";
    ctx.lineWidth = 4;
    for (const link of metagraph.links) {
      const from = positions[link.from];
      const to = positions[link.to];
      ctx.beginPath();
      ctx.moveTo(from.x, from.y);
      ctx.lineTo(to.x, to.y);
      ctx.stroke();
    }
    const iconSize = ICON_SIZE * scale;
    const conceptRadius = CONCEPT_RADIUS * scale;
    for (const [conceptId, pos] of Object.entries(positions)) {
      const concept = metagraph.concepts.find((c) => c.id === conceptId)!;
      ctx.fillStyle = conceptColors[concept.type];
      ctx.beginPath();
      ctx.ellipse(pos.x, pos.y, conceptRadius, conceptRadius, 0, 0, 2 * Math.PI);
      ctx.fill();
      const icon = images[conceptIcons[concept.type]];
      if (icon != null) {
        ctx.filter = "brightness(0)";
        ctx.drawImage(icon, pos.x - iconSize / 2, pos.y - iconSize / 2, iconSize, iconSize);
        ctx.filter = "none";
      }
    }
  }
  const blob = await canvas.convertToBlob();
  const fr = new FileReader();
  const promise: Promise<string> = new Promise(function (resolve) {
    fr.addEventListener("load", () => resolve(fr.result as string), false);
  });
  fr.readAsDataURL(blob);
  return promise;
}

type ImageSet = Record<string, HTMLImageElement | null>;

async function loadConceptTypeIcons(iconNames: string[]): Promise<ImageSet> {
  const images: Record<string, HTMLImageElement | null> = {};
  return await new Promise(function (resolve) {
    function imgFinished(name: string, img: HTMLImageElement | null) {
      images[name] = img;
      if (Object.keys(images).length === iconNames.length) resolve(images);
    }
    for (const name of iconNames) {
      const iconSrc = parseIconName(name);
      const img = new Image(ICON_SIZE, ICON_SIZE);
      img.onload = () => imgFinished(name, img);
      img.onerror = () => imgFinished(name, null);
      img.crossOrigin = "anonymous";
      img.src = iconSrc;
    }
  });
}

type ConceptPositions = Record<string, { x: number; y: number }>;

interface GraphLayout {
  positions: ConceptPositions;
  scale: number;
}

async function layoutGraph(metagraph: Graph) {
  const layout = new OffscreenMetagraphLayout();
  const promise: Promise<GraphLayout> = new Promise(function (resolve) {
    layout.on("end", function () {
      if (layout.nodes().length === 1) {
        // Special case for a single node
        const conceptId = metagraph.concepts[layout.nodes()[0].index!].id;
        resolve({
          positions: { [conceptId]: { x: THUMB_WIDTH / 2, y: THUMB_HEIGHT / 2 } },
          scale: MAX_CONCEPT_SCALE,
        });
      } else {
        const [minX, maxX] = extent(layout.nodes().map((node) => node.x)) as [number, number];
        const [minY, maxY] = extent(layout.nodes().map((node) => node.y)) as [number, number];
        const scaleX = SAFE_WIDTH / (maxX - minX);
        const scaleY = SAFE_HEIGHT / (maxY - minY);
        const offsetX = MARGIN - minX * scaleX;
        const offsetY = MARGIN - minY * scaleY;
        const positions: ConceptPositions = {};
        for (const node of layout.nodes()) {
          const conceptId = metagraph.concepts[node.index!].id;
          positions[conceptId] = {
            x: offsetX + node.x * scaleX,
            y: offsetY + node.y * scaleY,
          };
        }
        resolve({ positions, scale: Math.min(scaleX, scaleY, MAX_CONCEPT_SCALE) });
      }
    });
  });
  layout.avoidOverlaps(true);
  layout.nodes(metagraph.concepts.map((_, index) => ({ width: 50, height: 50, index })));
  layout.links(
    metagraph.links.map((link) => {
      return {
        source: metagraph.concepts.findIndex((c) => c.id === link.from),
        target: metagraph.concepts.findIndex((c) => c.id === link.to),
      };
    })
  );
  layout.jaccardLinkLengths(80);
  layout.start();
  return await promise;
}
