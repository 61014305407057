import useGraph from "@/common/composables/useGraph";
import { LinkDescriptor, linkPartner } from "@/common/lib/graph";
import { ConceptKnowledgeRef, ROLE_LINK_TYPE } from "@/common/lib/knowledge";
import { QueryPathNode } from "@/common/lib/query";
import { every, last, pick } from "lodash";
import { useExploreStore } from "../stores/explore";

export interface ExploreTreeNode extends QueryPathNode {
  elidedRoleType?: ConceptKnowledgeRef;
}

export type ExploreTreePath = ExploreTreeNode[];

export function childPaths(path: ExploreTreePath, elideRoles = true): ExploreTreePath[] {
  const exploreStore = useExploreStore();
  const { getLinksWith, getConceptsOfType, getConcept } = useGraph(() => exploreStore.metagraph);
  const conceptType = last(path)!.concept_type;
  const conceptId = getConceptsOfType(conceptType)[0].id;
  const links = getLinksWith(conceptId).filter(function (link) {
    const partner = linkPartner(link, conceptId);
    if (partner == null) return false;
    if (path.length > 1 && getConcept(partner).type === path[path.length - 2].concept_type)
      return false;
    return true;
  });
  return links.flatMap(function (link) {
    const concept = getConcept(linkPartner(link, conceptId));
    let linkDescriptor = LinkDescriptor.RelatedTo;
    if (link.type === ROLE_LINK_TYPE) {
      linkDescriptor = link.from === conceptId ? LinkDescriptor.AsA : LinkDescriptor.RoleOf;
    }
    const newNode = { concept_type: concept.type, link_descriptor: linkDescriptor };
    if (elideRoles) {
      // Peek one level deeper. If the current level seems to be an empty role concept,
      // leave it out and enrich the next level with information about it instead
      const elidablePaths = childPaths([...path, newNode], false);
      if (
        linkDescriptor !== LinkDescriptor.RoleOf &&
        (concept.properties ?? []).length === 0 &&
        elidablePaths.length > 0
      ) {
        const elidedPaths = elidablePaths.map(function (deeperPath) {
          const deeperNode = last(deeperPath);
          if (
            (linkDescriptor === LinkDescriptor.AsA &&
              deeperNode?.link_descriptor === LinkDescriptor.RelatedTo) ||
            (linkDescriptor === LinkDescriptor.RelatedTo &&
              deeperNode?.link_descriptor === LinkDescriptor.RoleOf)
          ) {
            return [...path, newNode, { ...deeperNode, elidedRoleType: newNode.concept_type }];
          }
          return null;
        });
        // If not every child can be represented this way, give up and show the node as usual
        if (every(elidedPaths, (ep) => ep != null)) return elidedPaths as ExploreTreePath[];
      }
    }

    return [[...path, newNode]];
  });
}

// Returns a set of ExploreTreePaths on the way to the provided path
export function treePathSteps(path: QueryPathNode[]): ExploreTreePath[] {
  const exploreStore = useExploreStore();
  if (exploreStore.query == null) return [];
  const paths: ExploreTreePath[] = [];
  for (let i = 0; i < path.length + 1; i++) {
    const section = path.slice(0, i);
    paths.push([{ concept_type: exploreStore.query.root_concept_type }, ...section]);
  }
  return paths;
}

// This turns a tree path (generated by childPaths) back into a QueryPath
export function expandTreePath(treePath: ExploreTreePath): QueryPathNode[] | undefined {
  if (treePath.length === 1) return undefined;
  return treePath.slice(1).map((element) => pick(element, "concept_type", "link_descriptor"));
}
