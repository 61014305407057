import { onMounted, onUnmounted } from "vue";

export function useResizeListener(onResize: () => void) {
  onMounted(() => {
    window.addEventListener("resize", onResize);
  });

  onUnmounted(() => {
    window.removeEventListener("resize", onResize);
  });
}
