<template>
  <div
    v-if="mode === 'create'"
    class="flex max-w-[450px] flex-col items-start gap-10 self-stretch px-10 py-0"
  >
    <div
      class="item-start flex flex-col justify-between gap-10 self-stretch text-lg text-gray-900 dark:text-gray-100"
    >
      <span> Select your data provider: </span>
      <div v-if="!selectedProvider" class="m-10 flex flex-col justify-between gap-10">
        <ProviderButton
          v-if="enabledProviders.includes('dbx')"
          img-src="/images/providers/dbx/databricks-wide.svg"
          height="40px"
          @click="selectedProvider = ProviderType.Databricks"
        />
        <ProviderButton
          v-if="enabledProviders.includes('snowflake')"
          img-src="/images/providers/snowflake/snowflake-wide.svg"
          height="50px"
          @click="selectedProvider = ProviderType.Snowflake"
        />
        <ProviderButton
          v-if="enabledProviders.includes('mysql')"
          img-src="/images/providers/mysql/mysql-wide.svg"
          height="100px"
          @click="selectedProvider = ProviderType.MySQL"
        />
        <ProviderButton
          v-if="enabledProviders.includes('starrocks')"
          img-src="/images/providers/starrocks/starrocks-wide.svg"
          height="35px"
          @click="selectedProvider = ProviderType.StarRocks"
        />
      </div>
      <div v-else class="m-10 flex flex-col justify-between gap-10">
        <ProviderButton
          v-if="selectedProvider === ProviderType.Databricks"
          img-src="/images/providers/dbx/databricks-wide.svg"
          height="40px"
          :static="true"
        />
        <ProviderButton
          v-if="selectedProvider === ProviderType.Snowflake"
          img-src="/images/providers/snowflake/snowflake-wide.svg"
          height="50px"
          :static="true"
        />
        <ProviderButton
          v-if="selectedProvider == ProviderType.MySQL"
          img-src="/images/providers/mysql/mysql-wide.svg"
          height="100px"
          :static="true"
        />
        <ProviderButton
          v-if="selectedProvider == ProviderType.StarRocks"
          img-src="/images/providers/starrocks/starrocks-wide.svg"
          height="35px"
          :static="true"
        />
      </div>
    </div>
    <div class="flex items-center justify-end gap-10 self-stretch">
      <div></div>
      <TextButton
        label="Cancel"
        @click="cancelEditWorkspace()"
        v-if="selectedProvider === undefined && workspacesState !== WorkspacesState.NoWorkspaces"
      />
    </div>
  </div>
  <DbxEditConnectionSettings
    v-if="selectedProvider === 'dbx'"
    :current-workspace="workspaceSettings"
    :mode="mode"
    @cancel="cancelEdit"
    @save-workspace="cancelEditWorkspace()"
  />
  <SnowflakeEditConnectionSettings
    v-if="selectedProvider === 'snowflake'"
    :current-workspace="workspaceSettings"
    :mode="mode"
    @cancel="cancelEdit"
    @save-workspace="cancelEditWorkspace()"
  />
  <MySqlEditConnectionSettings
    v-if="selectedProvider === 'mysql'"
    :current-workspace="workspaceSettings"
    :mode="mode"
    @cancel="cancelEdit"
    @save-workspace="cancelEditWorkspace()"
  />
  <StarRocksConnectionSettings
    v-if="selectedProvider === 'starrocks'"
    :current-workspace="workspaceSettings"
    :mode="mode"
    @cancel="cancelEdit"
    @save-workspace="cancelEditWorkspace()"
  />
</template>

<script lang="ts" setup>
import TextButton from "@/common/components/TextButton.vue";
import { ref, onMounted } from "vue";
import DbxEditConnectionSettings from "./DbxEditConnectionSettings.vue";
import SnowflakeEditConnectionSettings from "./SnowflakeEditConnectionSettings.vue";
import {
  useWorkspaceStore,
  FullWorkspace,
  WorkspacesState,
  ProviderType,
} from "@/common/stores/workspaceStore";
import ProviderButton from "./ProviderButton.vue";
import { storeToRefs } from "pinia";
import MySqlEditConnectionSettings from "./MySqlEditConnectionSettings.vue";
import { environment } from "@/common/environments/environmentLoader";
import StarRocksConnectionSettings from "./StarRocksConnectionSettings.vue";

const props = defineProps<{ mode: "edit" | "create"; workspaceId?: string }>();
const emits = defineEmits(["saveWorkspace"]);

const enabledProviders: string[] = environment.requireJson("PROVIDER_ENABLED_SET");

const selectedProvider = ref<ProviderType | undefined>();
const workspaceStore = useWorkspaceStore();
const { workspacesState } = storeToRefs(workspaceStore);

const workspaceSettings = ref<FullWorkspace>();

function cancelEdit() {
  if (props.mode === "edit") {
    cancelEditWorkspace();
  } else {
    selectedProvider.value = undefined;
  }
}

function cancelEditWorkspace() {
  emits("saveWorkspace");
}

onMounted(async () => {
  if (props.mode === "create") {
    return;
  }
  if (props.workspaceId) {
    const workspace = await workspaceStore.loadFullWorkspace(props.workspaceId);
    workspaceSettings.value = workspace;
    selectedProvider.value = workspace.metadata?.provider;
  }
});
</script>
