import { defineComponent as _defineComponent } from 'vue'
import { useExploreStore } from "@/reader/stores/explore";
import VisualizationConfig from "../viz-config/VisualizationConfig.vue";
import useQuery from "@/reader/composables/useQuery";
import { useAppStore } from "@/reader/stores/app";
import { computed, Ref, ref } from "vue";
import { ResizeObserver } from "vue-resize";
import { every, isArray, isUndefined, omitBy } from "lodash";
import { VISUALIZATION_CONFIG_DEFS } from "@/reader/lib/visualizationTypes";
import AsyncWrapper from "@/common/components/AsyncWrapper.vue";
import { propertyValueMenu } from "@/reader/lib/exploreMenus";
import { MenuItem } from "@/common/lib/menus";
import { findDeepColumnByAlias } from "@/common/lib/query";
import { formatValue } from "@/common/lib/format";
import { GraphValue } from "@/common/lib/value";
import { Dropdown } from "floating-vue";
import Menu from "@/common/components/Menu.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'Visualization',
  setup(__props, { expose: __expose }) {
  __expose();

const width = ref(0);
const height = ref(0);
const dropdownX = ref(0);
const dropdownY = ref(0);
const menuItems: Ref<MenuItem[]> = ref([]);
const containerEl: Ref<HTMLDivElement | null> = ref(null);
const dropdownShown = ref(false);

const appStore = useAppStore();
const exploreStore = useExploreStore();

const { isEmpty, results, queryResults, isDone } = useQuery(
  appStore.module!,
  () => exploreStore.query!
);

const isValid = computed(function () {
  const config = exploreStore.visualizationConfig;
  if (exploreStore.visualizationType == null || config == null) return false;
  const configDef = VISUALIZATION_CONFIG_DEFS[exploreStore.visualizationType];
  const requiredFields = configDef.controls.filter((c) => !c.optional).map((c) => c.key);
  return every(requiredFields, (f) => config[f] != null);
});

const vizComponent = computed(() => `visualization-${exploreStore.visualizationType}`);

const visualization = computed(() => ({
  type: exploreStore.visualizationType!,
  title: "",
  query: exploreStore.query!,
  config: omitBy(exploreStore.visualizationConfig!, isUndefined),
}));

function handleResize({ width: x, height: y }: { width: number; height: number }) {
  width.value = x;
  height.value = y;
}

function updateDropdownPosition(event: MouseEvent) {
  if (containerEl.value == null) return;
  const containerPos = containerEl.value.getBoundingClientRect();
  dropdownX.value = event.clientX - containerPos.x;
  dropdownY.value = event.clientY - containerPos.y;
}

function handleSelection(alias: string, value: GraphValue | [GraphValue, GraphValue] | null) {
  if (isArray(value)) {
    // TODO Support ranges
  } else if (value == null) {
    // TODO Support non-exists filtering
  } else {
    const column = findDeepColumnByAlias(exploreStore.query!, alias);
    const fvalue = formatValue(column!.property_type, value);
    menuItems.value = propertyValueMenu(fvalue.originalValue, fvalue.formattedValue, column);
    dropdownShown.value = true;
  }
}

const __returned__ = { width, height, dropdownX, dropdownY, menuItems, containerEl, dropdownShown, appStore, exploreStore, isEmpty, results, queryResults, isDone, isValid, vizComponent, visualization, handleResize, updateDropdownPosition, handleSelection, VisualizationConfig, get ResizeObserver() { return ResizeObserver }, AsyncWrapper, get Dropdown() { return Dropdown }, Menu }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})