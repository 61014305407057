import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.terms, (term, index) => {
    return (_openBlock(), _createElementBlock("div", {
      class: "mb-5 flex items-start",
      key: term.id
    }, [
      _createVNode($setup["DerivedPropertyDef"], {
        class: "basis-full",
        "parent-op": $props.parentOp,
        "model-value": term.term,
        "onUpdate:modelValue": (value) => $setup.setTerm(term.id, value)
      }, null, 8 /* PROPS */, ["parent-op", "model-value", "onUpdate:modelValue"]),
      ($setup.terms.length > 1)
        ? (_openBlock(), _createBlock($setup["IconButton"], {
            key: 0,
            class: "ml-10 h-[40px]",
            name: "delete",
            size: "l",
            onClick: ($event: any) => ($setup.deleteTerm(term.id))
          }, null, 8 /* PROPS */, ["onClick"]))
        : _createCommentVNode("v-if", true),
      _createVNode($setup["IconButton"], {
        class: _normalizeClass(["ml-10 h-[40px]", { invisible: index != $setup.terms.length - 1 }]),
        name: "add-line",
        size: "l",
        onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.addTerm()))
      }, null, 8 /* PROPS */, ["class"])
    ]))
  }), 128 /* KEYED_FRAGMENT */))
}