<template>
  <a :href="linkUrl" target="_blank">{{ value.value.lat }}, {{ value.value.lon }}</a>
</template>

<style lang="scss" scoped>
a {
  color: inherit;
}
</style>

<script lang="ts" setup>
import { GeopointValue } from "@/common/lib/value";
import { computed, toRefs } from "vue";

const props = defineProps<{ value: GeopointValue }>();
const { value } = toRefs(props);

const linkUrl = computed(function () {
  const { lat, lon } = value.value.value; // uhh
  return `https://www.openstreetmap.org/?mlat=${lat}&mlon=${lon}&zoom=17`;
});
</script>
