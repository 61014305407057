<template>
  <div class="flex flex-col">
    <div class="flex flex-row">
      <TextButton :secondary="true" label="Cancel" @click="cancel" />
      <TextButton
        :label="mode === 'create' ? 'Create' : 'Save'"
        @click="emit('save')"
        :secondary="!needsSave"
        :disabled="!canSave"
      />
    </div>
  </div>
  <Dialog
    v-if="showConfirmDialog"
    title="Discard changes?"
    success-label="Discard"
    @succeeded="handleConfirmClose"
    @cancelled="handleCancelClose"
    icon="md:warning"
  >
    {{ label ? `You have unsaved changes to ${label}.` : "You have unsaved changes." }}
    <br />
    Are you sure you want to discard them?
  </Dialog>
</template>

<script setup lang="ts">
import TextButton from "@/common/components/TextButton.vue";
import Dialog from "@/common/components/Dialog.vue";
import { ref } from "vue";
const props = withDefaults(
  defineProps<{
    mode?: string;
    needsSave?: boolean;
    canSave?: boolean;
    label?: string;
  }>(),
  {
    mode: "edit",
    needSave: false,
    canSave: true,
  }
);

const emit = defineEmits(["save", "cancelled"]);
const showConfirmDialog = ref(false);

function cancel() {
  if (props.needsSave) {
    showConfirmDialog.value = true;
  } else {
    emit("cancelled");
  }
}

function handleCancelClose() {
  showConfirmDialog.value = false;
}

function handleConfirmClose() {
  showConfirmDialog.value = false;
  emit("cancelled");
}
</script>
