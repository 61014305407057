import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["flex h-[75px] w-auto cursor-default items-center justify-around rounded border border-gray-700 bg-gray-800", {
      'cursor-pointer hover:border-orange hover:bg-gray-900 active:bg-black': !$props.static && !$props.disabled,
      'cursor-not-allowed opacity-50': $props.disabled,
    }]),
    disabled: $props.disabled
  }, [
    _createElementVNode("img", {
      src: $props.imgSrc,
      style: _normalizeStyle({ height: $props.height })
    }, null, 12 /* STYLE, PROPS */, _hoisted_2)
  ], 10 /* CLASS, PROPS */, _hoisted_1))
}