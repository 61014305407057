import { createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "absolute inset-0 flex flex-col items-center justify-center" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "flex items-center font-light text-gray-500" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.primaryDatum != null)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          style: _normalizeStyle({ fontSize: `${$setup.primaryTextHeight}px` })
        }, [
          _createVNode($setup["GraphValueComponent"], {
            value: $setup.primaryDatum.formattedValue
          }, null, 8 /* PROPS */, ["value"])
        ], 4 /* STYLE */))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, "—")),
    ($setup.comparisonDatum != null)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          style: _normalizeStyle({ fontSize: `${$setup.comparisonTextHeight}px` }),
          class: "flex items-center"
        }, [
          ($setup.diffDatum != null)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["mr-10 font-semibold", {
          'text-green-500': $setup.comparisonIsGood,
          'text-red-500': !$setup.comparisonIsGood,
        }])
              }, [
                _createVNode($setup["GraphValueComponent"], {
                  value: $setup.diffDatum.formattedValue
                }, null, 8 /* PROPS */, ["value"])
              ], 2 /* CLASS */))
            : _createCommentVNode("v-if", true),
          _createElementVNode("div", _hoisted_3, [
            _createVNode($setup["Icon"], {
              name: "md:target",
              color: "medium-gray"
            }),
            _createVNode($setup["GraphValueComponent"], {
              value: $setup.comparisonDatum.formattedValue
            }, null, 8 /* PROPS */, ["value"])
          ])
        ], 4 /* STYLE */))
      : _createCommentVNode("v-if", true)
  ]))
}