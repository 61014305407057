<template>
  <div class="my-10">
    <label class="mb-5 block">Start:</label>
    <DerivedPropertyDef :parent-op="OP" v-model="start" />
  </div>
  <div class="my-10">
    <label class="mb-5 block">End:</label>
    <DerivedPropertyDef :parent-op="OP" v-model="end" />
  </div>
  <div class="my-10">
    <label class="mb-5 block">Unit:</label>
    <div class="selection">
      <v-select
        v-model="unit"
        :options="dateDiffUnitOptions"
        :appendToBody="true"
        class="select"
        :class="{ 'light-select': !darkMode }"
        :getOptionLabel="formatUnit"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  DerivedPropertyTerm,
  DateDiffPropertyType,
  PropertyOpType,
  DateDiffUnit,
  dateDiffUnitOptions,
} from "@/common/lib/derived";
import { inject, Ref, ref, watchEffect } from "vue";
import DerivedPropertyDef from "./DerivedPropertyDef.vue";
import { DarkMode } from "@/common/lib/keys";
import vSelect from "vue-select";
import { capitalize } from "lodash";

const darkMode = inject(DarkMode) as Ref<boolean>;

const OP = PropertyOpType.DateDiff;

const unit = ref<DateDiffUnit>("DAY");
const start: Ref<DerivedPropertyTerm | null> = ref(null);
const end: Ref<DerivedPropertyTerm | null> = ref(null);

const model = defineModel<DateDiffPropertyType | null>({ required: true });

function formatUnit(unit: DateDiffUnit): string {
  return capitalize(unit) + "s";
}

watchEffect(function () {
  if (start.value == null || end.value == null) {
    model.value = null;
  } else {
    model.value = {
      op: OP,
      unit: unit.value,
      start: start.value,
      end: end.value,
    };
  }
});
</script>
