import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "isolate" }
const _hoisted_2 = {
  key: 0,
  class: "text-xxxl"
}
const _hoisted_3 = { class: "absolute inset-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.async.status !== $setup.AsyncStatus.Succeeded)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["absolute inset-0 z-10 flex flex-col items-center justify-center gap-10 text-black dark:text-white", { 'bg-white bg-opacity-85 dark:bg-black dark:bg-opacity-85': $props.overlay }])
        }, [
          ($setup.async.status === $setup.AsyncStatus.Failed)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString($setup.async.message), 1 /* TEXT */))
            : _createCommentVNode("v-if", true),
          ($setup.async.status === $setup.AsyncStatus.InProgress)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createVNode($setup["Spinner"]),
                _createElementVNode("div", {
                  class: _normalizeClass(["mt-10 text-xxxl", { 'opacity-60': $props.overlay }])
                }, _toDisplayString($setup.async.message), 3 /* TEXT, CLASS */),
                _createElementVNode("div", {
                  class: _normalizeClass({ 'opacity-60': $props.overlay })
                }, [
                  _createVNode($setup["WarehouseLoading"])
                ], 2 /* CLASS */)
              ], 64 /* STABLE_FRAGMENT */))
            : _createCommentVNode("v-if", true)
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}