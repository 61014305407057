import { uniqWith, without } from "lodash";
import {
  BASE_CONCEPT_TYPE,
  ConceptKnowledgeRef,
  PropertyKnowledgeRef,
  ShapeComponent,
  ShapeType,
} from "./knowledge";
import { MapSectionKey } from "./map";
import { GraphValue, isValue, stringifyValue } from "./value";

export interface Graph {
  concepts: GraphConcept[];
  links: GraphLink[];
}

export interface GraphConcept {
  id: string;
  type: ConceptKnowledgeRef;
  properties?: GraphProperty[];
  trace?: GraphTrace;
}

export interface GraphProperty {
  id: string;
  type: PropertyKnowledgeRef;
  value?: GraphValue | GraphCompoundValue;
  invalid?: unknown;
  qualifiers?: { [key: string]: GraphValue };
  source?: Record<string, unknown>;
  trace?: GraphTrace;
}

export interface GraphLink {
  id: string;
  type: string;
  from: string;
  to: string;
  properties?: GraphProperty[];
  trace?: GraphTrace;
}

export enum LinkDescriptor {
  AsA = "as_a",
  RelatedTo = "related_to",
  RoleOf = "role_of",
}

export interface GraphTrace {
  map: string;
  section: MapSectionKey;
  clause?: string;
  details?: Record<string, unknown>;
  priors?: GraphTrace[];
  in_connections: string;
}

export type GraphCompoundValue = { [key: string]: GraphValue };

export interface GraphSelection {
  type: "concept" | "link";
  id: string;
}

export interface PropertyAndComponentType {
  property_type: string;
  property_component?: string;
}

export interface ShapeMatch {
  shape: ShapeType;
  baseComponent: ShapeComponent;
  role: GraphConcept;
  opposing: GraphConcept;
  nearLink: GraphLink;
  farLink: GraphLink;
}

export function propertiesOfType(concept: GraphConcept, propType: string) {
  const props = concept.properties ?? [];
  return props.filter((p) => p.type === propType);
}

export function isValidProperty(property: GraphProperty) {
  return property.invalid == null;
}

export function deduplicatedLinks(links: GraphLink[]): GraphLink[] {
  // XXX This is done for demo looks; we need to figure out how to handle it
  //     more generally and on the server side
  return uniqWith(links, (l1, l2) => l1.type === l2.type && l1.from === l2.from && l1.to === l2.to);
}

export function stringifyProperty(property: GraphProperty): string {
  if (!property || !property.value) {
    return "invalid"; // TODO: constant for invalid text? better error for null values?
  }
  return stringifyValueOrCompositeValue(property.value);
}

export function stringifyValueOrCompositeValue(
  value: GraphValue | GraphCompoundValue | null | undefined,
  empty = "-"
): string {
  if (value == null) return empty;
  // XXX Do better
  if (isValue(value)) {
    return stringifyValue(value as GraphValue);
  } else {
    return Object.values(value as GraphCompoundValue)
      .map((v) => stringifyValue(v))
      .join(", ");
  }
}

export function findTraceBy(
  item: GraphConcept | GraphProperty | GraphLink,
  test: (t: GraphTrace) => boolean
): GraphTrace | null {
  function testTrace(trace: GraphTrace | undefined): GraphTrace | null {
    if (trace == null) return null;
    if (test(trace)) return trace;
    for (const prior of trace.priors ?? []) {
      const result = testTrace(prior);
      if (result != null) return result;
    }
    return null;
  }
  return testTrace(item.trace);
}

export function emptyGraph(): Graph {
  return {
    concepts: [],
    links: [],
  };
}

export function emptyConcept(): GraphConcept {
  return {
    id: "",
    type: BASE_CONCEPT_TYPE,
    properties: [],
  };
}

export function linkPartner(link: GraphLink, otherConceptId: string) {
  return without([link.from, link.to], otherConceptId)[0];
}

export function invertLinkDescriptor(descriptor: LinkDescriptor): LinkDescriptor {
  switch (descriptor) {
    case LinkDescriptor.RelatedTo:
      return LinkDescriptor.RelatedTo;
    case LinkDescriptor.AsA:
      return LinkDescriptor.RoleOf;
    case LinkDescriptor.RoleOf:
      return LinkDescriptor.AsA;
  }
}
