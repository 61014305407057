import { DateTime } from "luxon";

/**
 * Normalize a human-readable string into an ID segment of the form 0-9a-z_
 * @param name String containing any characters
 * @returns string only containing 0-9a-z_
 */
export function normalizeName(name: string): string {
  return name
    .toLowerCase()
    .normalize("NFKD")
    .replace(/\p{Diacritic}/gu, "")
    .replaceAll(/[^0-9a-z_]/g, "_");
}

/**
 * Format a date to be "Mmm DD, YYYY"
 * @param date DateTime or undefined
 * @returns string representing the date
 */
export function formatDate(date: DateTime | undefined) {
  return date ? date.toLocaleString(DateTime.DATE_MED) : "";
}

/**
 * Format bytes with a unit.
 * @param bytes unformatted bytes
 * @returns formatted string of number + unit
 */
export function formatBytes(bytes: number): string {
  const units = ["bytes", "KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB"];
  let amount = bytes;
  for (const unit of units) {
    if (amount < 1024) {
      return amount + " " + unit;
    }
    amount = Math.floor(amount / 1024);
  }
  return amount + " " + "YiB";
}
