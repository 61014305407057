import { createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "absolute left-[-50px] top-[25px] mt-10 w-[100px] text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle({ top: $setup.top, left: $setup.left }),
    class: "absolute z-30 cursor-pointer"
  }, [
    _createElementVNode("div", {
      onMousedown: _withModifiers($setup.startDragging, ["prevent"]),
      onClick: $setup.click
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["absolute left-[-25px] top-[-25px] z-0 flex h-[50px] w-[50px] items-center justify-center rounded-full bg-gray-800", { 'border-2 border-white': $setup.isRoot }]),
        style: _normalizeStyle({ backgroundColor: $setup.color })
      }, [
        _createVNode($setup["Icon"], {
          class: "icon",
          name: $setup.iconName,
          size: "xxl",
          color: "gray2"
        }, null, 8 /* PROPS */, ["name"])
      ], 6 /* CLASS, STYLE */),
      ($setup.propertyCount > 0)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "relative right-[-8px] top-[13px] z-10 min-w-20 rounded-full border-2 border-gray-200 text-center text-gray-900 dark:border-gray-900",
            style: _normalizeStyle({ backgroundColor: $setup.color })
          }, _toDisplayString($setup.propertyCount), 5 /* TEXT, STYLE */))
        : _createCommentVNode("v-if", true),
      _createElementVNode("div", _hoisted_1, _toDisplayString($setup.name), 1 /* TEXT */)
    ], 32 /* NEED_HYDRATION */)
  ], 4 /* STYLE */))
}