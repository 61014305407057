import { createElementVNode as _createElementVNode, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "m-10" }
const _hoisted_2 = { class: "input" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[2] || (_cache[2] = _createElementVNode("label", { class: "mb-5 block" }, "Type:", -1 /* HOISTED */)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode($setup["vSelect"], {
        class: "claritype-dropdown",
        modelValue: $setup.valueType,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.valueType) = $event)),
        options: $setup.valueTypeOptions,
        clearable: false,
        appendToBody: true
      }, null, 8 /* PROPS */, ["modelValue", "options"])
    ]),
    _cache[3] || (_cache[3] = _createElementVNode("label", { class: "mb-5 block" }, "Value:", -1 /* HOISTED */)),
    _createVNode($setup["Textbox"], _mergeProps({
      modelValue: $setup.inputValue,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.inputValue) = $event))
    }, $setup.inputType, {
      errorText: $setup.isInvalid ? 'Invalid value for selected type' : undefined
    }), null, 16 /* FULL_PROPS */, ["modelValue", "errorText"])
  ]))
}