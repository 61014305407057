<template>
  <div class="my-10">
    <label class="mb-5 block">Total of:</label>
    <DerivedPropertyDef :parent-op="OP" v-model="term" :props-only="true" />
  </div>
</template>

<script lang="ts" setup>
import { PropertyOpType, SumPropertyType } from "@/common/lib/derived";
import { Ref, ref, watchEffect } from "vue";
import DerivedPropertyDef from "./DerivedPropertyDef.vue";
import { PropertyKnowledgeRef } from "@/common/lib/knowledge";

const OP = PropertyOpType.Sum;

const term: Ref<PropertyKnowledgeRef | null> = ref(null);

const model = defineModel<SumPropertyType | null>({ required: true });

watchEffect(function () {
  if (term.value == null) {
    model.value = null;
  } else {
    model.value = {
      op: OP,
      property_type: term.value,
    };
  }
});
</script>
