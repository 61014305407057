import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7a0e9b1f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "list-builder" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString($props.title), 1 /* TEXT */),
      _createVNode($setup["IconButtonV2"], {
        name: "md:add_circle",
        size: "l",
        onClick: $setup.addNullItem,
        label: "Add",
        disabled: $setup.listHasNullItem,
        "data-test": "add-item"
      }, null, 8 /* PROPS */, ["disabled"])
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.list, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "item",
        key: index
      }, [
        _createElementVNode("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "default", {
            item: item,
            updateItem: (newValue) => $setup.updateItem(index, newValue)
          }, undefined, true)
        ]),
        _createVNode($setup["IconButtonV2"], {
          name: "delete",
          onClick: ($event: any) => ($setup.removeItem(index)),
          label: "Remove"
        }, null, 8 /* PROPS */, ["onClick"])
      ]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}