<template>
  <Teleport to="#modal-container">
    <div class="absolute inset-0 z-[1000]">
      <div class="absolute inset-0 bg-black opacity-50"></div>
      <div class="absolute inset-0 flex items-center justify-center">
        <div
          class="flex min-h-[100px] min-w-[450px] max-w-[450px] flex-col gap-5 rounded-[5px] border border-gray-200 bg-white px-20 py-10 text-gray-500 shadow-modal dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
        >
          <div class="mb-5 flex flex-row items-center gap-10 text-xxl font-bold leading-6">
            <Icon :name="icon" :color="iconColor" />
            {{ title }}
          </div>
          <div
            v-if="$slots.header"
            class="-mr-[15px] max-h-[70vh] overflow-y-scroll pr-15 text-lg font-normal text-gray-500 dark:text-white"
          >
            <slot name="header"></slot>
          </div>
          <div
            class="-mr-[15px] max-h-[70vh] overflow-y-scroll pr-15 text-lg font-normal text-gray-500 dark:text-white"
          >
            <slot></slot>
          </div>
          <div
            v-if="$slots.footer"
            class="-mr-[15px] max-h-[70vh] overflow-y-scroll pr-15 text-lg font-normal text-gray-500 dark:text-white"
          >
            <slot name="footer"></slot>
          </div>
          <div
            v-if="!skipDefaultFooter"
            class="flex flex-row items-center justify-end gap-10 pt-10"
          >
            <TextButton
              v-if="cancelLabel"
              :label="cancelLabel"
              :secondary="true"
              @click="emit('cancelled')"
            />
            <TextButton
              v-if="successLabel"
              :label="successLabel"
              @click="emit('succeeded')"
              data-test="success-button"
            />
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script lang="ts" setup>
import TextButton from "@/common/components/TextButton.vue";
import Icon from "./Icon.vue";
import { useKeyHandler } from "../composables/useKeyHandler";
import { KeyCommand } from "../lib/modifierKeys";
import { IconColorSpec } from "@/common/lib/icons";

const props = withDefaults(
  defineProps<{
    title: string;
    icon?: string;
    iconColor?: IconColorSpec;
    successLabel?: string | null;
    cancelLabel?: string | null;
    skipDefaultFooter?: boolean;
  }>(),
  {
    icon: "md:deployed_code",
    successLabel: "Ok",
    cancelLabel: "Cancel",
    skipDefaultFooter: false,
  }
);

const emit = defineEmits(["succeeded", "cancelled"]);

const keyHandler = useKeyHandler();
if (!props.skipDefaultFooter && props.cancelLabel) {
  keyHandler.registerHandler(new KeyCommand("Escape"), () => emit("cancelled"), true, false);
}
if (!props.skipDefaultFooter && props.successLabel) {
  keyHandler.registerHandler(new KeyCommand("Enter"), () => emit("succeeded"), true, false);
}
</script>
