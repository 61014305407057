import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["inline-flex items-center justify-center gap-5 rounded px-[6px] py-[4px] text-center leading-4", [
      `text-${$setup.textColor}`,
      {
        'border-none text-lg uppercase': !$setup.icon,
      },
    ]])
  }, [
    _createVNode($setup["Spinner"], {
      size: "l",
      class: "mr-1 px-2"
    }),
    _createElementVNode("span", null, _toDisplayString($setup.label), 1 /* TEXT */)
  ], 2 /* CLASS */))
}