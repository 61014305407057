<template>
  <component :is="valueComponent" :value="value" />
</template>

<script lang="ts">
import { GraphValue, GraphValueType } from "@/common/lib/value";
import { computed, defineComponent, toRefs } from "vue";

export default defineComponent({
  props: {
    value: {
      type: Object as () => GraphValue,
      required: true,
    },
  },
  setup(props) {
    const { value } = toRefs(props);

    const valueComponent = computed(function () {
      switch (value.value._type) {
        case GraphValueType.Integer:
        case GraphValueType.Float:
          return "graph-value-number";

        case GraphValueType.String:
          return "graph-value-string";

        case GraphValueType.Bytes:
          return "graph-value-image";

        case GraphValueType.Geopoint:
          return "graph-value-geopoint";

        default:
          return "graph-value-string";
      }
    });

    return { valueComponent };
  },
});
</script>
