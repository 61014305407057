import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "pointer-events-none absolute inset-0 z-30 cursor-pointer fill-none stroke-white",
  width: "100%",
  height: "100%"
}
const _hoisted_2 = ["d"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      class: "z-20 stroke-gray-500 stroke-1",
      d: $setup.path
    }, null, 8 /* PROPS */, _hoisted_2)
  ]))
}