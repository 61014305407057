import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "absolute inset-0 flex items-center justify-center" }
const _hoisted_2 = {
  key: 0,
  class: "text-[50px]"
}
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.datum != null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode($setup["GraphValueComponent"], {
            value: $setup.datum.formattedValue
          }, null, 8 /* PROPS */, ["value"])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, "—"))
  ]))
}