import { resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-567b364a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "bar"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_close_popper = _resolveDirective("close-popper")!

  return (_openBlock(), _createBlock($setup["Dropdown"], { placement: "auto" }, {
    popper: _withCtx(() => [
      _withDirectives(_createVNode($setup["Menu"], { items: $setup.menuItems }, null, 8 /* PROPS */, ["items"]), [
        [_directive_close_popper]
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["interactive-value", {
        '-with-bar': $setup.hasBarChart,
        '-quantitative': $props.quantitative,
        '-with-concept-page': $setup.hasConceptPage,
      }])
      }, [
        _createVNode($setup["GraphValueComponent"], {
          value: $setup.value.formattedValue
        }, null, 8 /* PROPS */, ["value"]),
        ($setup.hasBarChart)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode($setup["MiniBar"], {
                column: $setup.column!,
                value: $setup.value.originalValue as FloatValue
              }, null, 8 /* PROPS */, ["column", "value"])
            ]))
          : _createCommentVNode("v-if", true)
      ], 2 /* CLASS */)
    ]),
    _: 1 /* STABLE */
  }))
}