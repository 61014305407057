<template>
  <div class="my-10">
    <label class="mb-5 block">Dividend:</label>
    <DerivedPropertyDef :parent-op="OP" v-model="dividend" />
  </div>
  <div class="my-10">
    <label class="mb-5 block">Divisor:</label>
    <DerivedPropertyDef :parent-op="OP" v-model="divisor" />
  </div>
</template>

<script lang="ts" setup>
import { DerivedPropertyTerm, DividePropertyType, PropertyOpType } from "@/common/lib/derived";
import { Ref, ref, watchEffect } from "vue";
import DerivedPropertyDef from "./DerivedPropertyDef.vue";

const OP = PropertyOpType.Divide;

const divisor: Ref<DerivedPropertyTerm | null> = ref(null);
const dividend: Ref<DerivedPropertyTerm | null> = ref(null);

const model = defineModel<DividePropertyType | null>({ required: true });

watchEffect(function () {
  if (divisor.value == null || dividend.value == null) {
    model.value = null;
  } else {
    model.value = {
      op: OP,
      divisor: divisor.value,
      dividend: dividend.value,
    };
  }
});
</script>
