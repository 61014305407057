import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-between pr-[4px] align-text-top" }
const _hoisted_2 = { class: "tooltip-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createVNode($setup["Tooltip"], {
      placement: "bottom",
      delay: { show: 100, hide: 100 }
    }, {
      popper: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, _toDisplayString($setup.warehouseInfo.name) + " is " + _toDisplayString($setup.warehouseInfo.status), 1 /* TEXT */)
      ]),
      default: _withCtx(() => [
        _createVNode($setup["StatusPill"], {
          color: $setup.warehouseStatusColor,
          label: $setup.capitalize($setup.warehouseInfo.status) + ' ' + $setup.upperCase($setup.warehouseInfo.name),
          sublabel: $setup.warehouseInfo.size
        }, null, 8 /* PROPS */, ["color", "label", "sublabel"])
      ]),
      _: 1 /* STABLE */
    })
  ]))
}