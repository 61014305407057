import { Query } from "@/common/lib/query";

// Reader Session Storage keys
const READER_SESSION_EXPLORE_QUERY = "explore_query";

// Store a query to be restored once upon navigating to explore
export function storeExploreQuery(query: Query) {
  window.sessionStorage.setItem(READER_SESSION_EXPLORE_QUERY, JSON.stringify(query));
}

// If a query is stored, return it for single use and then remove it
export function consumeStoredExploreQuery(): Query | null {
  const item = window.sessionStorage.getItem(READER_SESSION_EXPLORE_QUERY);
  if (item == null) {
    return null;
  } else {
    window.sessionStorage.removeItem(READER_SESSION_EXPLORE_QUERY);
    return JSON.parse(item);
  }
}
