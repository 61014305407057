<template>
  <div class="flex max-w-[450px] flex-col items-start gap-10 self-stretch px-10 py-0">
    <div
      class="item-start flex justify-between gap-10 self-stretch text-lg text-gray-900 dark:text-gray-100"
    >
      <span>
        Follow the instructions in the
        <a class="text-inherit visited:text-inherit" :href="databricksOAuthDocsUrl" target="_blank"
          >Databricks Documentation</a
        >
        to create a personal access token and paste it along with your Workspace URL below to
        connect your account.
      </span>
    </div>
    <form class="flex flex-col items-start gap-10 self-stretch">
      <Textbox
        label="Workspace nickname"
        :required="true"
        v-model="workspace.metadata.nickname"
        :disabled="saving"
      />
      <Textbox
        label="Personal access token"
        :required="true"
        v-model="workspace.credentials.personal_access_token"
        type="password"
        autocomplete="on"
        :disabled="saving"
      />
      <Textbox
        label="Server hostname"
        :required="true"
        v-model="workspace.metadata.id"
        :disabled="saving"
      />
    </form>
    <div class="flex items-center justify-end gap-10 self-stretch">
      <TextButton label="Cancel" @click="cancelEditWorkspace()" :disabled="saving" />
      <TextButton
        :label="commitLabel"
        v-if="!saving"
        :disabled="!saveEnabled || saving"
        @click="saveWorkspace()"
      />
      <SpinnerButton v-else :label="commitLabel" />
    </div>
  </div>

  <Dialog
    v-if="errorDialogMessage"
    :cancel-label="null"
    title="Connection Error"
    icon="error"
    @succeeded="errorDialogMessage = undefined"
  >
    {{ errorDialogMessage }}
  </Dialog>
</template>

<script lang="ts" setup>
import Textbox from "@/common/components/Textbox.vue";
import TextButton from "@/common/components/TextButton.vue";
import { environment } from "@/common/environments/environmentLoader";
import { FullWorkspace, ProviderType, useWorkspaceStore } from "@/common/stores/workspaceStore";
import { computed, onMounted, ref } from "vue";
import SpinnerButton from "../SpinnerButton.vue";
import Dialog from "@/common/components/Dialog.vue";
import { BackendError } from "@/common/http/http";
import { isObject, isString } from "lodash";

const workspaceStore = useWorkspaceStore();

const workspace = ref<DbxWorkspace>(newDbxWorkspace());
const props = defineProps<{ currentWorkspace?: FullWorkspace; mode: "edit" | "create" }>();

const emits = defineEmits(["saveWorkspace", "cancel"]);

const saving = ref(false);
const errorDialogMessage = ref<string | undefined>();

const saveEnabled = computed(
  () =>
    workspace.value.metadata.id.trim() !== "" &&
    workspace.value.metadata.nickname.trim() !== "" &&
    workspace.value.credentials.personal_access_token.trim() !== ""
);

const databricksOAuthDocsUrl = environment.require("DATABRICKS_OAUTH_DOCS_URL");
const commitLabel = computed(() => {
  if (saving.value) {
    return props.mode === "edit" ? "Updating…" : "Connecting…";
  }
  return props.mode === "edit" ? "Update" : "Connect";
});

function cancelEditWorkspace() {
  emits("cancel");
}

async function saveWorkspace() {
  saving.value = true;
  try {
    await workspaceStore.saveWorkspace(workspace.value);
    emits("saveWorkspace");
  } catch (error: unknown) {
    if (error instanceof BackendError) {
      const response = error.failure?.response;
      if (isObject(response) && "cause" in response && isString(response.cause)) {
        errorDialogMessage.value = response.cause;
      }
      return;
    }
  } finally {
    saving.value = false;
  }
}

interface DbxWorkspace extends FullWorkspace {
  credentials: {
    personal_access_token: string;
  };
}

function newDbxWorkspace(): DbxWorkspace {
  return {
    metadata: {
      nickname: "",
      id: "",
      connected: true,
      current_warehouse_id: undefined,
      provider: ProviderType.Databricks,
    },
    credentials: {
      personal_access_token: "",
    },
  };
}

onMounted(() => {
  if (props.currentWorkspace) {
    // Todo: add a proper type filter.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    workspace.value = props.currentWorkspace as any;
  }
});
</script>
