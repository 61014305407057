<template>
  <div class="flex" :class="{ '-dark-hover': darkHover }" :style="{ height: buttonSizePx }">
    <Tooltip
      class="tooltip flex"
      :disabled="label == null || disabled"
      theme="icon-button-tooltip"
      placement="bottom"
      :distance="2"
    >
      <template #popper>{{ label }}</template>
      <button
        class="with-icon group flex cursor-pointer items-center justify-around rounded-[16px] border-none p-0"
        :class="{
          'active:bg-orange dark:active:bg-black': !disabled,
          'hover:bg-gray-200 dark:hover:bg-gray-900': !disabled && !darkHover,
          'hover:bg-gray-200 dark:hover:bg-black dark:active:bg-gray-900': !disabled && darkHover,
          'cursor-not-allowed opacity-50 dark:opacity-100': disabled,
        }"
        :disabled="disabled"
        :style="{ width: buttonSizePx, height: buttonSizePx }"
      >
        <div
          v-if="preText"
          class="svg-to-medium-gray"
          :class="{
            'dark:group-hover:svg-to-white group-hover:svg-to-dark-gray dark:group-active:svg-to-white group-active:svg-to-gray2':
              !disabled,
            'dark:svg-to-dark-gray svg-to-light-gray': disabled,
          }"
        >
          {{ preText }}
        </div>
        <img
          :src="iconSrc"
          :style="{ width: `${sizePx}px`, height: `${sizePx}px` }"
          class="dark:svg-to-medium-gray svg-to-dark-gray max-w-none"
          :class="{
            'dark:group-hover:svg-to-white group-hover:svg-to-dark-gray dark:group-active:svg-to-white group-active:svg-to-gray2':
              !disabled,
            'svg-to-light-gray dark:svg-to-dark-gray': disabled,
          }"
        />
      </button>
    </Tooltip>
  </div>
</template>
<style lang="scss">
.v-popper--theme-icon-button-tooltip {
  .v-popper__inner {
    @apply rounded bg-white px-2 pl-4 text-xs text-black dark:bg-black dark:text-white;
  }
  .v-popper__arrow-inner {
    @apply invisible;
  }
  .v-popper__arrow-outer {
    @apply invisible;
  }
}
</style>

<script lang="ts" setup>
import { computed } from "vue";
import { Tooltip } from "floating-vue";
import { getIconSizePx, IconSize, parseIconName } from "../lib/icons";

const props = withDefaults(
  defineProps<{
    name: string;
    size?: IconSize;
    label?: string;
    disabled?: boolean;
    targetSize?: number;
    darkHover?: boolean;
    preText?: string;
  }>(),
  {
    size: "m",
    disabled: false,
    targetSize: 32,
    darkHover: false,
  }
);

const sizePx = computed(() => getIconSizePx(props.size));
const iconSrc = computed(() => parseIconName(props.name));
const buttonSizePx = computed(() => Math.max(sizePx.value, props.targetSize) + "px");
</script>
