import { v4 as uuid } from "uuid";
import { onUnmounted, Ref, ref } from "vue";
import { sleep } from "../lib/asynchronous";

/**
 * Create a dynamic timer that updates its own wait state.
 * @param timerDeltaMs initial delay in milliseconds
 * @param debug flag to enable debug logging
 * @returns
 */
export function useDynamicTimer(timerDeltaMs = 5000, debug = false) {
  // Allow conditional debug logging.
  // eslint-disable-next-line no-console
  const log = debug ? console.debug : () => undefined;
  const id = uuid();

  log(id, "Created new useTimer");
  const timerStarted = ref(false);
  const timerAction: Ref<() => Promise<number>> = ref(() => Promise.reject("No action set"));

  async function start(action: () => Promise<number>) {
    log(id, "Timer started");
    // Update action to run.
    timerAction.value = action;
    if (timerStarted.value) {
      // There is already a timer running.
      return;
    }
    timerStarted.value = true;
    while (timerStarted.value) {
      log(id, "Timer running action");
      try {
        timerDeltaMs = await timerAction.value();
      } catch (error: unknown) {
        timerStarted.value = false;
        log(id, "Timer caught exception, stopping", error);
        return;
      }
      log(id, `Timer waiting ${timerDeltaMs} ms…`);
      await sleep(timerDeltaMs);
    }
  }

  function stop() {
    timerStarted.value = false;
    log(id, "Timer stopped");
  }

  // Always stop when unmounted.
  onUnmounted(() => {
    log(id, "Unmounting");
    stop();
  });

  return { start, stop };
}
