import { toDisplayString as _toDisplayString, vModelDynamic as _vModelDynamic, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["type"]
const _hoisted_2 = ["type"]
const _hoisted_3 = { class: "font-bold" }
const _hoisted_4 = { class: "font-bold" }
const _hoisted_5 = { class: "font-bold" }
const _hoisted_6 = { class: "font-bold" }
const _hoisted_7 = { class: "font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.isEditing)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        _createTextVNode(_toDisplayString($setup.opDescription) + " between ", 1 /* TEXT */),
        ($setup.isEditing)
          ? (_openBlock(), _createBlock($setup["InPlaceEdit"], {
              key: 0,
              title: "Value Range",
              onCancel: $setup.stopEditing,
              onCommit: $setup.commit,
              valid: $setup.isEditValid
            }, {
              default: _withCtx(() => [
                _withDirectives(_createElementVNode("input", {
                  type: $setup.filterType,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.lowerBoundUnderEdit) = $event)),
                  ref: "lowerBoundInputEl",
                  placeholder: "Enter a minimum",
                  class: "w-full border-transparent bg-transparent",
                  style: { colorScheme: 'dark' }
                }, null, 8 /* PROPS */, _hoisted_1), [
                  [_vModelDynamic, $setup.lowerBoundUnderEdit]
                ]),
                _withDirectives(_createElementVNode("input", {
                  type: $setup.filterType,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.upperBoundUnderEdit) = $event)),
                  placeholder: "Enter a maximum",
                  class: "w-full border-transparent bg-transparent",
                  style: { colorScheme: 'dark' }
                }, null, 8 /* PROPS */, _hoisted_2), [
                  [_vModelDynamic, $setup.upperBoundUnderEdit]
                ])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["valid"]))
          : _createCommentVNode("v-if", true)
      ], 64 /* STABLE_FRAGMENT */))
    : (_openBlock(), _createElementBlock("span", {
        key: 1,
        onClick: $setup.startEditing,
        class: "cursor-pointer border-b border-dotted border-b-transparent hover:border-b-orange"
      }, [
        _createTextVNode(_toDisplayString($setup.opDescription) + " ", 1 /* TEXT */),
        ($setup.filter.values.length > 0)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              ($setup.isExact)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(" exactly "),
                    _createElementVNode("span", _hoisted_3, _toDisplayString($setup.stringifyValue($setup.lowerBound!)), 1 /* TEXT */)
                  ], 64 /* STABLE_FRAGMENT */))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    ($setup.upperBound == null)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createTextVNode(" at least "),
                          _createElementVNode("span", _hoisted_4, _toDisplayString($setup.stringifyValue($setup.lowerBound!)), 1 /* TEXT */)
                        ], 64 /* STABLE_FRAGMENT */))
                      : ($setup.lowerBound == null)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            _createTextVNode(" at most "),
                            _createElementVNode("span", _hoisted_5, _toDisplayString($setup.stringifyValue($setup.upperBound)), 1 /* TEXT */)
                          ], 64 /* STABLE_FRAGMENT */))
                        : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                            _createTextVNode(" between "),
                            _createElementVNode("span", _hoisted_6, _toDisplayString($setup.stringifyValue($setup.lowerBound)), 1 /* TEXT */),
                            _createTextVNode(" and "),
                            _createElementVNode("span", _hoisted_7, _toDisplayString($setup.stringifyValue($setup.upperBound)), 1 /* TEXT */)
                          ], 64 /* STABLE_FRAGMENT */))
                  ], 64 /* STABLE_FRAGMENT */))
            ], 64 /* STABLE_FRAGMENT */))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createTextVNode("(click to set)")
            ], 64 /* STABLE_FRAGMENT */))
      ]))
}