<template>
  <div class="m-10">Returns the current date</div>
</template>

<script lang="ts" setup>
import { CurrentDatePropertyType, PropertyOpType } from "@/common/lib/derived";
import { onMounted } from "vue";

const model = defineModel<CurrentDatePropertyType>();
onMounted(() => {
  model.value = { op: PropertyOpType.CurrentDate };
});
</script>
