<template>
  <div class="py-15 pl-20 pr-15">
    <Textbox
      v-model="sourceBrowserStore.search"
      type="search"
      :disabled="!hasSearch && catalogs.length === 0"
    />
  </div>
  <div class="max-h-fit flex-initial basis-full overflow-y-auto">
    <div class="overflow-y-auto">
      <div class="mx-0 my-10 max-h-fit select-none" data-test="system-resources">
        <template v-if="status === AsyncStatus.Succeeded">
          <div v-for="catalog in catalogs" :key="catalog.name">
            <div
              class="flex flex-col items-start justify-center px-10 py-5 hover:bg-gray-900"
              @click="toggleGroupCollapsed(catalog.name)"
            >
              <div class="flex items-center gap-10 self-stretch">
                <Disclosure
                  class="h-20 w-20 justify-center"
                  :expanded="isGroupExpanded(catalog.name) || hasSearch"
                />
                <Icon name="menu_book" />
                <div
                  class="flex flex-[1_0_0] flex-row items-center justify-start gap-5 break-all font-medium text-gray-200"
                >
                  <span>{{ catalog.name }}</span>
                </div>
                <div class="text-right text-sm text-gray-500">
                  {{ catalog.schemas.length }} {{ pluralize("Schemas", catalog.schemas.length) }}
                </div>
              </div>
            </div>
            <template v-if="isGroupExpanded(catalog.name) || hasSearch">
              <div v-for="schema in catalog.schemas" :key="schema.name">
                <div
                  class="my-5 ml-20 mr-15 flex flex-col items-start justify-center hover:bg-gray-900"
                  @click="toggleGroupCollapsed(`${catalog.name}:${schema.name}`)"
                >
                  <div class="flex items-center gap-10 self-stretch">
                    <Disclosure
                      class="disclosure"
                      :expanded="isGroupExpanded(`${catalog.name}:${schema.name}`) || hasSearch"
                    />
                    <Icon name="dataset" color="white" />
                    <div
                      class="flex flex-[1_0_0] flex-row items-center justify-start gap-5 break-all text-base text-gray-200"
                    >
                      <span>{{ schema.name }}</span>
                    </div>
                    <div class="text-right text-sm text-gray-500">
                      {{ schema.tables.length }} {{ pluralize("Table", schema.tables.length) }}
                    </div>
                  </div>
                </div>
                <template v-if="isGroupExpanded(`${catalog.name}:${schema.name}`) || hasSearch">
                  <div
                    v-for="table in listSystemTables(provider!, catalog, schema)"
                    class="flex flex-col items-start justify-center gap-5 py-5 pl-[60px] pr-15 hover:bg-gray-900"
                    :class="{ '-selected': isSelected(table), 'cursor-pointer': !table.loaded }"
                    :key="`${table.group}-${table.name}`"
                    :data-test="`table-${table.name}`"
                    @click="toggleSelected(table)"
                  >
                    <div class="flex items-center gap-5 self-stretch break-all">
                      <Icon v-if="table.loaded" name="table" color="orange" />
                      <Icon v-else-if="isSelected(table)" name="checkbox-selected" color="none" />
                      <Icon v-else name="table" color="white" />
                      <Tooltip placement="right-start" :delay="{ show: 500, hide: 100 }">
                        <template #popper>
                          <div class="max-w-[300px]">
                            <div class="mb-5 break-all">
                              {{ `${catalog.name}.${schema.name}.${table.name}` }}
                            </div>
                            {{ table.table.comment }}
                          </div>
                        </template>
                        <span :class="{ 'text-orange': table.loaded }">{{ table.name }}</span>
                      </Tooltip>
                    </div>
                    <div class="flex flex-row justify-between">
                      <div class="pl-[25px] text-sm text-gray-500" v-if="table.table.column_count">
                        {{ table.table.column_count }}
                        {{ pluralize("col", table.table.column_count) }}
                      </div>
                      <div class="pl-[25px] text-sm text-gray-500" v-if="table.table.rows">
                        {{ rowCount(table.table.rows) }}
                      </div>
                      <div class="pl-[25px] text-sm text-gray-500" v-if="table.table.bytes">
                        {{ formatBytes(table.table.bytes) }}
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </template>
          </div>

          <div class="p-10 text-center text-gray-500" v-if="hasSearch && catalogs.length === 0">
            No resources match your search
          </div>
          <div class="p-10 text-center text-gray-500" v-if="!hasSearch && catalogs.length === 0">
            Catalog empty
          </div>
        </template>
        <div class="text-center" v-if="status === AsyncStatus.InProgress">
          <Spinner />
        </div>
        <div v-if="status === AsyncStatus.Failed">
          <MessageBar mode="error">
            <template #title> Error loading tables </template>
            {{ (sourceBrowserStore.catalogs as AsyncFailed).message }}
            <template #actions>
              <TextButton label="Refresh" @click="refreshTables()" mode="error" />
            </template>
          </MessageBar>
        </div>
      </div>
    </div>
  </div>
  <div class="sticky bottom-0 m-20 ml-30 flex items-center bg-gray-800" v-if="selectionCount > 0">
    <div class="flex-initial basis-full text-gray-200">
      {{ pluralize("data source", selectionCount, true) }} selected
    </div>
    <TextButton label="Load" data-test="load" @click="commitTables" />
  </div>
</template>

<script lang="ts" setup>
import Icon from "@/common/components/Icon.vue";
import MessageBar from "@/common/components/MessageBar.vue";
import TextButton from "@/common/components/TextButton.vue";
import { AsyncFailed, asyncResultOr, AsyncStatus } from "@/common/lib/async";
import Disclosure from "@/common/components/Disclosure.vue";
import Spinner from "@/common/components/Spinner.vue";
import { Catalog, Schema, Table } from "@/common/stores/catalog";
import { SourceSelection, SystemTable, useSourceBrowserStore } from "@/common/stores/sourceBrowser";
import pluralize from "pluralize";
import { computed, onMounted, toRefs } from "vue";
import { Tooltip } from "floating-vue";
import { every, find } from "lodash";
import { environment } from "../environments/environmentLoader";
import { formatBytes } from "@/common/lib/text";
import { getMapSection, MapSectionKey, SourceType } from "@/common/lib/map";
import Textbox from "./Textbox.vue";
import useDataset from "@/editor/composables/useDataset";
import { useAppStore } from "@/editor/stores/app";

const props = defineProps<{ workspaceId: string }>();
const { workspaceId } = toRefs(props);

const emit = defineEmits<{
  (e: "select-tables", selections: SourceSelection[]): void;
}>();

const sourceBrowserStore = useSourceBrowserStore();
const appStore = useAppStore();

const status = computed(() => sourceBrowserStore.catalogs.status);
const provider = computed(() => sourceBrowserStore.provider);
const catalogs = computed(() => {
  const catalogs = asyncResultOr(sourceBrowserStore.catalogs, []);
  const filteredCatalogs = filterCatalogs(catalogs, sourceBrowserStore.search);
  return filteredCatalogs;
});
const hasSearch = computed(() => sourceBrowserStore.search.length > 0);

function filterCatalogs(catalogs: Catalog[], searchString: string): Catalog[] {
  if (searchString === "") {
    return catalogs;
  }
  return catalogs.flatMap((catalog) => {
    const schemas = catalog.schemas.flatMap((s) => filterSchema(s, searchString, catalog.name));
    if (schemas.length === 0) {
      return [];
    }
    catalog = Object.assign({}, catalog, { schemas });
    return [catalog];
  });
}

function filterSchema(schema: Schema, searchString: string, catalogName: string): Schema[] {
  const tables = schema.tables.filter((t) =>
    filterTable(t.name, searchString, catalogName, schema.name)
  );
  if (tables.length === 0) {
    return [];
  }
  schema = Object.assign({}, schema, { tables });
  return [schema];
}

function listSystemTables(provider: SourceType, catalog: Catalog, schema: Schema): SystemTable[] {
  return schema.tables.map((t) => toSystemTable(provider, catalog, schema, t));
}

function toSystemTable(
  provider: SourceType,
  catalog: Catalog,
  schema: Schema,
  table: Table
): SystemTable {
  const loaded = loadedPaths.value.includes(`${catalog.name}.${schema.name}.${table.name}`);
  return {
    provider,
    catalog: catalog.name,
    schema: schema.name,
    group: `${catalog.name}:${schema.name}`,
    name: table.name,
    table,
    loaded,
  };
}

function filterTable(
  tableName: string,
  searchString: string,
  catalogName: string,
  schemaName: string
): boolean {
  const searchTokens = searchString.toLowerCase().split(/ +/);
  const names = [tableName.toLowerCase()];
  if (environment.requireBoolean("SOURCES_SEARCH_CATALOGS_AND_SCHEMAS")) {
    names.push(schemaName.toLowerCase(), catalogName.toLowerCase());
  }
  return every(searchTokens, (token) => find(names, (name) => name.includes(token)));
}

function toggleSelected(table: SystemTable) {
  if (table.loaded) {
    return;
  }
  sourceBrowserStore.toggleSelected(table);
}

function toggleGroupCollapsed(group: string) {
  sourceBrowserStore.toggleGroupCollapsed(group);
}

const isSelected = (table: SystemTable) => sourceBrowserStore.isSelected(table);

const isGroupExpanded = (group: string) => sourceBrowserStore.collapsedGroups.includes(group);

function rowCount(rowCount: number): string | undefined {
  if (!rowCount) {
    return undefined;
  }
  return `${rowCount.toLocaleString()} ${pluralize("row", rowCount)}`;
}

function refreshTables() {
  sourceBrowserStore.loadSystemTables(workspaceId.value, true);
}

function commitTables() {
  emit("select-tables", sourceBrowserStore.selection);
}

const selectionCount = computed(() => sourceBrowserStore.selection.length);

const loadedPaths = computed(() => {
  const clauses = getMapSection(appStore.mapOrEmptyMap, MapSectionKey.InEncodings);
  return Object.keys(clauses).map(toCatalogPath);
});

function toCatalogPath(id: string) {
  const fullDataset = useDataset(() => id);
  const path = fullDataset.catalogPath();
  if (!path) {
    return "";
  }
  return `${path.catalog}.${path.schema}.${path.table}`;
}

onMounted(function () {
  sourceBrowserStore.loadSystemTables(workspaceId.value);
});
</script>
