import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withModifiers as _withModifiers, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "metagraph-pane" }
const _hoisted_2 = {
  class: "absolute inset-0 overflow-hidden",
  ref: "canvas"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode($setup["ResizeObserver"], {
        "emit-on-mount": true,
        onNotify: $setup.handleResize
      }),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.metalinks, (link) => {
        return (_openBlock(), _createBlock($setup["Metalink"], {
          key: link.id,
          "link-type": link.type,
          "from-x": link.fromX,
          "from-y": link.fromY,
          "to-x": link.toX,
          "to-y": link.toY
        }, null, 8 /* PROPS */, ["link-type", "from-x", "from-y", "to-x", "to-y"]))
      }), 128 /* KEYED_FRAGMENT */)),
      _createElementVNode("div", {
        class: "absolute inset-0 cursor-grab",
        onMousedown: _withModifiers($setup.startPan, ["prevent"])
      }, null, 32 /* NEED_HYDRATION */),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.metaconcepts, (metaconcept) => {
        return (_openBlock(), _createBlock($setup["Metaconcept"], {
          ref_for: true,
          ref: "metaconceptComponents",
          "concept-type": metaconcept.type,
          "position-x": $setup.metaconceptXPosition(metaconcept.type),
          "position-y": $setup.metaconceptYPosition(metaconcept.type),
          key: metaconcept.id,
          onMove: (x, y) => $setup.moveConcept(metaconcept.type, x, y)
        }, null, 8 /* PROPS */, ["concept-type", "position-x", "position-y", "onMove"]))
      }), 128 /* KEYED_FRAGMENT */))
    ], 512 /* NEED_PATCH */)
  ]))
}