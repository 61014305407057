<template>
  <div class="list-builder">
    <div class="header">
      <div class="title">{{ title }}</div>
      <IconButtonV2
        name="md:add_circle"
        size="l"
        @click="addNullItem"
        label="Add"
        :disabled="listHasNullItem"
        data-test="add-item"
      />
    </div>
    <div v-for="(item, index) in list" class="item" :key="index">
      <div class="body">
        <slot :item="item" :update-item="(newValue: T | null) => updateItem(index, newValue)" />
      </div>
      <IconButtonV2 name="delete" @click="removeItem(index)" label="Remove" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  margin-bottom: $thin-margin;

  .title {
    flex: 0 1 100%;
    color: $gray8;
    font-size: 14px;
    font-weight: 500;
  }
}

.item {
  display: flex;
  align-items: center;
  margin-bottom: $thin-margin;

  .body {
    flex: 0 1 100%;
  }
}

.add {
  display: flex;
}
</style>

<script setup lang="ts" generic="T">
import { Ref, computed, ref, watchEffect } from "vue";
import IconButtonV2 from "./IconButtonV2.vue";

const list: Ref<Array<T | null>> = ref([]);

defineProps<{ title: string }>();
const model = defineModel<Array<T | null>>({ required: true });
defineSlots<{
  default(props: { item: T | null; updateItem: (item: T | null) => void }): unknown;
}>();

function addNullItem() {
  list.value.push(null);
}

function removeItem(index: number) {
  list.value.splice(index, 1);
  updateModel();
}

function updateItem(index: number, newValue: T | null) {
  list.value[index] = newValue;
  updateModel();
}

function updateModel() {
  model.value = list.value.filter((v) => v != null);
}

const listHasNullItem = computed(() => list.value.includes(null));

watchEffect(() => (list.value = [...model.value]));
</script>
