import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex max-w-full items-center rounded-[20px] bg-gray-700 p-[3px] pr-[6px] text-[5px]" }
const _hoisted_2 = { class: "ml-5 truncate pr-5 text-xs" }
const _hoisted_3 = {
  key: 0,
  class: "text-xs text-gray-500"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      style: _normalizeStyle({ color: $props.color })
    }, "⬤", 4 /* STYLE */),
    _createElementVNode("div", _hoisted_2, _toDisplayString($props.label), 1 /* TEXT */),
    ($props.sublabel)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString($props.sublabel), 1 /* TEXT */))
      : _createCommentVNode("v-if", true)
  ]))
}