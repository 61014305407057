<template>
  <div class="z-10 w-full select-none">
    <div class="scrollbar-none flex overflow-x-auto overflow-y-hidden">
      <div
        class="group relative flex cursor-pointer flex-col hover:bg-gray-200 dark:hover:bg-gray-900 dark:active:bg-black"
        :class="{
          'text-gray-700 dark:text-gray-200': tab.key === props.modelValue,
          'text-gray-500 hover:text-gray-700 dark:hover:text-gray-200':
            tab.key !== props.modelValue,
          'pointer-events-none opacity-30': tab.disabled,
        }"
        v-for="tab in tabs"
        :key="tab.key"
        @click="emit('update:modelValue', tab.key)"
      >
        <div
          class="m-0 mx-10 flex items-center gap-5"
          :class="{
            'color-unset': tab.key === props.modelValue,
          }"
        >
          <template v-if="tab.icon">
            <Icon
              class="flex group-hover:hidden"
              :name="tab.icon"
              size="m"
              :color="
                tab.key === props.modelValue
                  ? { light: 'dark-gray', dark: 'light-gray' }
                  : 'medium-gray'
              "
            />
            <Icon
              class="hidden group-hover:flex"
              :name="tab.icon"
              size="m"
              :color="{ light: 'dark-gray', dark: 'light-gray' }"
            />
          </template>
          <div class="max-w-[150px] overflow-hidden text-ellipsis whitespace-nowrap leading-[32px]">
            {{ tab.label }}
          </div>
        </div>
        <div class="h-2 bg-orange" v-if="tab.key === props.modelValue"></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Icon from "./Icon.vue";
import { Tab } from "./tab";

const props = defineProps<{ tabs: Tab[]; modelValue: string }>();
const emit = defineEmits(["update:modelValue"]);
</script>
