import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["IconButton"], {
    name: $setup.expanded ? 'arrow_drop_down' : 'arrow_right',
    color: "white",
    size: $setup.size,
    "target-size": 20,
    onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.emit('toggle')))
  }, null, 8 /* PROPS */, ["name", "size"]))
}