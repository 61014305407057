<template>
  <Teleport to="#modal-container">
    <div class="absolute inset-0 z-[1000]">
      <div class="absolute inset-0 bg-black opacity-50"></div>
      <div class="absolute inset-0 flex items-center justify-center">
        <div
          class="border border-gray-200 bg-white p-15 shadow-modal dark:border-gray-700 dark:bg-gray-900"
          :style="style"
        >
          <slot></slot>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script lang="ts" setup>
import { StyleValue } from "vue";

defineProps<{
  style?: StyleValue;
}>();
</script>
