import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "absolute inset-0 flex flex-col" }
const _hoisted_2 = { class: "mx-10 my-5 flex min-h-30 items-center" }
const _hoisted_3 = { class: "mx-5 shrink grow-0 basis-full text-xxl font-bold" }
const _hoisted_4 = { class: "basis-full overflow-y-auto p-10 pt-0" }
const _hoisted_5 = {
  key: 0,
  class: "flex justify-center p-15"
}
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 2 }
const _hoisted_8 = { class: "properties" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode($setup["Icon"], {
        name: $setup.iconName,
        color: { dark: 'white', light: 'black' }
      }, null, 8 /* PROPS */, ["name"]),
      _createElementVNode("div", _hoisted_3, _toDisplayString($setup.title), 1 /* TEXT */),
      ($props.showCloseButton)
        ? (_openBlock(), _createBlock($setup["IconButtonV2"], {
            key: 0,
            name: "close",
            size: "l",
            onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.emit('close')))
          }))
        : _createCommentVNode("v-if", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      ($setup.queryResults.status === $setup.AsyncStatus.InProgress)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode($setup["Spinner"])
          ]))
        : _createCommentVNode("v-if", true),
      ($setup.queryResults.status === $setup.AsyncStatus.Failed)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString($setup.queryResults.message), 1 /* TEXT */))
        : _createCommentVNode("v-if", true),
      ($setup.queryResults.status === $setup.AsyncStatus.Succeeded)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.propertyValuesByType, (properties, type) => {
                return (_openBlock(), _createBlock($setup["ValueGroup"], {
                  values: properties,
                  "property-type": type as PropertyKnowledgeRef,
                  key: type
                }, null, 8 /* PROPS */, ["values", "property-type"]))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.neighborhoods, (query, key) => {
              return (_openBlock(), _createBlock($setup["NeighborhoodSummary"], {
                key: key,
                query: query,
                onNavigate: _cache[1] || (_cache[1] = (address) => $setup.emit('navigate', address)),
                onPivot: () => $setup.pivotTo(query)
              }, null, 8 /* PROPS */, ["query", "onPivot"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]))
        : _createCommentVNode("v-if", true)
    ])
  ]))
}