<template>
  <div class="absolute left-0 right-0 top-0 z-0 h-[24px] bg-gray-200 dark:bg-gray-800"></div>
  <div class="absolute inset-0 z-0 overflow-auto" data-test="table">
    <table class="z-10 border-collapse">
      <tr>
        <th class="sticky top-0 z-10 bg-gray-200 p-0">
          <div class="flex h-[25px] border-b border-r border-white px-10"></div>
        </th>
        <template v-for="(colName, index) in columns" :key="index">
          <th class="sticky top-0 z-10 bg-gray-200 p-0 text-center align-baseline">
            <div
              class="flex h-[25px] items-center justify-end whitespace-nowrap border-b border-r border-white px-10 text-right"
            >
              <div class="mx-auto">{{ colName }}</div>
            </div>
          </th>
          <th v-if="index > 0" class="sticky top-0 z-10 bg-gray-200 p-0">
            <div class="flex h-[25px] border-b border-r border-white px-10"></div>
          </th>
        </template>
      </tr>
      <FinancialSubtable :rows="table" :columns="columns" :depth="0" />
    </table>
  </div>
</template>

<script lang="ts" setup>
import {
  FinancialStatementRow,
  FinancialStatementVisualization,
  generateValue,
} from "@/reader/lib/visualization";
import { computed, toRefs } from "vue";
import { GraphValue, isValue, stringifyValue, toValue } from "@/common/lib/value";
import FinancialSubtable from "./financial-statement/FinancialSubtable.vue";
import { TRANSFORMERS, ValueWithFormattedValue } from "@/common/lib/format";
import { UseQueryResult } from "@/reader/composables/useQuery";
import { last, range } from "lodash";
import { GraphCompoundValue } from "@/common/lib/graph";

const props = defineProps<{
  visualization: FinancialStatementVisualization;
  results: UseQueryResult[];
  width: number;
  height: number;
}>();

defineEmits<{ select: [alias: string, value: GraphValue | null] }>();

const { visualization, results } = toRefs(props);

export interface FinComputedCell {
  value?: ValueWithFormattedValue;
  overComparisonValue?: ValueWithFormattedValue;
  overPreviousPeriod?: ValueWithFormattedValue;
}

export interface FinComputedRow {
  label: string;
  cells: FinComputedCell[];
  contents?: FinComputedRow[];
  highlight: boolean;
}

function buildSubtable(rowDefs: FinancialStatementRow[]): FinComputedRow[] {
  return rowDefs.map(function (rowDef) {
    return {
      label: rowDef.label,
      cells: buildCells(rowDef),
      contents: rowDef.contents == null ? undefined : buildSubtable(rowDef.contents),
      highlight: rowDef.highlight ?? false,
    };
  });
}

function buildCells(rowDef: FinancialStatementRow): FinComputedCell[] {
  const query = visualization.value.query;
  const cells: FinComputedCell[] = [];
  for (const index of range(results.value.length)) {
    const period = results.value[index];
    const value = generateValue(rowDef.value, period, query) ?? undefined;
    cells.push({
      value,
      overPreviousPeriod: compareValues(
        last(cells)?.value?.originalValue,
        value?.originalValue,
        true
      ),
    });
  }
  return cells;
}

function compareValues(
  val1: GraphValue | GraphCompoundValue | undefined,
  val2: GraphValue | GraphCompoundValue | undefined,
  isChange: boolean
): ValueWithFormattedValue | undefined {
  if (!isValue(val1) || !isValue(val2)) return undefined;
  const num1 = Number(val1?.value) ?? 0;
  const num2 = Number(val2?.value) ?? 0;
  let ratio;
  if (isChange) {
    if (num1 <= 0 || num2 < 0) return undefined;
    ratio = (num2 - num1) / num1;
  } else {
    if (num2 === 0) return undefined;
    ratio = num1 / num2;
  }
  return {
    originalValue: toValue(ratio),
    formattedValue: TRANSFORMERS[isChange ? "percentChange" : "percent"](toValue(ratio)),
  };
}

const columns = computed(function () {
  const query = visualization.value.query;
  const gen = visualization.value.config.columns;
  return results.value.map(function (period) {
    const value = generateValue(gen, period, query);
    return value ? stringifyValue(value.formattedValue) : "???";
  });
});

const table = computed(() => buildSubtable(visualization.value.config.rows));
</script>
